import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.env.NODE_ENV === "production" && !isDomainDev()) {
    flare.light(nuxtApp.$config.public.flareKey);

    flare.beforeEvaluate = (error: Error) => {
      if (isFetchError(error)) {
        if (isNoProductsFoundError(error)) return false;
        if (isCantDeleteShipmentError(error)) return false;
        if (isCheckoutTestFailedError(error)) return false;
      } else if (isModuleFetchError(error)) return false;
      else if (isSyntaxError8(error)) return false;

      return error;
    };

    flare.beforeSubmit = (report) => {
      return {
        ...report,
        application_path: report.context.request?.url,
      };
    };

    nuxtApp.vueApp.use(flareVue);
  }
});

const getHttpStatusCode = (error: any) => {
  return error.data?.statusCode;
};

const isModuleFetchError = (error: Error) => {
  return error.message?.includes("dynamically imported module");
};

const isFetchError = (error: any) => {
  return error.name == "FetchError";
};

const isNoProductsFoundError = (error: any) => {
  return [400, 404].includes(getHttpStatusCode(error)) && error.data?.url?.startsWith("/api/products");
};

const isCantDeleteShipmentError = (error: any) => {
  return getHttpStatusCode(error) == 400 && error.data?.url?.startsWith("/api/shipments");
};

const isCheckoutTestFailedError = (error: any) => {
  return getHttpStatusCode(error) == 400 && error.data?.url?.startsWith("/api/workspace/checkouts/test");
};

const isSyntaxError8 = (error: Error) => {
  return error.name == "SyntaxError" && error.message == "8";
};
