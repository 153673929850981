import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/app/middleware/01.auth.global.ts";
import _02_45domain_45global from "/app/middleware/02.domain.global.ts";
import _03_45capabilites_45global from "/app/middleware/03.capabilites.global.ts";
import _04_45page_45access_45global from "/app/middleware/04.page_access.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45domain_45global,
  _03_45capabilites_45global,
  _04_45page_45access_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}