<template>
  <label class="flex cursor-pointer relative gap-2.5" :for="id">
    <input
      class="flex-shrink-0 peer relative h-5 w-5 aspect-square cursor-pointer appearance-none border transition-all before:content[''] before:-inset-1 before:absolute focus-visible:outline-none focus-visible:before:border-2 focus-visible:before:border-focus !checked:border-inversed-00 checked:bg-inversed-00 checked:hover:bg-inversed-01 checked:hover:border-inversed-01 checked:disabled:bg-disabled !indeterminate:border-inversed-00 indeterminate:bg-inversed-00 indeterminate:hover:bg-inversed-01 indeterminate:hover:border-inversed-01 indeterminate:disabled:bg-disabled disabled:border-disabled disabled:cursor-default hover:border-02"
      v-model="inputValue"
      :id="id"
      :disabled="readonly"
      type="checkbox"
      :indeterminate="indeterminate"
      :class="{
        'rounded-full before:rounded-full': rounded,
        'rounded-md before:rounded-lg ': !rounded,
        'bg-01 border-2 border-01': darkBg,
        'bg-00': !darkBg,
        'checked:border-black checked:hover:border-inversed-01 hover:border-destructive border-destructive-01 border-2 bg-destructive-01':
          error,
      }"
    />
    <div
      class="h-5 w-5 flex items-center justify-center pointer-events-none absolute text-inherit opacity-0 transition-opacity peer-checked:opacity-100 peer-indeterminate:opacity-100 peer-indeterminate:text-surface-lvl-00 peer-checked:text-surface-lvl-00 peer-disabled:text-foreground-disabled"
    >
      <svg
        class="h-3.5 w-3.5"
        v-if="indeterminate"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="3"
      >
        <line x1="4" y1="10" x2="15" y2="10" stroke-linecap="round" />
      </svg>
      <svg
        class="h-3.5 w-3.5"
        v-else-if="inputValue"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
    <slot></slot>
    <div
      class="text-body-default text-ellipsis w-full overflow-hidden"
      v-if="label"
      :class="[error ? 'text-destructive' : '', inputValue ? 'text-primary' : '']"
    >
      {{ label }}
    </div>
  </label>
</template>

<script setup lang="ts">
  const id = `checkbox-${useId()}`;

  const props = withDefaults(
    defineProps<{
      readonly?: boolean;
      indeterminate?: boolean;
      name?: string;
      error?: boolean;
      label?: string;
      activeValue?: boolean;
      darkBg?: boolean;
      rounded?: boolean;
    }>(),
    {
      readonly: false,
      indeterminate: false,
      name: "",
      error: false,
      label: "",
      activeValue: false,
      darkBg: false,
    }
  );

  const emit = defineEmits(["update:modelValue"]);
  const modelValue = defineModel();

  const activeValue = computed(() => props.activeValue);

  watch(activeValue, (newValue) => {
    inputValue.value = newValue;
  });

  onMounted(() => {
    if (props.activeValue) {
      inputValue.value = true;
    }
  });

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
  } = useField(() => props.name, undefined, {
    type: "checkbox",
    checkedValue: true,
    uncheckedValue: false,
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  watch(inputValue, (newValue) => {
    emit("update:modelValue", newValue);
  });

  const error = computed(() => {
    return props.error || errorMessage.value;
  });
</script>
