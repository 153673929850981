import { createOperationsGenerator } from "#image";
import { joinURL } from "ufo";
import type { ProviderGetImage } from "@nuxt/image";

const operationsGenerator = createOperationsGenerator();

export const getImage: ProviderGetImage = (src, { modifiers = {}, baseURL } = {}) => {
  const operations = operationsGenerator(modifiers);

  let url = src + (operations ? "?" + operations : "");
  return {
    url,
  };
};
