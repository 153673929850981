<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper">
    <div>
      <div class="text-body-default text-tertiary">
        {{ t("balance") }}
      </div>
      <div class="flex justify-between gap-4">
        <div class="text-title-subsection text-secondary">{{ balance?.toLocaleString() || 0 }} DKK</div>
        <div class="flex gap-2 items-center">
          <div class="text-body-default-heavy">
            {{ t("settings.payment.autofill.balance") }}
          </div>
          <InputNumber class="w-[136px]" v-model="manualFillValue" placeholder="min. 500" suffix="DKK" :min="500" />
          <Button variant="primary" @click="manualFill" :disabled="!manualFillValue || manualFillValue < 500">
            {{ t("settings.payment.autofill.fill") }}
          </Button>
        </div>
      </div>
    </div>

    <div class="border border-00 rounded-xl p-4">
      <div class="flex justify-between">
        <div class="flex gap-3">
          <div class="text-body-default-heavy text-secondary">
            {{ t("settings.payment.autofill.activate") }}
          </div>
          <Chip type="kiwi">
            {{ t("recommended") }}
          </Chip>
        </div>
        <div>
          <Toggle v-model="options.autofill" :disabled="!savedCards?.length" />
        </div>
      </div>
      <div class="text-body-default text-tertiary">
        {{ t("settings.payment.autofill.description") }}
      </div>
      <div class="grid grid-cols-1 @lg:grid-cols-3 gap-4 my-4" v-if="hasCards">
        <div class="col-span-1">
          <DropdownSelect
            v-model="options.autofill_threshold"
            :label="t('settings.payment.autofill.threshold.placeholder')"
            :readonly="!options.autofill"
            :options="autofillOptions"
          />
        </div>
        <div class="col-span-1">
          <InputText
            v-model="options.autofill_amount"
            :label="t('settings.payment.autofill.amount.placeholder')"
            suffix="DKK"
            light-bg
            :readonly="!options.autofill"
          />
        </div>
        <div class="col-span-1">
          <DropdownSelect
            :model-value="savedCardsAsDropdownOptions[0]?.value"
            :options="savedCardsAsDropdownOptions"
            :label="t('settings.payment.autofill.card.placeholder')"
            readonly
          />
        </div>
      </div>
      <div class="flex justify-between md:flex-row flex-col items-end md:items-center gap-4">
        <div class="flex gap-2 justify-end w-full" v-if="hasCards">
          <Button variant="secondary" :disabled="!isOptionsDirty" @click="resetAutofillOptions">
            {{ t("undo") }}
          </Button>
          <Button variant="primary" :disabled="!isOptionsDirty" @click="updateAutofill">
            {{ t("save") }}
          </Button>
        </div>
        <div class="text-body-default-heavy text-disabled w-full mt-4" v-else>
          {{ t("settings.payment.autofill.requires_saved_card") }}
        </div>
      </div>
    </div>

    <Divider />

    <div>
      <div class="mb-3">
        <div class="text-body-lg-heavy text-secondary">
          {{ t("settings.payment.card.title") }}
        </div>
      </div>
      <div>
        <ListSelector :values="savedCardsAsDropdownOptions" name="card" radio-position="top">
          <template #avatar="{ value }">
            <CreditcardType :type="value.type"></CreditcardType>
          </template>
          <template #title="{ value }">
            <div class="flex flex-col gap-3 items-start">
              <div class="flex flex-col">
                <div class="text-body-lg-heavy text-secondary">
                  {{ buildCardTitle(value.value) }}
                </div>
                <div class="text-body-default text-tertiary">
                  {{ t("settings.payment.card.expiry") }}: {{ value.value.expiry_month }}/{{ value.value.expiry_year }}
                </div>
              </div>
              <div>
                <Button variant="primary-link" @click="removeCard">
                  {{ t("settings.payment.card.remove") }}
                </Button>
              </div>
            </div>
          </template>
        </ListSelector>
        <Button class="mt-4" v-if="!hasCards" variant="primary-link" @click="addCard">
          {{ t("settings.payment.card.add") }}
        </Button>
      </div>
    </div>

    <Divider />
    <div class="">
      <div class="mb-3 z-50 bg-surface-lvl-00">
        <div class="text-body-lg-heavy text-secondary">
          {{ t("settings.payment.history.title") }}
        </div>
        <div class="text-body-default text-quarterary">
          {{ t("settings.payment.history.description") }}
        </div>
      </div>
      <Table :items="history" :headers="historyHeaders">
        <template #col.invoice="{ item }">
          <Button class="ml-auto" variant="primary-link" @click="() => getReceipt(item)" type="button">
            {{ t("settings.payment.history.invoice") }}
          </Button>
        </template>
      </Table>
      <TablePaginator :pagination="historyPaginationData" @paginate="onPaginate" />
    </div>
    <NotificationWrapper />
  </div>
</template>

<script setup lang="ts">
  export interface ISubscription {
    payment_type: string;
    balance: Balance;
    card: Card;
  }

  export interface Balance {
    amount: number;
    autofill: number;
    autofill_amount: number;
    autofill_threshold: number;
  }

  export interface Card {
    type: null;
    active: null;
    number: null;
    expiry_month: null;
    expiry_year: null;
  }

  import Chip from "~/components/Badge/Chip.vue";
  import type { ISettingsFlow } from "../types";
  import type { Pagination } from "~/types/paginate";
  import type { ITableHeader } from "~/components/Table/types";
  import NotificationWrapper from "../components/NotificationWrapper.vue";
  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );
  const { t } = useI18n();

  const balance = ref();
  const manualFillValue = ref();

  let initialOptions = {
    autofill: 0,
    autofill_threshold: 0,
    autofill_amount: 0,
  };

  const options = ref({
    autofill: 0,
    autofill_threshold: 0,
    autofill_amount: 0,
  });

  const autofillOptions = [
    { label: "500 DKK", value: 500 },
    { label: "1000 DKK", value: 1000 },
    { label: "2000 DKK", value: 2000 },
    { label: "5000 DKK", value: 5000 },
    { label: "10000 DKK", value: 10000 },
  ];

  const isOptionsDirty = computed(() => {
    return (
      options.value.autofill != initialOptions.autofill ||
      options.value.autofill_threshold != initialOptions.autofill_threshold ||
      options.value.autofill_amount != initialOptions.autofill_amount
    );
  });

  const savedCards = ref<Card[]>([]);
  const savedCardsAsDropdownOptions = computed(() => {
    return savedCards.value.map((card) => {
      return {
        label: buildCardTitle(card),
        value: card,
      };
    });
  });
  const historyPage = ref(1);
  const history = ref<any[]>([]);
  const historyPaginationData = ref<Pagination>({
    current_page: 1,
    last_page: 1,
    per_page: 1,
    total: 1,
  });

  const fetchSubscriptionInfo = () =>
    homeFetch("workspace/payments/subscription").then((response) => {
      if (response?.data) {
        const data = response.data as ISubscription;
        balance.value = data.balance.amount;
        options.value.autofill = data.balance.autofill;
        options.value.autofill_threshold = data.balance.autofill_threshold;
        options.value.autofill_amount = data.balance.autofill_amount;

        initialOptions = {
          autofill: data.balance.autofill,
          autofill_threshold: data.balance.autofill_threshold,
          autofill_amount: data.balance.autofill_amount,
        };

        if (data.card && data.card.type) {
          savedCards.value = [data.card];
        } else {
          savedCards.value = [];
        }
      }
    });

  const onPaginate = (page: number) => {
    historyPage.value = page;
  };

  const paginateHistory = () =>
    homeFetch("workspace/payments/autofills", {
      query: {
        page: historyPage.value,
        limit: 20,
      },
    }).then((response) => {
      // @ts-ignore
      if (response?.data) {
        // @ts-ignore
        history.value = response.data.data;
        // @ts-ignore
        historyPaginationData.value = getPaginationData(response.data);
      }
    });

  const updateAutofill = () => {
    homeFetch("workspace/payments/update-autofill", {
      method: "POST",
      silent: true,
      body: {
        autofill: options.value.autofill,
        autofill_threshold: options.value.autofill_threshold,
        autofill_amount: options.value.autofill_amount,
      },
    })
      .then((r) => {
        initialOptions = {
          autofill: options.value.autofill,
          autofill_threshold: options.value.autofill_threshold,
          autofill_amount: options.value.autofill_amount,
        };

        $toast.add({
          title: t("settings.payment.autofill.update.success"),
          target: "#settings-modal .notification-wrapper",
        });
      })
      .catch((e) => {
        if (e.response?._data?.data?.message) {
          $toast.add({
            title: e.response._data.data.message,
            target: "#settings-modal .notification-wrapper",
            type: "error",
          });
        } else if (e.message) {
          $toast.add({
            title: e.message,
            target: "#settings-modal .notification-wrapper",
            type: "error",
          });
        } else {
          $toast.add({
            title: t("unexpected_error"),
            target: "#settings-modal .notification-wrapper",
            type: "error",
          });
        }
      });
  };

  const manualFill = () => {
    homeFetch("workspace/payments/balance", {
      method: "POST",
      body: {
        amount: manualFillValue.value,
        final_url: window.location.origin + "/redirects/ordersuccess",
      },
      silent: true,
    })
      .then((response) => {
        window.open(response.data.payment_url);
        window.addEventListener("message", subscriptionEventHandler);
      })
      .catch(() => {
        $toast.add({
          title: t("unexpected_error"),
          target: "#settings-modal .notification-wrapper",
          type: "error",
        });
      });
  };

  const resetAutofillOptions = () => {
    options.value.autofill = initialOptions.autofill;
    options.value.autofill_threshold = initialOptions.autofill_threshold;
    options.value.autofill_amount = initialOptions.autofill_amount;
  };

  watch(historyPage, () => {
    paginateHistory();
  });

  const historyHeaders = ref<ITableHeader[]>([
    {
      key: "created_at",
      label: t("date"),
      type: "date",
    },
    {
      key: "amount",
      label: t("amount"),
      align: "right",
      type: "price",
    },
    {
      align: "right",
      key: "invoice",
      label: "",
      labelless: true,
    },
  ]);

  const addCard = () => {
    homeFetch("workspace/payments/card", {
      method: "POST",
      body: {
        final_url: window.location.origin + "/redirects/ordersuccess",
      },
    }).then((response) => {
      window.open(response.data.redirect_url);
      window.addEventListener("message", subscriptionEventHandler);
    });
  };

  const removeCard = () => {
    $confirm({
      message: t("settings.payment.card.remove_confirm"),
      onConfirm: () => {
        homeFetch("workspace/payments/card", {
          method: "DELETE",
          silent: true,
        })
          .then(() => {
            fetchSubscriptionInfo();
            $toast.add({
              title: t("settings.payment.card.remove_success"),
              target: "#settings-modal .notification-wrapper",
            });
          })
          .catch(() => {
            $toast.add({
              title: t("settings.payment.card.remove_error"),
              target: "#settings-modal .notification-wrapper",
              type: "error",
            });
          });
      },
    });
  };

  const subscriptionEventHandler = (event) => {
    if (event.data === "payment:success") {
      fetchSubscriptionInfo();
      window.removeEventListener("message", subscriptionEventHandler);
    }
  };

  const buildCardTitle = (card: Card) => {
    let cardType: string = card.type!;

    switch (cardType) {
      case "visa":
        cardType = "Visa";
        break;
      case "visa_dk":
        cardType = "Visa/Dankort";
        break;
      case "visa-electron":
        cardType = "Visa Electron";
        break;
      case "mastercard":
        cardType = "Mastercard";
        break;
      case "mastercard-debet":
        cardType = "Mastercard Debet";
        break;
      default:
        cardType = "";
        break;
    }

    //get last 8 digits of card number and replace X with *
    const cardNumber = (card.number! as string).slice(-8).replace(/X/g, "*");

    let title = "";

    if (cardType) {
      title = `${cardType} ${cardNumber}`;
    } else {
      title = cardNumber;
    }

    return title;
  };

  const hasCards = computed(() => savedCards.value.length > 0);

  const getReceipt = (item) => {
    homeFetch(`workspace/payments/${item.transaction_id}/download`, {
      method: "GET",
      silent: true,
    })
      .then((response) => {
        window.open(response.data.url);
      })
      .catch(() => {
        $toast.add({
          title: t("unexpected_error"),
          target: "#settings-modal .notification-wrapper",
          type: "error",
        });
      });
  };

  onMounted(() => {
    setLoading(true);
    Promise.all([fetchSubscriptionInfo(), paginateHistory()]).then(() => {
      setLoading(false);
      goToCurrentHeader();
    });
  });

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));
</script>

<script lang="ts">
  export const usePaymentSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/receipt";
    const title = t("settings.payment.title");
    const key = "payment";
    const condition = () => !useAuth().isDFM.value;

    return {
      icon,
      title,
      key,
      condition,
    };
  };
</script>
