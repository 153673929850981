<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper">
    <div class="flex gap-3 justify-between flex-col w-full">
      <div class="flex-col gap-0.5">
        <div class="text-body-lg-heavy settings-flow-header">
          {{ t("user_settings.profile.personal_information.title") }}
        </div>
      </div>
      <div class="grid grid-cols-2 gap-3 auto-cols-fr">
        <InputText class="col-span-1 w-full" v-model="firstName" :label="t('first_name')" />
        <InputText class="col-span-1 w-full" v-model="lastName" :label="t('last_name')" />
        <InputText class="col-span-1" v-model="information.phone" :label="t('phone_number')" />
        <InputText class="col-span-1" v-model="information.email" :label="t('email')" />
      </div>
    </div>
  </div>
  <NotificationWrapper />
</template>

<script setup lang="ts">
  import _ from "lodash";
  import type { ISettingsFlow } from "../types";
  import NotificationWrapper from "../components/NotificationWrapper.vue";
  import type { IUser } from "~/types/auth";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
      loading?: boolean;
    }>(),
    {
      currentHeader: null,
    }
  );
  const { t } = useI18n();
  const information = ref<IUser>({} as IUser);
  let initialInformation = {} as IUser;

  const firstName = computed({
    get: () => information.value?.name?.split(" ")[0] || "",
    set: (value: string) => {
      const first = value;
      const last = information.value?.name?.split(" ")[1] || "";
      const combined = `${first} ${last}`.trim();
      information.value = {
        ...information.value,
        name: combined,
      };
    },
  });

  const lastName = computed({
    get: () => information.value?.name?.split(" ")[1] || "",
    set: (value: string) => {
      const first = information.value?.name?.split(" ")[0];
      const last = value;
      const combined = `${first} ${last}`.trim();

      information.value = {
        ...information.value,
        name: combined,
      };
    },
  });

  const fetchInformation = async () => {
    setLoading(true);
    return homeFetch("settings/profile")
      .then((response) => {
        if (response?.data) {
          information.value = { ...response.data?.profile } as IProfile;
          initialInformation = _.cloneDeep(information.value);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateInformation = () => {
    $confirm({
      message: t("confirm_save_changes"),
      confirmText: t("save_changes"),
      cancelText: t("cancel"),
      onConfirm: () => {
        setLoading(true);
        homeFetch("settings/profile", {
          method: "PUT",
          body: information.value,
        })
          .then(useAuth().updateCapabilities)
          .then(() => {
            initialInformation = _.cloneDeep(information.value);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  const hasChanges = computed(() => {
    return !_.isEqual(information.value, initialInformation);
  });

  watch(
    information,
    (val, oldVal) => {
      if (!hasChanges.value) {
        $toast.remove("profile_settings");
        return;
      } else
        $toast.add({
          duration: 0,
          id: "profile_settings",
          group: "profile",
          title: t("unsaved_changes"),
          target: "#settings-modal .notification-wrapper",
          icon: null,
          actions: [
            {
              text: t("undo"),
              handler: () => {
                resetInformation();
              },
            },
            {
              text: t("save_changes"),
              handler: () => {
                updateInformation();
              },
            },
          ],
        });
    },
    {
      deep: true,
    }
  );

  const resetInformation = () => {
    information.value = _.cloneDeep(initialInformation);
  };
  onMounted(() => {
    fetchInformation().then(() => {
      goToCurrentHeader();
    });
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
  export const useProfileSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/UserCircle";
    const title = t("user_settings.profile.title");
    const pageTitle = t("user_settings.profile.page_title");
    const key = "profile";
    const headers = [t("user_settings.profile.personal_information.title"), t("user_settings.profile.language")];

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
    };
  };
</script>
