export default defineNuxtRouteMiddleware((to, from) => {
  if (useHasPageAccess(to.name as string)) {
    return true;
  }

  return abortNavigation({
    statusCode: 403,
    message: "You don't have access to this page.",
  });
});
