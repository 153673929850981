<template>
  <Modal class="shadow-lvl-05" v-model:open="open" maxWidth="1020px" ref="modal">
    <div class="flex flex-col max-w-full p-[14px] gap-5">
      <ListSelector
        class="[&>div]:flex-row [&_.list-select-option]:flex-col [&_.list-select-option]:flex"
        v-model="transferUnitType"
        :values="unitTypes"
        valueKey="value"
      >
        <template #title="{ value }">
          <div class="flex gap-4 justify-between flex-1 items-center">
            <div class="text-body-lg-heavy">
              {{ value.label }}
            </div>
            <Badge class="text-body-default" variant="primary">
              {{ value.badge }}
            </Badge>
          </div>
        </template>

        <template #value="{ value }">
          <div class="flex flex-col gap-3 w-full h-full">
            <div class="text-body-default text-tertiary">{{ value.description }}</div>

            <div class="flex flex-col gap-1.5">
              <template v-for="(tableEntry, idx) in value.table">
                <div class="flex text-body-default text-secondary gap-2 justify-between">
                  <div>{{ tableEntry.left }}</div>
                  <div class="text-right flex gap-1 items-center">
                    {{ tableEntry.right }}
                  </div>
                </div>
                <Button
                  class="!justify-start mr-1"
                  v-if="tableEntry.infoLink"
                  variant="primary-link"
                  :href="tableEntry.infoLink.link"
                  target="_blank"
                  @click.stop=""
                >
                  {{ tableEntry.infoLink.title }} <Icon src="ArrowUpRight" />
                </Button>
                <Divider v-if="idx !== value.table.length - 1" />
              </template>
            </div>
            <template v-if="value.prices">
              <Divider />
              <div class="flex flex-col gap-1.5">
                <div class="text-body-default-heavy">{{ t("price_excl_vat_and_fuel") }}:</div>
                <div class="flex flex-col gap-1.5">
                  <template v-for="(price, idx) in value.prices">
                    <div class="flex text-body-default text-tertiary gap-2 justify-between">
                      <div>{{ price.left }}</div>
                      <div class="text-right flex gap-1 items-center">
                        {{ price.right }}
                      </div>
                    </div>
                  </template>
                  <Button
                    class="!justify-start mr-1"
                    v-if="value.link"
                    variant="primary-link"
                    :href="value.link"
                    @click.stop=""
                  >
                    {{ t("settings.plan.pickup.default_unit_type.bag.auto_delivery") }}
                    <Icon src="ArrowUpRight" />
                  </Button>
                </div>
              </div>
            </template>
          </div>
        </template>
      </ListSelector>
      <Callout type="aware" icon="WarningDiamond">
        {{ t("settings.plan.pickup.default_unit_type.warning") }}
      </Callout>
    </div>

    <ModalFooter class="border-t-0">
      <Button @click="() => emit('update:open', false)" type="button">
        {{ t("cancel") }}
      </Button>
      <Button
        variant="primary"
        @click="updateUnitType"
        :disabled="!transferUnitTypeHasChanged"
        :loading="updating"
        type="button"
      >
        {{ t("save_changes") }}
      </Button>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
  const modal = ref<HTMLDivElement | null | undefined>();
  const open = defineModel("open", { default: false });
  const { t } = useI18n();
  const emit = defineEmits(["update:open"]);
  const updating = ref(false);

  const unitTypes = ref([
    {
      value: "BAG",
      label: t("bag", 99),
      badge: t("settings.plan.pickup.default_unit_type.bag.badge"),
      description: t("settings.plan.pickup.default_unit_type.bag.description"),
      table: [
        { left: t("dimensions"), right: "130 x 85 cm" },
        { left: t("weight"), right: "Max 30 kg" },
        {
          left: t("load_capacity"),
          right: `${t("up_to")} 120 L`,
          infoLink: {
            title: t("load_capacity_read_more", { type: t("bag", 99).toLowerCase() }),
            link: "https://help.homerunner.com",
          },
        },
      ],
      link: {
        title: t("settings.plan.pickup.default_unit_type.bag.auto_delivery"),
        link: "https://help.homerunner.com",
      },
    },
    {
      value: "PALLET",
      label: t("pallet", 99),
      badge: t("settings.plan.pickup.default_unit_type.pallet.badge"),
      description: t("settings.plan.pickup.default_unit_type.pallet.description"),
      table: [
        { left: t("dimensions"), right: "130 x 85 cm" },
        { left: t("weight"), right: "Max 30 kg" },
        {
          left: t("load_capacity"),
          right: `${t("up_to")} 120 L`,
          infoLink: {
            title: t("load_capacity_read_more", { type: t("pallet", 99).toLowerCase() }),
            link: "https://help.homerunner.com",
          },
        },
      ],
    },
  ]);

  const transferUnitType = ref("");

  watch(
    () => open.value,
    () => {
      if (open.value) {
        transferUnitType.value = unref(useAuth().accountOptions.value?.transfer?.transfer_pickup_unit_type);
      } else {
        transferUnitType.value = "";
      }
    }
  );

  const transferUnitTypeHasChanged = computed(() => {
    return transferUnitType.value !== unref(useAuth().accountOptions.value?.transfer?.transfer_pickup_unit_type);
  });

  const updateUnitType = () => {
    updating.value = true;
    return homeFetch("workspace/plan/unit-type", {
      method: "PUT",
      body: {
        transfer_pickup_unit_type: transferUnitType.value,
      },
    })
      .then(() => {
        useAuth().updateCapabilities();
        emit("update:open", false);
      })
      .finally(() => {
        updating.value = false;
      });
  };
</script>
