<template>
  <div class="w-full p-3 flex flex-col gap-3">
    <template v-for="(item, index) in items" :key="'item_' + index">
      <ToggleListItem
        :item="item"
        :model-value="isItemSelected(item)"
        @update:model-value="(val) => toggleItem(val, item, index)"
        :expand-on-toggle="expandOnToggle"
        ref="refs"
      >
        <slot v-bind="{ item, index }" name="item"> </slot>
      </ToggleListItem>

      <Divider v-if="index < items.length - 1" :spacing="false" />
    </template>
  </div>
</template>

<script setup lang="ts">
  import ToggleListItem, { type IToggleListItemEntry } from "./partials/ToggleListItem.vue";

  defineOptions({
    name: "AccordionList",
  });

  const props = withDefaults(
    defineProps<{
      items: IToggleListItemEntry[];
      expandOnToggle?: boolean;
    }>(),
    {
      items: () => [],
      expandOnToggle: false,
    }
  );

  const selected = defineModel<string[] | number[]>();
  const refs = ref<Record<number, any>>({});

  const emit = defineEmits(["update:model-value"]);

  const isItemSelected = (item: IToggleListItemEntry) => {
    if (!selected.value) {
      return item.active || item.selected;
    }
    return selected.value.includes(item.value);
  };

  const toggleItem = (value: boolean, item: IToggleListItemEntry, idx: number) => {
    const s = selected.value ? [...selected.value] : [];
    const index = s.indexOf(item.value);
    if (index === -1) {
      s.push(item.value);
    } else {
      s.splice(index, 1);
    }
    emit("update:model-value", s, item, idx, value);
  };

  defineExpose({
    refs,
  });
</script>
