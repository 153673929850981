<template>
  <div
    class="grid w-full justify-center items-center p-1 bg-00 rounded-xl tabs relative"
    :style="`--width:${100 / options.length}%; grid-template-columns: repeat(${options.length}, 1fr);`"
  >
    <div
      class="px-3 py-[3px] cursor-pointer text w-full tabs-item relative z-10"
      v-for="(option, index) in options"
      :key="option.value"
      @click="((tabValue = option.value), (selectedIndex = index))"
      :class="{ active: tabValue === option.value }"
    >
      <p class="text-body-default-heavy text-center">{{ option.label }}</p>
    </div>
    <span
      class="tab-item-animate rounded-lg bg-white shadow-surface-lvl-01 shadow-lvl-01 border border-01 transform"
      id="tabItem"
    ></span>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";

  const props = withDefaults(
    defineProps<{
      options: Array<any>;
      name: string;
    }>(),
    {
      options: () => [],
      name: "",
    }
  );

  onMounted(() => {
    tabValue.value = props.options[0].value;
  });

  const { value: tabValue } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const emit = defineEmits(["click", "update:modelValue"]);

  const selectedIndex = ref(0);

  watch(selectedIndex, async (newindex, oldindex) => {
    emit("update:modelValue", tabValue.value);
    const tabItem = document.getElementById("tabItem");
    if (tabItem) {
      tabItem.style.transform = `translateX(${newindex * 100}%) translateX(+${newindex}px)`;
    }
  });

  defineExpose({
    tabValue,
  });
</script>

<style>
  .tab-item-animate {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(var(--width) - 4px);
    height: 27px;
    transform-origin: 0 0;
    transition: transform 0.25s;
  }
</style>
