<template>
  <div
    class="w-full overflow-hidden transition-all duration-[400ms] rounded-lg hover:bg-00 group"
    :class="open ? 'bg-00' : ''"
  >
    <div class="py-2 px-3 flex items-center cursor-pointer" @click="emit('openItem', index)">
      <div>
        <p class="text-secondary text-body-default-heavy">{{ title }}</p>
        <p
          class="text-body-sm group-hover:text-tertiary transition-all duration-[400ms]"
          :class="open ? 'text-tertiary' : 'text-quarterary'"
        >
          {{ subtitle }}
        </p>
      </div>
      <Icon
        class="ml-auto transition-all duration-[400ms] transform"
        src="caretDown"
        :size="ComponentSize.sm"
        filled
        :class="open ? ' -rotate-180' : '-rotate-0'"
      />
    </div>
    <div class="overflow-hidden grid transition-all duration-[400ms]" :class="open ? 'grid-rows-1fr' : 'grid-rows-0'">
      <div class="overflow-hidden px-5 transition-all duration-[400ms]" :class="open ? 'py-2' : 'py-0'">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const props = withDefaults(
    defineProps<{
      title: string;
      subtitle: string;
      open: boolean;
      index: number;
    }>(),
    {
      title: "",
      subtitle: "",
      open: false,
      index: 0,
    }
  );

  const emit = defineEmits(["openItem"]);
</script>
