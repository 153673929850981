<template>
  <button
    class="h-[38px] focus:shadow-focus-blue group flex w-full items-center gap-1.5 whitespace-nowrap rounded-[10px] p-1 text-body-default text-secondary hover:text-primary hover:bg-01 transition-all focus:text-gray-900 focus:outline-none active:outline-none"
    type="button"
    :class="{
      'text-primary active bg-surface-lvl-00 border border-01 shadow-lvl-01': active,
      'text-tertiary': !active,
      '!text-secondary': !!search,
    }"
  >
    <div class="text-inherit flex-shrink-0 rounded-[10px]">
      <div class="relative h-7 w-7 flex items-center justify-center p-1">
        <Icon v-if="entry.icon" :src="entry.icon" :size="ComponentSize.lg" />
      </div>
    </div>
    <div class="text-body-default" :class="{ ' font-medium': active }">
      <span v-for="(part, idx) in splitTitle" :key="part" :class="{ 'font-bold': idx === 1 }">{{ part }}</span>
    </div>
  </button>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const props = withDefaults(
    defineProps<{
      entry: {
        title: string;
        icon?: string;
      };
      active: boolean;
      search: string;
    }>(),
    {
      active: false,
      search: "",
    }
  );

  const search = computed(() => props.search);

  const splitTitle = computed(() => {
    const title = props.entry.title;
    const index = title.toLowerCase().indexOf(search.value.toLowerCase());
    if (index === -1) {
      return [title];
    }

    //split title into 3 parts: before search, search, after search
    return [
      title.slice(0, index),
      title.slice(index, index + search.value.length),
      title.slice(index + search.value.length),
    ];
  });
</script>
