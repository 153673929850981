<template>
  <div class="flex flex-col gap-5 min-h-0 w-full rounded-xl overflow-auto px-2 pb-3 max-w-[556px] m-auto">
    <p class="w-full text-center text-secondary text-title-section">{{ t("portal_settings_title") }}</p>
    <InputText
      name="name"
      :placeholder="t('portal_name_example', { name: account?.account?.name })"
      :label="t('name_portal')"
    />
    <InputText
      v-model="portalURL"
      disabled
      :placeholder="t('portal_url_example', { name: parcedName() })"
      :label="t('portal_url')"
    />
    <InputText name="logo" :placeholder="t('portal_logo_example')" :label="t('portal_logo')" />
    <div v-if="legacy">
      <ButtonGroup name="settings.order_gate" :buttons="buttons" :label="t('return_portal_type')"></ButtonGroup>
      <p class="mt-1 text-quarterary text-body-sm">{{ t("return_portal_type_message") }}</p>
      <TickerCheckbox class="mt-1" name="settings.order_number" :label="t('requires_order_number')" />
      <TickerCheckbox class="mt-1" name="settings.private" :label="t('private_return_portal')" />
    </div>
    <div>
      <ListSelector name="address" :values="addresses" valueKey="type" @update:modelValue="(val) => setAddress(val)">
        <template #title="{ value }">
          <div>
            <p class="text-body-lg-heavy text-secondary">{{ t("organisation_address") }}</p>
            <p class="text-tertiary text-body-default">{{ value.street1 }}, {{ value.zip_code }} {{ value.city }}</p>
          </div>
        </template>
      </ListSelector>
      <TickerCheckbox
        class="mt-3"
        @update:modelValue="(val) => altAddress(val)"
        :label="t('send_return_to_different_address')"
        name="alternative_address"
      />
      <div class="grid grid-cols-4 gap-3 auto-cols-fr mt-3" v-if="values?.address !== 'SHIPPING'">
        <InputText
          class="col-span-2 w-full"
          :label="t('organisation_name')"
          name="settings.alternative_address.company"
        />
        <InputText
          class="col-span-2 w-full"
          :label="t('street_name_and_number')"
          name="settings.alternative_address.street"
        />
        <div class="grid grid-cols-6 gap-3 col-span-2">
          <InputText class="w-full col-span-2" :label="t('zip_code')" name="settings.alternative_address.zip_code" />
          <InputText class="w-full col-span-4" :label="t('city')" name="settings.alternative_address.city" />
        </div>
        <DropdownSelectCountry
          class="col-span-2"
          :label="t('country')"
          name="settings.alternative_address.country"
          triggerType="input"
        />
        <InputText class="col-span-2" :label="t('phone_number')" name="settings.alternative_address.telephone" />
        <InputText class="col-span-2" :label="t('email')" name="settings.alternative_address.notification_email" />
      </div>
    </div>
    <InputNumber
      class="[&_.input-wrapper]:max-w-24"
      name="settings.order_gate_age_limit"
      :label="t('order_gate_limit')"
      :suffix="t('day', values?.settings?.order_gate_age_limit || 1).toLowerCase()"
    >
      <template #label_suffix>
        <Tooltip :text="t('order_gate_limit_description')"></Tooltip>
      </template>
    </InputNumber>
    <InputText name="settings.reference_key" :placeholder="t('portal_reference_example')" :label="t('reference')" />
  </div>
</template>

<script setup lang="ts">
  const emit = defineEmits(["updateAddress", "updateAltAddress"]);
  const { t } = useI18n();
  const props = defineProps<{
    schema: any;
    values: any;
    information: any;
    legacy: boolean;
  }>();

  const addresses = ref([]);

  const buttons = [
    {
      text: t("open_returnportal"),
      variant: "default",
      value: false,
    },
    {
      text: t("closed_returnportal"),
      variant: "default",
      value: true,
    },
  ];

  onMounted(() => {
    for (const address of props.information.contact_info) {
      if (address.type === "SHIPPING") {
        addresses.value.push(address);
      }
    }
  });

  watch(
    () => props?.values?.address,
    (newValue) => {
      if (newValue === "SHIPPING") {
        emit("updateAltAddress", false);
      }
    }
  );

  const setAddress = (val) => {
    if (val) {
      emit("updateAddress", "SHIPPING");
    } else {
      emit("updateAddress", "ALTERNATIVE");
    }
  };

  const altAddress = (val) => {
    if (val) {
      emit("updateAddress", "ALTERNATIVE");
    } else {
      emit("updateAddress", "SHIPPING");
    }
  };

  const { getAccount } = useAuth();

  const account = getAccount();

  const parcedName = () => {
    return account?.account?.name?.toLowerCase().split(".")[0].replace(/ /g, "-");
  };

  const portalURL = computed(() => {
    if (!props?.values?.name) {
      return `https://return.coolrunner.dk/`;
    }
    return `https://return.coolrunner.dk/${props?.values?.name}`;
  });
</script>
