<template>
  <EmptyView :title="t('no_carrier_product')" :description="''">
    <template #description>
      <p class="text-body-lg text-tertiary mb-10">{{ t("no_carrier_product_desc") }}</p>
      <Button class="w-full max-w-max mx-auto gap-6" @click="goBack" variant="primary" :size="ComponentSize.lg">{{
        t("set_another_size")
      }}</Button>
    </template>
    <template #illustration>
      <img class="mb-3.5" src="~/assets/illustrations/emptyViews/ec-no-result.svg" alt="No carriers illustration" />
    </template>
  </EmptyView>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const { t } = useI18n();

  const emit = defineEmits(["goback"]);

  const goBack = () => {
    emit("goback");
  };
</script>
