<template>
  <div>
    <ListSelector v-model="type" radioPosition="top" :values="service.fields[0].options" valueKey="key">
      <template #title="{ value }">
        <div class="text-left">
          <p class="text-body-lg-heavy text-secondary">{{ value.label }}</p>
          <p class="text-tertiary text-body-default" v-if="value.key == 'DOT1'">{{ t("dot_option_desc.1") }}</p>
          <p class="text-tertiary text-body-default" v-if="value.key == 'DOT2'">{{ t("dot_option_desc.2") }}</p>
          <p class="text-tertiary text-body-default" v-if="value.key == 'DOT3'">{{ t("dot_option_desc.3") }}</p>
        </div>
      </template>
    </ListSelector>
    <div class="w-full text-left mt-6 flex gap-4 items-center" v-if="type == 'DOT2' || type == 'DOT3'">
      <InputTime
        class="w-full filter-value max-w-max"
        v-model="time"
        value-as-date
        :label="t('define_time')"
        :minMax="type == 'DOT2' ? '10:00-14:00' : '6:15-16:45'"
      />
      <p class="text-quarterary text-body-default mt-6" v-if="(time && type == 'DOT2') || (time && type == 'DOT3')">
        {{ t("the_shipment_will_be_delivered_between", { time_from: time_from, time_to: time_to }) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { DateTime } from "luxon";

  const { t } = useI18n();
  const props = defineProps<{
    service: any;
  }>();
  const emit = defineEmits(["dateUpdate"]);

  const { value: type } = useField(() => `services.${props.service.carrier_key}.type`, undefined, {
    syncVModel: false,
    controlled: true,
  });

  const { value: time } = useField(() => `services.${props.service.carrier_key}.interval_start`, undefined, {
    syncVModel: false,
    controlled: true,
  });

  const time_from = computed(() => {
    if (time.value) return useGetDOTFromTime(type.value, time.value);
  });

  const time_to = computed(() => {
    if (time.value) return useGetDOTToTime(type.value, time.value);
  });
</script>
