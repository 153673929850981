const _1 = "repeat(1, minmax(0, 1fr))"
const _2 = "repeat(2, minmax(0, 1fr))"
const _3 = "repeat(3, minmax(0, 1fr))"
const _4 = "repeat(4, minmax(0, 1fr))"
const _5 = "repeat(5, minmax(0, 1fr))"
const _6 = "repeat(6, minmax(0, 1fr))"
const _7 = "repeat(7, minmax(0, 1fr))"
const _8 = "repeat(8, minmax(0, 1fr))"
const _9 = "repeat(9, minmax(0, 1fr))"
const _10 = "repeat(10, minmax(0, 1fr))"
const _11 = "repeat(11, minmax(0, 1fr))"
const _12 = "repeat(12, minmax(0, 1fr))"
const _21 = "repeat(21, minmax(0, 1fr))"
const _none = "none"
const _subgrid = "subgrid"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "21": _21, "none": _none, "subgrid": _subgrid,  }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _21, _none, _subgrid }