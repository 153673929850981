import type { ITableRowAction } from "~/components/Table/types";

export default () => {
  const { t } = useI18n();

  const createNewTicket = (package_number: string, shipmentId: string) => {
    $newticket.open();

    homeFetch(`tickets/types/${package_number}`).then((r) => {
      if (!r.data) return;

      $newticket.add(r.data, package_number, shipmentId);
    });
  };

  const goToTicket = (ticketId: number) => {
    const link = useRouter().resolve({ name: "tickets-id", params: { id: ticketId } })?.href;
    useRouter().push(link);
  };

  const submitTicket = async (ticket: Object) => {
    let ticket_id = null;
    await homeFetch(`tickets`, {
      method: "POST",
      body: ticket,
    }).then((r) => {
      ticket_id = r.data.data.id;
      $toast.add({
        title: t("ticket_created_successfully"),
        actions: [
          {
            text: t("go_to_entity", { entity: t("ticket", 1).toLowerCase() }),
            handler: () => goToTicket(ticket_id),
          },
        ],
      });
    });
    return {
      ticketId: ticket_id,
    };
  };

  const { focusEntity, unfocusEntity } = useFocus("tickets");

  const useActions = (): (ITableRowAction | null)[] => [
    {
      key: "focus",
      label: t("focus_entity"),
      handler: (item: Object) => focusEntity(item),
      hidden: (item: Object) => item.redacted || item.is_focused,
    },
    {
      key: "unfocus",
      label: t("unfocus_entity"),
      handler: (item: Object) => unfocusEntity(item),
      hidden: (item: Object) => item.redacted || !item.is_focused,
    },
  ];

  return {
    createNewTicket,
    submitTicket,
    useActions,
  };
};
