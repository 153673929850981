<template>
  <div class="flex flex-col h-full mx-auto items-center justify-center gap-5">
    <div
      class="relative w-[200px] h-[100px] flex items-center justify-center text-utility-kiwi-darkest bg-utility-kiwi rounded-full overflow-hidden"
    >
      <svg
        class="absolute"
        width="200"
        height="101"
        viewBox="0 0 200 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-136.967 38.1815C-149.752 45.5628 -154.132 61.9106 -146.751 74.6953C-139.37 87.4801 -123.022 91.8605 -110.237 84.4792L32.553 2.03922C45.3378 -5.34206 49.7182 -21.6899 42.3369 -34.4746C34.9556 -47.2594 18.6078 -51.6398 5.82302 -44.2585L-136.967 38.1815Z"
          stroke="#ABD9C1"
          style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
          stroke-width="1.5"
        />
        <path
          d="M-162.116 132.028C-174.9 139.409 -179.281 155.757 -171.9 168.542C-164.518 181.326 -148.17 185.707 -135.386 178.325L7.40457 95.8854C20.1893 88.5041 24.5697 72.1563 17.1884 59.3715C9.80715 46.5868 -6.54066 42.2064 -19.3254 49.5877L-162.116 132.028Z"
          stroke="#ABD9C1"
          style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
          stroke-width="1.5"
        />
        <path
          d="M30.3861 51.7514C37.7674 64.5362 54.1152 68.9166 66.8999 61.5353C79.6847 54.154 84.0651 37.8062 76.6838 25.0214C69.3025 12.2367 52.9547 7.85629 40.1699 15.2376C27.3852 22.6189 23.0048 38.9667 30.3861 51.7514Z"
          stroke="#ABD9C1"
          style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
          stroke-width="1.5"
        />
        <path
          d="M99.6656 -19.1125C86.8808 -11.7312 82.5004 4.61665 89.8817 17.4014C97.263 30.1862 113.611 34.5666 126.396 27.1853L269.186 -55.2547C281.971 -62.636 286.351 -78.9838 278.97 -91.7686C271.588 -104.553 255.241 -108.934 242.456 -101.552L99.6656 -19.1125Z"
          stroke="#ABD9C1"
          style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
          stroke-width="1.5"
        />
        <path
          d="M62.6226 81.6034C49.8378 88.9846 45.4575 105.332 52.8387 118.117C60.22 130.902 76.5678 135.282 89.3526 127.901L232.143 45.4611C244.928 38.0798 249.308 21.732 241.927 8.94723C234.545 -3.83752 218.198 -8.21791 205.413 -0.836628L62.6226 81.6034Z"
          stroke="#ABD9C1"
          style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
          stroke-width="1.5"
        />
        <path
          d="M168.369 99.8797C155.584 107.261 151.204 123.609 158.585 136.394C165.966 149.178 182.314 153.559 195.099 146.177L266.494 104.957C279.279 97.5762 283.659 81.2284 276.278 68.4436C268.896 55.6588 252.549 51.2785 239.764 58.6597L168.369 99.8797Z"
          stroke="#ABD9C1"
          style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
          stroke-width="1.5"
        />
      </svg>
      <Icon src="bold/Check" :size="ComponentSize.xl4" />
    </div>

    <div class="text-title-section text-center">
      {{ t("checkout_ready_for_customers", { checkout: formValues?.name }) }}
    </div>
    <div class="text-body-lg text-tertiary text-center">
      {{ t("checkout_created_description") }}
    </div>

    <div class="flex flex-col gap-2 w-full">
      <Button variant="default" type="button" @click="() => emit('advanced')">
        {{ t("setup_rules") }}
      </Button>

      <Button variant="secondary" type="button" @click="() => emit('close')">
        {{ t("maybe_later") }}
      </Button>

      <Button variant="tertiary" type="button" @click="() => emit('close')">
        <template #prefix>
          <Icon src="bold/Question" />
        </template>
        {{ t("how_do_rules_work") }}
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const formValues = inject("FORM_VALUES") as Record<string, any>;

  const { t } = useI18n();

  const emit = defineEmits(["close", "advanced"]);
</script>
