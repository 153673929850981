<template>
  <div class="flex gap-1 w-full">
    <div
      class="flex-grow h-1 transition-colors"
      v-for="i in 4"
      :key="i"
      :class="{
        'bg-brand': password && i - 1 <= score,
        '!bg-02': i > score,
        'rounded-l-full': i === 1,
        'rounded-r-full': i === 4,
      }"
    />
  </div>
</template>

<script setup lang="ts">
  import { scorePassword } from "./logic";

  defineOptions({
    name: "PasswordStrengthBar",
  });

  const props = defineProps({
    password: {
      type: String,
      required: true,
      default: "",
    },
  });

  const emit = defineEmits(["score"]);

  const score = computed(() => {
    if (!props.password) {
      return 0;
    }
    return scorePassword(props.password);
  });
</script>
