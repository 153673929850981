import Echo from "laravel-echo";
import socketIo from "socket.io-client";

const client = ref();

export default () => {
  const getEchoClient = () => {
    if (!client.value) {
      const { getAuthCookie, accountId } = useAuth();

      client.value = new Echo({
        broadcaster: "socket.io",
        client: socketIo,
        host: `https://core.coolrunner.dk`,
        encrypted: true,
        secure: true,
        rejectUnauthorized: false,
        transports: ["websocket"],
        auth: {
          headers: {
            AccountId: accountId.value,
            AccessToken: getAuthCookie().value,
            Context: "socket_new",
          },
        },
      });
    }

    return client.value;
  };

  const hasEchoClient = computed(() => !!client.value);
  const destroyEchoClient = () => {
    client.value = null;
  };

  return {
    getEchoClient,
    hasEchoClient,
    destroyEchoClient,
  };
};
