<template>
  <component
    :is="cc"
    :header="header"
    :item="item"
    :data-header="header.key"
    :index="index"
    :value="cv"
    :class="{
      '!text-destructive': isd,
    }"
    :isNew="isNew"
    :disabled="!cv"
  >
    <slot>
      {{ cv || t("not_specified") }}
    </slot>
    <slot name="suffix">
      <Icon v-if="item.is_focused && lead" filled src="binoculars" />
    </slot>

    <template #subValue>
      <slot name="subValue">
        {{ scv }}
      </slot>
    </template>
  </component>
</template>

<script setup lang="ts">
  import type { ITableData, ITableHeader } from "../../types";
  import { useTableCellValue } from "~/composables/useTableCells";
  defineOptions({
    name: "Cell",
  });

  const props = withDefaults(
    defineProps<{
      header: ITableHeader;
      item: ITableData;
      lead?: boolean;
      index?: number;
      isNew?: boolean;
    }>(),
    {}
  );

  const { t } = useI18n();

  const cc = cellComponent(props.header, props.lead);
  const cv = useTableCellValue(props.item, props.header, props.lead);
  const scv = useTableCellSubValue(props.item, props.header, props.lead);
  const isd = useIsDestructive(props.header, props.item, props.lead);
</script>
