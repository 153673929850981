<template>
  <form v-if="validationSchema" @submit.prevent="onSubmit">
    <slot name="hidden"></slot>
    <template v-if="!currentSchema.spec?.meta?.noHeader">
      <div class="w-full py-4 px-4 flex bg-surface-lvl-00 z-10" v-if="headerType == 'create'">
        <Button class="mr-2" type="button" iconOnly variant="tertiary" @click="exit">
          <Icon class="cursor-pointer text-secondary" src="close" />
        </Button>
        <p class="text-quarterary text-title-body mt-1.5">{{ name }}</p>
        <Divider class="mx-2" rotation="vertical"></Divider>
        <p class="text-secondary text-title-body mt-1.5">{{ stepNames[currentStepIdx] }}</p>
      </div>
      <div class="flex py-2 pl-4 pr-2.5 w-full bg-surface-lvl-00 z-10" v-if="headerType == 'default'">
        <p class="text-secondary text-body-lg-heavy mt-1.5">{{ name }}</p>
        <Button class="ml-auto" type="button" iconOnly variant="tertiary" @click="exit">
          <Icon class="cursor-pointer" src="close" />
        </Button>
      </div>
      <div class="flex mb-2 items-center bg-surface-lvl-00 z-10" v-if="headerType == 'login'">
        <slot name="image" />
      </div>
    </template>
    <div class="px-4 flex min-h-0 h-full form-content">
      <slot>
        <FormStep v-for="(schema, idx) in validationSchema">
          <slot :name="`step-${stepKey}`" :schema="schema" :canSubmit="canSubmit">
            <FormBuilderVeeStep :schema="schema">
              <template #header>
                <div class="invisible" v-if="hideTitle"></div>
                <slot :name="`step-${stepKey}-header`" />
              </template>

              <template v-for="field in schemaFields(schema)" #[`${field}`]>
                <slot :name="`step-${stepKey}-${field}`" :schema="schema" :field="field"></slot>
              </template>

              <template v-for="field in schemaFields(schema)" #[`${field}-label`]>
                <slot :name="`step-${stepKey}-${field}-label`" :schema="schema" :field="field"></slot>
              </template>
            </FormBuilderVeeStep>
          </slot>
        </FormStep>
      </slot>
    </div>
    <slot name="footer">
      <template v-if="!currentSchema.spec?.meta?.noFooter">
        <div class="flex px-6 py-4 bg-surface-lvl-00 z-10 justify-between relative" v-if="footerType == 'create'">
          <Button
            class="w-full max-w-max mr-auto"
            type="button"
            :class="hasPrevious ? 'opacity-100' : 'opacity-0'"
            @click="goToPrev"
            variant="tertiary"
            >{{ t("previous") }}</Button
          >
          <div class="ml-auto flex gap-2">
            <slot name="footer-buttons"></slot>
            <Button
              v-if="hasContinueButton && canSubmit"
              type="submit"
              :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'"
              :loading="loading"
            >
              {{ continueButtonText }}
            </Button>
          </div>
          <div
            class="flex py-2.5 px-4 absolute left-0 right-0 justify-center -z-10"
            v-if="showProgress && !currentSchema.spec.meta?.hideProgressBar"
          >
            <div v-for="(_, idx) in validationSchema" :key="idx">
              <div
                class="h-0.5 transition-all duration-300 mt-2"
                v-if="!_.spec.meta?.autoskip && !_.spec.meta?.hideInProgressBar"
                :class="[
                  idx <= currentStepIdx ? 'bg-inversed-00' : 'bg-02',
                  idx == currentStepIdx ? 'w-10' : 'w-5',
                  { 'mx-1': idx == 0, 'mr-1': idx !== 0 },
                ]"
              ></div>
            </div>
          </div>
        </div>
        <div class="p-4 flex bg-surface-lvl-00 z-10" v-if="footerType == 'default'">
          <Button class="w-full max-w-max" v-if="hasPrevious" type="button" @click="goToPrev" variant="tertiary">{{
            t("previous")
          }}</Button>
          <div class="flex gap-2 ml-auto">
            <Button v-if="canCancel" type="button" variant="default" @click="exit">
              {{ t("cancel") }}
            </Button>
            <Button class="w-full max-w-max" v-if="hasSkip" type="button" variant="default" @click="skip">{{
              t("skip_this_step")
            }}</Button>
            <Button
              class="w-full max-w-max"
              v-if="hasContinueButton"
              type="submit"
              :disabled="!canSubmit || hasSkip"
              :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'"
              :loading="loading"
            >
              {{ continueButtonText }}
            </Button>
          </div>
        </div>
        <div class="flex flex-col gap-5 bg-surface-lvl-00 z-10" v-if="footerType == 'login'">
          <Button
            class="w-full mt-5"
            v-if="hasContinueButton"
            type="submit"
            :disabled="!canSubmit"
            :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'"
            :size="ComponentSize.lg"
            :loading="loading"
          >
            {{ continueButtonText }}
          </Button>
          <slot name="footer-buttons"></slot>
        </div>
      </template>
    </slot>
  </form>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      validationSchema?: any[];
      loading?: boolean;
      submitButtonText?: string;
      lastStepPositive?: boolean;
      showProgress?: boolean;
      splitFooter?: boolean;
      customFooter?: boolean;
      name?: string;
      stepNames?: string[];
      headerType?: "default" | "create" | "login";
      footerType?: "default" | "create" | "login";
      canCancel?: boolean;
      hideTitle?: boolean;
    }>(),
    {
      loading: false,
      submitButtonText: "",
      lastStepPositive: false,
      showProgress: false,
      splitFooter: false,
      name: "",
      customFooter: false,
      stepNames: () => [],
      headerType: "default",
      footerType: "default",
      canCancel: false,
      hideTitle: false,
    }
  );

  const emit = defineEmits(["submit", "exit", "skip", "next"]);
  const {
    currentStepIdx,
    isLastStep,
    hasPrevious,
    hasSkip,
    stepKey,
    hasContinueButton,
    continueButtonText,
    schemaFields,
    goToPrev,
    goToNext,
    form,
    canSubmit,
    skip,
    exit,
    onSubmit,
    goToLastStep,
    resetForm,
    currentSchema,
    setTouched,
    setStep,
  } = useFormBuilder({
    submitButtonText: props.submitButtonText,
    validationSchema: props.validationSchema,
    emit,
  });

  const { values, setValues, setFieldValue } = form;
  defineExpose({
    values,
    currentStepIdx,
    isLastStep,
    hasPrevious,
    canSubmit,
    stepKey,
    setValues,
    setFieldValue,
    goToLastStep,
    goToNext,
    resetForm,
    currentSchema,
    setTouched,
    setStep,
    onSubmit,
  });
</script>
