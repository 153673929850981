<template>
  <div :class="`filter__group select-none`">
    <div class="flex gap-3 items-center relative transform-none">
      <div class="w-[30px] flex flex-col text-sm font-normal text-quarterary self-stretch items-center">
        <div class="flex flex-col gap-y-2.5 text-center">
          <div class="py-2 filter-group-label">{{ first ? t("filter.where") : t("filter.and") }}</div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="flex flex-col gap-y-2.5">
          <RuleFilterEntry
            v-for="(entry, idx) in modelValue"
            :key="filterKey + '-' + idx + '-' + lastArrayUpdate"
            :filter-key="filterKey"
            :model-value="entry"
            @update:model-value="(mv) => updateFilters(idx, mv)"
            @update:filterKey="(newFk, mv) => updateFilterKey(newFk, idx, mv)"
            @remove="() => removeEntry(idx)"
          />
        </div>
      </div>
    </div>
    <Divider class="my-2.5 ml-[42px]" v-if="!last" />
  </div>
</template>

<script setup lang="ts">
  import _ from "lodash";
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();
  defineOptions({
    name: "RuleFilterGroup",
  });

  const lastArrayUpdate = ref(Date.now());

  const props = withDefaults(
    defineProps<{
      modelValue: IRuleFilter[];
      filterKey: string;
      first: boolean;
      last: boolean;
      index: number;
    }>(),
    {
      modelValue: () => [],
    }
  );

  const updateFilterKey = (newKey: string, idx: number, mv: Record<string, any>) => {
    emits("update:filterKey", newKey, idx, mv);
    lastArrayUpdate.value = Date.now();
  };

  const updateFilters = (idx: number, value: IRuleFilter) => {
    const group = _.cloneDeep(props.modelValue);
    group[idx] = value;

    emits("update:modelValue", group);
  };

  const removeEntry = (idx: number) => {
    const group = _.cloneDeep(props.modelValue);
    group.splice(idx, 1);

    emits("remove", group);
    lastArrayUpdate.value = Date.now();
  };

  const emits = defineEmits(["remove", "update:filterKey", "update:modelValue"]);
</script>
<style>
  .filter-group-label {
    @apply text-[13px] tracking-[0.02rem] text-quarterary;
  }

  .filter-or--border {
    @apply relative flex h-full items-center justify-center self-stretch after:absolute after:left-1/2 after:h-full after:w-px after:bg-01;
  }

  .filter-or--border > .filter-group-label {
    @apply relative z-[5] bg-surface-lvl-00 px-2.5 py-1.5;
  }
</style>
