<template>
  <div class="cursor-pointer flex">
    <div class="text-body-default text-secondary">
      <span v-for="(part, idx) in splitLabel" :key="part" :class="{ 'font-bold': idx === 1 }">{{ part }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      entry: {
        label: string;
        value: string;
      };
      search: string;
    }>(),
    {
      search: "",
    }
  );
  const search = computed(() => props.search);

  const splitLabel = computed(() => {
    const label = props.entry.label;
    const index = label.toLowerCase().indexOf(search.value.toLowerCase());
    if (index === -1) {
      return [label];
    }

    //split title into 3 parts: before search, search, after search
    return [
      label.slice(0, index),
      label.slice(index, index + search.value.length),
      label.slice(index + search.value.length),
    ];
  });
</script>
