<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <GlobalLoader />
  <Toast />
  <template v-if="isUserLoggedIn">
    <BoardingModal />
    <NewShipmentWizard />
    <FormWizard />
    <InviteUsersWizard />
    <PrintWizard />
    <NewTicketWizard />

    <ClientOnly>
      <WorkspaceSettingsModal />
      <UserSettingsModal />
      <ModalLegal
        v-if="hasPendingLegal"
        :pendingDocument="pendingDocument"
        @updateAccept="(val) => acceptDocument(val)"
        :loading="documentsLoading"
      />
      <ModalPresentation v-else-if="showPresentationModal" />
      <DialogModal />
      <GlobalSearch />
    </ClientOnly>
  </template>
</template>
<script setup>
  import { flare } from "@flareapp/js";
  import QueryString from "qs";

  useHead({
    script: [
      {
        children:
          "if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) { document.documentElement.classList.add('dark'); } else { document.documentElement.classList.remove('dark'); } localStorage.theme = 'light'; localStorage.theme = 'dark'; localStorage.removeItem('theme');",
      },
      {
        children:
          "if(navigator.userAgent.indexOf('iPhone') > -1 ){document.querySelector('[name=viewport]').setAttribute('content','width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover');}",
      },
    ],
    htmlAttrs: {
      "data-theme": "light",
    },
  });

  const { locale, t } = useI18n();
  const { isUserLoggedIn, accountId, user, getLegalDocuments, hasSubdomains, isExternalAccess } = useAuth();
  const importSocketChannel = ref("");
  const hasPendingLegal = ref(false);
  const pendingDocument = ref({});
  const pendingDocuments = ref([]);
  const documentsLoading = ref(false);

  const showPresentationModal = computed(() => {
    if (isExternalAccess.value) return false;
    return true;
  });

  //add current os to the body class for styling ie. ios, android, windows, mac
  onMounted(() => {
    if (process.client) {
      document.body.classList.add(getOS().toLowerCase());
    }
  });

  const importerStates = ["awaiting", "failed", "done"];
  const { getEchoClient, destroyEchoClient, hasEchoClient } = useEcho();
  const setupImporterSockets = () => {
    importSocketChannel.value = `order_import.${accountId.value}`;
    const echo = getEchoClient();
    importerStates.forEach((key) => {
      echo.private(importSocketChannel.value).listen(`.${key}`, (e) => {
        switch (key) {
          case "done":
            onImportDone(e);
            break;
          case "awaiting":
            onImportAwaiting(e);
            break;
          case "failed":
            onImportFailed(e);
            break;
        }
      });
    });
  };

  const destroyImporterSockets = () => {
    if (!hasEchoClient.value) return;
    const echo = getEchoClient();
    importerStates.forEach((key) => {
      echo.private(importSocketChannel.value).stopListening(`.${key}`);
    });

    importSocketChannel.value = "";
    destroyEchoClient();
  };

  watch(isUserLoggedIn, (value) => {
    if (process.server) return;
    if (value) {
      if (user.value)
        flare.addContextGroup("user", {
          email: user.value.email,
          name: user.value.name,
          accountId: accountId.value,
        });

      setupImporterSockets();

      checkAuthCookies().then(() => {
        getLegalDocuments().then((data) => {
          if (!data) return;

          if (data.pending_documents && data.pending_documents.length > 0) {
            hasPendingLegal.value = true;
            pendingDocuments.value = data.pending_documents;
            pendingDocument.value = data.pending_documents[0];
          }
        });
      });
    } else destroyImporterSockets();
  });

  const checkAuthCookies = () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const hrToken = document.cookie.split("; ").find((row) => row.startsWith("hr_token="));
        const hrTokenExpire = document.cookie.split("; ").find((row) => row.startsWith("hr_token_expire="));

        if (hrToken && hrTokenExpire) {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  };

  const acceptDocument = (document) => {
    if (document) {
      documentsLoading.value = true;
      homeFetch(`customers/legal-documents/${document.id}`, {
        method: "POST",
      })
        .then(() => {
          documentsLoading.value = false;
          pendingDocuments.value = pendingDocuments.value.filter((item) => item.id !== document.id);
          if (pendingDocuments.value.length !== 0) {
            pendingDocument.value = pendingDocuments.value[0];
          } else {
            hasPendingLegal.value = false;
          }
        })
        .finally(() => {});
    }
  };

  onUnmounted(() => {
    destroyImporterSockets();
  });

  const onImportDone = (data) => {
    const base = useRouter().resolve({ name: "shipments" });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "done" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_import_done",
      title: t("new_shipment_generated"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };

  const onImportAwaiting = (data) => {
    const base = useRouter().resolve({
      name: "shipments-import-list",
    });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "awaiting" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_imported",
      title: t("new_shipment_imported"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };

  const onImportFailed = (data) => {
    const base = useRouter().resolve({
      name: "shipments-import-list",
    });
    const url =
      base.path +
      "?" +
      QueryString.stringify({ filters: { state: { $or: [{ $eq: "failed" }] } } }, { encodeValuesOnly: true });

    $toast.add({
      group: "shipment_import_failed",
      title: t("new_shipment_failed"),
      actions: [
        {
          text: t("do_view"),
          handler: () => {
            useRouter().push(url);
          },
        },
      ],
    });
  };
</script>
<style>
  html,
  body,
  #__nuxt,
  #__layout {
    height: 100% !important;
    width: 100% !important;
  }
</style>
