<template>
  <Button
    :variant="buttonVariant"
    :size="buttonSize"
    :icon="triggerIconPrefix"
    ref="trigger"
    @click.prevent.stop="toggleDropdown"
    :active="showDropdown"
    :iconOnly="!triggerText"
    type="button"
  >
    <template v-if="triggerIconPrefix" #prefix>
      <Icon :src="triggerIconPrefix" />
    </template>
    {{ triggerText }}
    <template v-if="triggerIconSuffix" #suffix>
      <Icon :src="triggerIconSuffix" />
    </template>
  </Button>

  <Dropdown
    v-model:open="showDropdown"
    v-if="trigger"
    :toggle-element="trigger.el"
    ref="dropdown"
    :overlay="false"
    :calculatePosition="calculatePosition"
  >
    <div
      class="overflow-y-auto p-1.5"
      :class="{
        'p-2 space-y-2': size === 'large',
      }"
      :style="{
        'max-height': maxHeight,
      }"
      ref="optionsWrapper"
    >
      <template v-for="(option, index) in options" :key="`${option ? option.value : 'divider_' + index}`">
        <Divider v-if="option == null" />

        <DropdownItem
          v-else
          :size="size"
          @click="() => selectOption(option.value)"
          @keydown.enter.prevent="() => selectOption(option.value)"
          @keydown.down.prevent="() => setFocusOnItem(index, 'down')"
          @keydown.up.prevent="() => setFocusOnItem(index, 'up')"
          :has-arrow="hasArrow"
          :title="option.label"
          :subtext="option.subtext"
          :disabled="option.disabled"
          :variant="option.destructive ? 'destructive' : 'default'"
        >
          <template v-if="option.icon" #prefix>
            <Icon :src="option.icon" />
          </template>
          <template v-else-if="option.image" #prefix>
            <Avatar
              size="2xs"
              :entity="{
                image: option.image,
              }"
            />
          </template>
        </DropdownItem>
      </template>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      triggerText?: string;
      triggerIconPrefix?: string | null;
      triggerIconSuffix?: string | null;
      size?: "default" | "large";
      actions: DropdownOption[];
      maxHeight?: string;
      hasArrow?: boolean;
      buttonSize?: ButtonSize;
      buttonVariant?: ButtonVariant;
    }>(),
    {
      triggerIconPrefix: null,
      triggerIconSuffix: null,
      size: "default",
      maxHeight: "16rem",
      buttonSize: ComponentSize.sm,
      buttonVariant: "tertiary",
    }
  );

  const { dropdown, trigger, showDropdown, toggleDropdown, calculatePosition } = useDropdown();
  const modelValue = ref("");

  const emit = defineEmits(["select", "update:modelValue"]);
  const options = computed(() => props.actions);

  const { optionsWrapper, setFocusOnItem, selectOption } = useSelect({
    options,
    dropdown,
    modelValue,
    emit,
    open: showDropdown,
    toggleDropdown,
  });
</script>
