<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper">
    <div class="flex w-full flex-col justify-between gap-4">
      <div class="settings-flow-header text-body-lg-heavy">
        {{ t("settings.organisation.workspace.name.title") }}
      </div>
      <div class="flex w-full flex-wrap gap-3">
        <InputText class="flex-1" v-model="information.name"> </InputText>
        <InputSubdomain class="flex-1" v-model="information.subdomain" :placeholder="information.name" />
      </div>
    </div>
    <Divider />
    <div class="flex w-full flex-col justify-between gap-3">
      <div class="flex-col gap-0.5">
        <div class="settings-flow-header text-body-lg-heavy">
          {{ t("settings.organisation.workspace.address.title") }}
        </div>
        <div class="text-body-default text-quarterary">
          {{ t("settings.organisation.workspace.address.description") }}
        </div>
      </div>
      <div class="grid auto-cols-fr grid-cols-2 gap-3 @lg:grid-cols-4" v-if="shippingIndex !== -1">
        <InputText
          class="col-span-2 w-full"
          v-model="information.contact_info[shippingIndex].name"
          :label="t('organisation_name')"
        />
        <InputText
          class="col-span-2 w-full"
          v-model="information.contact_info[shippingIndex].street1"
          :label="t('street_name_and_number')"
        />
        <div class="col-span-2 grid grid-cols-2 gap-3 @lg:col-span-4 @lg:grid-cols-4">
          <InputText
            class="col-span-2 w-full @md:col-span-1"
            v-model="information.contact_info[shippingIndex].zip_code"
            :label="t('zip_code')"
          />
          <InputText
            class="col-span-2 w-full @md:col-span-1"
            v-model="information.contact_info[shippingIndex].city"
            :label="t('city')"
          />
          <DropdownSelectCountry
            class="col-span-2"
            v-model="information.contact_info[shippingIndex].country_iso"
            :label="t('country')"
            triggerType="input"
          />
        </div>
        <InputText
          class="col-span-2"
          v-model="information.contact_info[shippingIndex].phone"
          :label="t('phone_number')"
        />
        <InputText class="col-span-2" v-model="information.contact_info[shippingIndex].email" :label="t('email')" />

        <div class="col-span-2 @lg:col-span-4">
          <TickerCheckbox
            v-model="billingAndShippingSame"
            :label="t('settings.organisation.workspace.address.billing_and_shipping_same')"
          />
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col justify-between gap-3" v-if="!billingAndShippingSame && billingIndex !== -1">
      <div class="flex-col gap-0.5">
        <div class="text-body-lg-heavy">
          {{ t("settings.organisation.workspace.address.invoice.title") }}
        </div>
      </div>
      <div class="grid auto-cols-fr grid-cols-2 gap-3 @lg:grid-cols-4">
        <InputText
          class="col-span-2 w-full"
          v-model="information.contact_info[billingIndex].name"
          :label="t('organisation_name')"
        />
        <InputText
          class="col-span-2 w-full"
          v-model="information.contact_info[billingIndex].street1"
          :label="t('street_name_and_number')"
        />
        <div class="col-span-2 grid grid-cols-2 gap-3 @lg:col-span-4 @lg:grid-cols-4">
          <InputText
            class="col-span-2 w-full @md:col-span-1"
            v-model="information.contact_info[billingIndex].zip_code"
            :label="t('zip_code')"
          />
          <InputText
            class="col-span-2 w-full @md:col-span-1"
            v-model="information.contact_info[billingIndex].city"
            :label="t('city')"
          />
          <DropdownSelectCountry
            class="col-span-2"
            v-model="information.contact_info[billingIndex].country_iso"
            :label="t('country')"
          />
        </div>
        <InputText
          class="col-span-2"
          v-model="information.contact_info[billingIndex].phone"
          :label="t('phone_number')"
        />
        <InputText class="col-span-2" v-model="information.contact_info[billingIndex].email" :label="t('email')" />
      </div>
    </div>
    <Divider />

    <div class="flex w-full flex-col justify-between gap-3">
      <div class="flex-col gap-0.5">
        <div class="text-body-lg-heavy">
          {{ t("weight_unit", 99) }}
        </div>
        <div class="text-body-default text-quarterary">
          {{ t("settings.organisation.workspace.weight_unit.description") }}
        </div>
      </div>
      <div class="grid auto-cols-fr grid-cols-2 gap-3 @lg:grid-cols-4">
        <div>
          <TickerRadio :selected="isWeightUnit('g')" @click="() => setWeightUnit('g')" rounded framed>
            {{ t("gram") }} (g)
          </TickerRadio>
        </div>

        <div>
          <TickerRadio :selected="isWeightUnit('kg')" @click="() => setWeightUnit('kg')" rounded framed>
            {{ t("kilogram") }} (kg)
          </TickerRadio>
        </div>

        <div>
          <TickerRadio :selected="isWeightUnit('lb')" @click="() => setWeightUnit('lb')" rounded framed>
            {{ t("pound") }} (lb)
          </TickerRadio>
        </div>

        <div>
          <TickerRadio :selected="isWeightUnit('oz')" @click="() => setWeightUnit('oz')" rounded framed>
            {{ t("ounce") }} (oz)
          </TickerRadio>
        </div>
      </div>
    </div>
  </div>
  <NotificationWrapper />
</template>

<script setup lang="ts">
  import _ from "lodash";
  import type { ISettingsFlow } from "../types";

  import NotificationWrapper from "../components/NotificationWrapper.vue";

  export interface OrganisationInformation {
    id: number;
    name: string;
    vat: string;
    payment_method: string;
    business: boolean;
    test_mode: number;
    level: string;
    created_at: Date;
    updated_at: Date;
    core_customer_id: number;
    account_id: null;
    deleted_at: null;
    disabled_at: null;
    subdomain: string;
    contact_info: ContactInfo[];
  }

  export interface ContactInfo {
    id: number;
    title: null;
    name: string;
    phone_prefix: null;
    phone: string;
    email: string;
    street1: string;
    street2: null;
    zip_code: string;
    city: string;
    type: "BILLING" | "SHIPPING";
    country_id: number;
    country_iso: string;
    entity_type: string;
    entity_id: number;
    latitude: number;
    longitude: number;
    created_at: Date;
    updated_at: Date;
  }
  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );
  const { t } = useI18n();
  const information = ref<OrganisationInformation>({} as OrganisationInformation);
  const billingAndShippingSame = ref();
  const billingIndex = ref(-1);
  const shippingIndex = ref(-1);
  let initialInformation: OrganisationInformation = {} as OrganisationInformation;
  let initialSame: boolean;

  const fetchInformation = async () => {
    setLoading(true);
    let info = await getOrganisationInfo();
    if (info) {
      information.value = info;
      initialInformation = _.cloneDeep(info);
    }
    getIndices();
    checkIfBillingAndShippingSame(true);
    setLoading(false);
  };

  const weightUnit = ref<string>(usePreferredWeightUnit().value);
  const isWeightUnit = (unit: string) => weightUnit.value === unit;
  const setWeightUnit = (unit: string) => {
    weightUnit.value = unit;
  };
  const weightUnitHasChanged = computed(() => weightUnit.value !== usePreferredWeightUnit().value);
  const resetWeightUnit = () => {
    weightUnit.value = usePreferredWeightUnit().value;
  };

  const updateInformation = () => {
    return homeFetch("workspace/organisation", {
      method: "PUT",
      body: JSON.stringify(information.value),
    }).then(fetchInformation);
  };

  const save = () => {
    const savePromises: Promise<any>[] = [];
    if (informationHasChanged.value) {
      savePromises.push(updateInformation());
    }
    if (weightUnitHasChanged.value) {
      savePromises.push(updateWeightUnit(weightUnit.value));
    }

    Promise.all(savePromises).then(() => {
      $toast.remove("organisation_settings");

      $toast.add({
        title: t("changes_saved"),
        target: "#settings-modal .notification-wrapper",
      });
    });
  };

  const getIndices = () => {
    if (information.value?.contact_info) {
      billingIndex.value = information.value.contact_info.findIndex((c) => c.type === "BILLING");
      shippingIndex.value = information.value.contact_info.findIndex((c) => c.type === "SHIPPING");
    }
  };

  const checkIfBillingAndShippingSame = (setInitial: boolean) => {
    if (information.value?.contact_info) {
      const billing = information.value.contact_info.find((c) => c.type === "BILLING");
      const shipping = information.value.contact_info.find((c) => c.type === "SHIPPING");
      if (billing && shipping) {
        //check all fields except id, created_at, updated_at, latitude, longitude, title and type

        billingAndShippingSame.value = Object.keys(billing).every((key) => {
          if (
            key === "id" ||
            key === "created_at" ||
            key === "updated_at" ||
            key === "latitude" ||
            key === "longitude" ||
            key === "title" ||
            key === "type" ||
            key === "country"
          ) {
            return true;
          }
          return billing[key] === shipping[key];
        });

        if (setInitial) initialSame = billingAndShippingSame.value;
      }
    }
  };

  watch(billingAndShippingSame, (val, oldVal) => {
    if (!information.value.contact_info) return;
    // Check if oldVal is undefined
    if (oldVal === undefined) return;

    // Check if billing and shipping are the same
    if (billingAndShippingSame.value) {
      // Check if billingIndex is -1
      if (billingIndex.value === -1) {
        // Push a new contact info object with the same values as the shipping contact info, but with type "BILLING"
        information.value.contact_info.push({
          ...information.value.contact_info[shippingIndex.value],
          type: "BILLING",
        });
        return;
      }
      // Check if the shipping contact info has changed
      if (
        !_.isEqual(
          information.value.contact_info[shippingIndex.value],
          initialInformation.contact_info[shippingIndex.value]
        )
      ) {
        // Replace the billing contact info with the initial shipping contact info, but with type "BILLING"
        information.value.contact_info[billingIndex.value] = {
          ...initialInformation.contact_info[shippingIndex.value],
          type: "BILLING",
        };
        return;
      } else {
        // Check if the initial billing and shipping are the same
        if (initialSame) {
          // Reset the billing contact info to the initial values
          information.value.contact_info[billingIndex.value] = {
            ...initialInformation.contact_info[billingIndex.value],
          };
        } else {
          // Replace the billing contact info with the shipping contact info, but with type "BILLING"
          information.value.contact_info[billingIndex.value] = {
            ...information.value.contact_info[shippingIndex.value],
            type: "BILLING",
          };
        }
      }
    }
  });

  const informationHasChanged = computed(() => {
    if (!information.value.id) return false;
    return !_.isEqual(information.value, initialInformation);
  });

  const hasChanges = computed(() => {
    return informationHasChanged.value || weightUnitHasChanged.value;
  });

  watch(
    hasChanges,
    (val, oldVal) => {
      if (!hasChanges.value) {
        $toast.remove("organisation_settings");
        return;
      }
      $toast.add({
        duration: 0,
        id: "organisation_settings",
        title: t("unsaved_changes"),
        target: "#settings-modal .notification-wrapper",
        icon: null,
        actions: [
          {
            text: t("undo"),
            handler: () => {
              resetOrganisationInformation();
              resetWeightUnit();
            },
          },
          {
            text: t("save_changes"),
            handler: () => {
              save();
            },
          },
        ],
      });
    },
    {
      deep: true,
    }
  );

  const resetOrganisationInformation = () => {
    information.value = _.cloneDeep(initialInformation);
    billingAndShippingSame.value = initialSame;
  };

  onMounted(() => {
    fetchInformation().then(() => {
      goToCurrentHeader();
    });
  });

  onDeactivated(() => {
    resetOrganisationInformation();
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
  export const useOrganisationSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/nut";
    const title = t("settings.organisation.title");
    const pageTitle = t("settings.organisation.page_title");
    const key = "organisation";
    const headers = [
      t("settings.organisation.workspace.name.title"),
      t("settings.organisation.workspace.address.title"),
      t("weight_unit"),
    ];

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
    };
  };
</script>
