<template>
  <div class="h-full">
    <div class="space-x-4 whitespace-nowrap overflow-auto flex group/bar h-full items-stretch px-2" ref="tabbar">
      <slot> </slot>
    </div>
    <Divider :spacing="false" />
  </div>
</template>

<script setup lang="ts">
  const tabbar = ref<HTMLElement | null>(null);

  onMounted(() => {
    useHasScrollbar(tabbar.value);
    nextTick(() => {
      if (tabbar.value) {
        tabbar.value.addEventListener(
          "wheel",
          (e) => {
            if (tabbar.value) {
              if (e.deltaX !== 0) {
                tabbar.value.scrollLeft += e.deltaX;
              } else if (e.deltaY !== 0) {
                tabbar.value.scrollLeft += e.deltaY;
              }
            }
          },
          { passive: true }
        );

        //for each tab in the tabbar  add an event listener
        tabbar.value.childNodes.forEach((tab) => {
          tab.addEventListener("active", (e) => {
            const { tab: tabElement } = e.detail;
            const tabbarElement = tabbar.value as HTMLElement;
            const tabbarWidth = tabbarElement.offsetWidth;
            const tabWidth = tabElement.offsetWidth;
            const tabOffsetLeft = tabElement.offsetLeft;

            //get width of aside
            const asideWidth = document.querySelector("aside")?.offsetWidth || 0;
            tabbarElement.scrollTo({
              left: tabOffsetLeft - tabbarWidth / 2 + tabWidth / 2 - asideWidth,
              behavior: "smooth",
            });
          });
        });
      }
    });
  });
</script>
