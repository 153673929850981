import {
  TableRowCellActions,
  TableRowCellChip,
  TableRowCellDefault,
  TableRowCellFiletype,
  TableRowCellLead,
  TableRowCellTracking,
} from "#components";
import _ from "lodash";
import type { ITableData, ITableHeader } from "~/components/Table/types";

export const useTableCellValue = (row: any, header: ITableHeader, lead?: boolean) => {
  return computed(() => {
    if (header.valueFunc) return header.valueFunc(row);
    else if (header.mappings) return header.mappings[row[header.key]];
    else {
      const rawValue = getNestedValue(row, header.key);

      switch (header.type) {
        case "date":
        case "datetime":
        case "datetime_relative":
          return formatDate(rawValue);
        case "price":
          return useFormatPrice(rawValue, true, getCurrency(row, header));
        case "weight":
          return useFormatWeight(rawValue);
        default:
          return rawValue;
      }
    }
  });
};

export const useTableCellSubValue = (row: any, header: ITableHeader, lead?: boolean) => {
  return computed(() => {
    const type = cellType(header, lead);

    if ((type !== "lead" && type !== "default") || !header.subValueKey) {
      return null;
    }
    if (header.subValueFunc) return header.subValueFunc(row);
    else if (header.subValueMappings) return header.subValueMappings[row[header.key]];
    else {
      const rawValue = getNestedValue(row, header.subValueKey);
      switch (header.subValueType) {
        case "date":
        case "datetime":
        case "datetime_relative":
          return formatDate(rawValue);
        case "price":
          return useFormatPrice(rawValue, true, getCurrency(row, header));
        case "weight":
          return useFormatWeight(rawValue);
        default:
          return rawValue;
      }
    }
  });
};

export const useIsDestructive = (header: ITableHeader, row: any, lead?: boolean) => {
  return computed(() => {
    if (!lead) return false;
    //check if header.destructive is boolean or function
    if (typeof header.destructive === "function") return header.destructive(row);
    else return header.destructive;
  });
};

export const cellComponent = (header: ITableHeader, lead?: boolean) => {
  switch (cellType(header, lead)) {
    case "chip":
      return TableRowCellChip;
    case "tracking":
      return TableRowCellTracking;
    case "lead":
      return TableRowCellLead;
    case "actions":
      return TableRowCellActions;
    case "file_type":
      return TableRowCellFiletype;
    default:
      return TableRowCellDefault;
  }
};

export const isRowNew = (item: ITableData, newIndicator?: string | Function) => {
  if (!newIndicator) return false;
  //if props.newIndicator is a string, return the value of the key
  if (typeof newIndicator === "string") {
    return _.get(item, newIndicator);
  }

  //if props.newIndicator is a function, return the value of the function
  return newIndicator(item);
};

const getCurrency = (item: any, header: ITableHeader) => {
  if (header.currency) {
    //if currency is a function, call it with item as argument
    if (typeof header.currency === "function") return header.currency(item);
    else return header.currency;
  }
  return item.currency;
};

const cellType = (header: ITableHeader, lead?: boolean) => {
  if (header.type) return header.type;
  else return lead ? "lead" : "default";
};
