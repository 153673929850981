import { RouteName } from "~/types/routes";

export default (key: string) => {
  const { isAccountBusiness, isDFM, hasService } = useAuth();
  switch (key) {
    case RouteName.shipments:
    case RouteName.shipments_details:
    case RouteName.shipments_label:
      return true;
    case RouteName.invoices:
      return true;
    case RouteName.documents:
      return true;
    case RouteName.prices:
      return true;
    case RouteName.returns:
      return isAccountBusiness();
    case RouteName.tickets:
    case RouteName.tickets_details:
      return !isDFM.value;
    case RouteName.sortings:
      return isAccountBusiness() && hasService("transfer");
    default:
      return true;
  }
};
