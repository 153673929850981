<template>
  <div
    class="calendar-cell w-10 h-10 rounded-xl flex items-center justify-center relative text-sm transition-colors duration-200 font-medium cursor-pointer"
    :class="{
      'bg-00 text-secondary hover:text-primary hover:bg-01': !active && today,
      '!bg-brand !text-white !hover:bg-brand-dark': active && position !== 'middle',
      'bg-surface-lvl-01 text-secondary hover:bg-01': active && position === 'middle',
      'text-secondary hover:bg-01 hover:text-primary': !active && !today,
      'bg-disabled': disabled && (active || today),
      '!text-disabled': disabled,
      'before:content[\'\'] before:absolute before:h-full before:z-[-1]': connector != 'none',
      'before:bg-calendar-cell-connector-left before:w-8 before:right-8': connector == 'left',
      'before:bg-calendar-cell-connector-right before:w-8 before:left-full': connector == 'right',
      'before:bg-calendar-cell-connector-both before:w-16': connector == 'both',
    }"
  >
    <slot></slot>
    <div class="bottom-1 absolute flex justify-center" v-if="dot">
      <div
        class="h-1.5 w-1.5 rounded-full"
        :class="{
          '': active,
          'bg-brand-dark': !active && !disabled,
          'bg-foreground-disabled': !active && disabled,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";

  const props = withDefaults(
    defineProps<{
      position?: "leading" | "trailing" | "middle" | null;
      dot?: boolean;
      today?: boolean;
      disabled?: boolean;
      connector?: "none" | "left" | "right" | "both";
    }>(),
    {
      dot: false,
      today: false,
      disabled: false,
      connector: "none",
    }
  );

  const active = computed(() => {
    return !!props.position;
  });
</script>
