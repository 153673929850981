<template>
  <VueFinalModal
    class="flex items-center justify-center p-5"
    v-model="open"
    contentClass="overflow-visible rounded-xl max-w-screen-lg w-full"
    overlayClass="bg-modal-overlay"
    overlayTransition="vfm-fade"
    contentTransition="vfm-fade"
    clickToClose
    @closed="onClosed"
    @opened="onOpened"
    :escToClose="false"
    :focusTrap="false"
  >
    <div class="relative w-full rounded-xl" ref="modalContent">
      <GlobalSearchContainer class="relative w-full gap-1.5 bg-surface-lvl-00 p-1.5 pb-0 shadow-lvl-03">
        <GlobalSearchInput
          :show="open"
          ref="searchInput"
          @keydown.down.prevent="() => arrowPress('down')"
          @keydown.up.prevent="() => arrowPress('up')"
          @keydown.enter.prevent="clickCurrentResult"
        />
        <div>
          <Transition name="scale-height" mode="out-in">
            <div v-if="showRecentSearches">
              <GlobalSearchCategory :category="'recents' as SearchResultCategory">
                <GlobalSearchResult
                  v-for="result in gsStore.recentSearches"
                  :key="result.id"
                  :result="result"
                  :category="'recent'"
                  @mouseenter="handleResultHover(result.id)"
                />
              </GlobalSearchCategory>
            </div>
            <div
              class="relative grid max-w-full grid-cols-[minmax(max-content,_1fr)_17px_minmax(250px,_400px)]"
              v-else-if="showResults"
            >
              <div
                class="overflow-y-auto transition-all"
                :style="{
                  maxHeight,
                }"
              >
                <template v-for="(category, key) in gsStore.results" :key="key">
                  <GlobalSearchCategory v-if="category.length" :category="key">
                    <GlobalSearchResult
                      v-for="result in category"
                      :key="result.id"
                      :result="result"
                      @mouseenter="handleResultHover(result.id)"
                    />
                  </GlobalSearchCategory>
                </template>
              </div>
              <Divider rotation="vertical"></Divider>
              <GlobalSearchInfoContainer></GlobalSearchInfoContainer>
            </div>
            <div class="flex h-[182px] w-full flex-col items-center justify-center gap-2" v-else-if="showNoResults">
              <img src="~/assets/illustrations/cc-lighthouse.svg" alt="No shipments illustration" />
              <div class="flex flex-col gap-0.5 text-center">
                <div class="text-body-lg-heavy">
                  {{ t("no_search_results") }}
                </div>
                <div class="text-body-default text-tertiary">
                  {{ t("no_search_results_description") }}
                </div>
              </div>
            </div>
          </Transition>
        </div>

        <div class="sticky bottom-0 z-10 bg-inherit px-4 py-1.5 pb-3">
          <div class="flex items-center gap-8 text-quarterary">
            <div class="flex items-center gap-1.5">
              <div>
                <Icon src="bold/ArrowsDownUp" />
              </div>
              <div class="text-body-default">
                {{ t("select") }}
              </div>
            </div>

            <div class="flex items-center gap-1.5">
              <div>
                <Icon src="bold/ArrowUDownLeft" />
              </div>
              <div class="text-body-default">
                {{ t("open") }}
              </div>
            </div>
            <div class="flex items-center gap-1.5">
              <div class="flex items-center text-body-default-heavy">esc</div>
              <div class="text-body-default">
                {{ t("close_search") }}
              </div>
            </div>
            <div class="flex items-center gap-1.5">
              <div class="flex items-center text-body-default-heavy">⌘K</div>
              <div class="text-body-default">
                {{ t("open_search") }}
              </div>
            </div>
            <div class="ms-auto flex items-center gap-1.5" v-show="gsStore.loading">
              <div class="text-body-default">{{ t("searching") }}...</div>
              <Spinner class="size-4" />
            </div>
          </div>
        </div>
      </GlobalSearchContainer>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
  import { useGlobalSearchStore } from "~/stores/GlobalSearch";
  import _ from "lodash";
  import { VueFinalModal } from "vue-final-modal";
  import type { SearchResultCategory } from "~/types/search";

  defineOptions({
    name: "GlobalSearch",
  });

  const { t } = useI18n();
  const gsStore = useGlobalSearchStore();

  const usingKeyboard = ref(true);
  const open = ref(false);
  const searchInput = ref<HTMLInputElement | null>(null);

  const hasSearch = computed(() => gsStore.search.length >= 3);

  const showResults = computed(() => {
    if (!hasSearch.value && gsStore.recentSearches?.length) return true;
    return hasSearch.value && gsStore.results && Object.values(gsStore.results).some((x) => x.length);
  });

  const showNoResults = computed(() => {
    return hasSearch.value && gsStore.results && !Object.values(gsStore.results).some((x) => x.length);
  });

  const showRecentSearches = computed(() => {
    return !hasSearch.value && gsStore.recentSearches?.length;
  });

  const maxHeight = computed(() => {
    if (showResults.value) {
      return "636px";
    } else if (showNoResults.value) {
      return "290px";
    }
    return "102px";
  });

  const onOpened = () => {
    document.addEventListener("mousemove", handleMouseMoved, false);
  };

  const onClosed = () => {
    gsStore.search = "";
    gsStore.results = undefined;
    gsStore.shipments.length = 0;
    gsStore.tickets.length = 0;
    gsStore.currentResultIndex = 0;

    document.removeEventListener("mousemove", handleMouseMoved, false);
  };

  const handleMouseMoved = () => {
    gsStore.usingKeyboard = false;
  };

  const toggleModal = () => {
    open.value = !open.value;

    if (open.value) {
      setTimeout(() => {
        searchInput.value?.focus();
      });
    } else {
      onClosed();
    }
  };

  const onKeyPress = (e: KeyboardEvent) => {
    //if development environment, use cmd+k to open the modal
    if (getOS() === "Mac") {
      if (e.key === "k" && e.metaKey) {
        toggleModal();
        e.preventDefault();
      }
    } else {
      if (e.key === "k" && e.ctrlKey) {
        toggleModal();
        e.preventDefault();
      }
    }

    if (e.key === "Escape" && open.value) {
      if (gsStore.search.length > 0) {
        gsStore.search = "";
      } else {
        toggleModal();
      }
      e.preventDefault();
    }
  };

  const arrowPress = (direction: "up" | "down") => {
    if (!gsStore.listedResults.length) return;
    gsStore.usingKeyboard = true;

    if (gsStore.currentResultIndex === undefined) {
      gsStore.currentResultIndex = direction === "down" ? 0 : gsStore.listedResults.length - 1;
      return;
    }

    let nextIndex = gsStore.currentResultIndex + (direction === "down" ? 1 : -1);
    if (nextIndex < 0) {
      nextIndex = gsStore.listedResults.length - 1;
    } else if (nextIndex >= gsStore.listedResults.length) {
      nextIndex = 0;
    }

    gsStore.currentResultIndex = nextIndex;
  };

  const handleResultHover = (id: number) => {
    const index = gsStore.listedResults.findIndex((x) => x.id === id);
    if (gsStore.usingKeyboard || index === -1) return;
    gsStore.currentResultIndex = index;
  };

  const doSearch = () => {
    if (gsStore.search.length < 3) {
      return;
    }
    gsStore.loading = true;
    const searchedString = gsStore.search;

    homeFetch("search", {
      query: {
        search: gsStore.search,
      },
    }).then(({ data }) => {
      if (searchedString !== gsStore.search) return;

      gsStore.results = data;
      data ? (gsStore.currentResultIndex = 0) : undefined;
      gsStore.loading = false;
    });
  };

  const clickCurrentResult = () => {
    if (gsStore.currentResult) {
      const result = document.querySelector(
        `[data-result="${gsStore.currentResult.package_number}_${gsStore.currentResult.id}"]`
      ) as HTMLButtonElement;
      if (result) {
        result.click();
      }
    }
  };

  const debouncedSearch = _.debounce(doSearch, 300);

  //watch the search value and debounce the search
  watch(
    () => gsStore.search,
    () => {
      if (gsStore.search.length < 3) {
        gsStore.results = undefined;
        gsStore.currentResultIndex = undefined;
        return;
      }
      debouncedSearch();
    }
  );

  onMounted(() => {
    document.addEventListener("keydown", onKeyPress);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", onKeyPress);
  });

  useRouter().beforeEach(() => {
    if (open.value) {
      toggleModal();
    }
  });
</script>
