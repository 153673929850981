<template>
  <div>
    <div
      class="flex gap-2 w-full"
      :title="item.label"
      :class="{
        'cursor-pointer': hasOnClick,
      }"
      @click="onClick"
    >
      <Icon
        class="transform transition-all duration-300 my-auto flex-shrink-0"
        v-if="slots.default"
        src="bold/CaretDown"
        filled
        :class="{
          '-rotate-90': !expanded,
          'opacity-0': !selected && expandOnToggle,
        }"
      ></Icon>
      <div class="flex items-center gap-2 w-full basis-full min-w-0">
        <Avatar
          v-if="item.image"
          size="xs"
          :entity="{
            name: item.label,
            image: item.image,
          }"
        />
        <div class="flex flex-col w-full min-w-0">
          <div class="text-body-lg-heavy whitespace-nowrap text-ellipsis overflow-hidden">{{ item.label }}</div>
          <p class="text-body-default text-quarterary" v-if="item.description">{{ item.description }}</p>
        </div>
        <Toggle :model-value="selected" @update:model-value="toggle" @click.stop.prevent="" />
      </div>
    </div>
    <div class="flex gap-2 w-full mt-3" v-if="expanded">
      <slot v-bind="item"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  export interface IToggleListItemEntry {
    label: string;
    description?: string;
    image?: string;
    value: string | number;
  }

  const { t } = useI18n();
  const slots = defineSlots();

  const selected = defineModel({ default: false });
  const expanded = ref(false);

  const props = withDefaults(
    defineProps<{
      item: IToggleListItemEntry;
      expanded?: boolean;
      expandOnToggle?: boolean;
    }>(),
    {
      expanded: false,
      expandOnToggle: false,
    }
  );
  const toggle = (val) => {
    if (props.expandOnToggle) {
      expanded.value = val;
    }

    selected.value = val;
  };

  const hasOnClick = computed(() => {
    if (!slots.default) {
      return false;
    }

    if (props.expandOnToggle && !selected.value) {
      return false;
    }

    return true;
  });

  const onClick = () => {
    if (!hasOnClick.value) return;

    expanded.value = !expanded.value;
  };

  const expand = () => {
    expanded.value = true;
  };

  const collapse = () => {
    expanded.value = false;
  };

  defineExpose({
    expand,
    collapse,
  });
</script>
