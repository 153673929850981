<template>
  <div class="p-4 rounded-lg border border-01 flex flex-col justify-between items-center h-[270px]">
    <div class="flex flex-col items-center w-full">
      <div class="relative h-8 w-8 mb-3">
        <Icon class="text-secondary" src="regular/printer" :size="ComponentSize.xl2" />
        <div
          class="w-2 h-2 bg-positive absolute bottom-[3px] right-0 rounded-full ring-2 ring-white"
          v-if="isConsideredActive"
        ></div>
      </div>
      <div class="flex flex-col w-full text-center items-center">
        <BadgeChip v-if="printer.default" type="bold">
          {{ t("default") }}
        </BadgeChip>
        <div class="text-body-lg-heavy w-full text-ellipsis overflow-hidden">
          {{ printer.alias }}
        </div>
        <div class="text-body-default text-quarterary">
          {{ printer.print_service.hostname }}
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2 w-full text-center">
      <div class="flex gap-2">
        <Button class="flex-1" :size="ComponentSize.sm" @click="testPrinter" :loading="loading">
          {{ t("settings.printers.test") }}
        </Button>
        <Button
          :size="ComponentSize.sm"
          variant="tertiary"
          ref="trigger"
          @click="toggleDropdown"
          :active="showDropdown"
        >
          <Icon class="text-foreground-secondary" src="bold/DotsThreeVertical" />
        </Button>
      </div>
      <div class="text-body-sm text-disabled flex gap-1 justify-center">
        <Icon src="bold/ClockCounterClockwise"></Icon> {{ latestActiveAtHuman }}
      </div>
    </div>
  </div>
  <Dropdown
    v-model:open="showDropdown"
    ref="dropdown"
    maxWidth="162px"
    :toggleElement="trigger"
    :calculatePosition="calculateDropdownPosition"
  >
    <div class="flex flex-col dropdown__content p-1.5">
      <DropdownItem @click="toggleRenameDropdown">
        {{ t("rename") }}
      </DropdownItem>
      <DropdownItem @click="() => setAsDefault(!printer.default)">
        {{ printer.default ? t("unset_as_default") : t("set_as_default") }}
      </DropdownItem>
      <Divider class="mx-1" />
      <DropdownItem variant="destructive" @click="deletePrinter">
        {{ t("settings.printers.delete") }}
      </DropdownItem>
    </div>
  </Dropdown>

  <Modal
    v-if="showRenameDropdown"
    v-model:open="showRenameDropdown"
    ref="renameDropdown"
    :overlay="false"
    max-width="360px"
    min-width="320px"
  >
    <ModalHeader class="border-b-0">
      {{ t("rename_printer") }}
    </ModalHeader>
    <div class="p-3 space-y-5">
      <InputText v-model="name" :placeholder="t('new_name')" autofocus />
    </div>
    <ModalFooter class="border-t-0 px-4 !py-4 justify-between">
      <Button @click="clearRename" variant="tertiary">{{ t("undo") }}</Button>
      <Button :disabled="!name" :loading="loading" @click="rename" variant="primary">{{ t("save_changes") }}</Button>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
  import { DateTime } from "luxon";
  import type { SettingsPrinter } from "../flows/PrinterSettings.vue";
  import { ComponentSize } from "~/types/global";
  const { t, locale } = useI18n();

  const props = withDefaults(
    defineProps<{
      printer: SettingsPrinter;
    }>(),
    {}
  );

  const { dropdown, trigger, showDropdown, toggleDropdown, setStyleProperty } = useDropdown();
  const loading = ref(false);

  const calculateDropdownPosition = (cb?: null | Function) => {
    //calculate dropdown position based on tr position
    const triggerRect = trigger.value?.el
      ? trigger.value?.el.getBoundingClientRect()
      : trigger.value?.getBoundingClientRect();
    if (triggerRect) {
      let top = triggerRect.bottom;
      let left = triggerRect.left;

      const triggerWidth = triggerRect.width | 0;
      const dropdownWidth = 162;

      setStyleProperty("top", `${top + 3}px`);
      setStyleProperty("left", `${left - triggerWidth * 1.5 - dropdownWidth / 2}px`);
    }

    if (cb && typeof cb === "function") cb();
  };

  const testPrinter = () => {
    loading.value = true;
    homeFetch(`workspace/printers/${props.printer.id}/test`)
      .then((response) => {
        $toast.add({
          title: t("settings.printers.test"),
          text: t("settings.printers.test_success"),
          icon: "bold/printer",
          target: "#settings-modal .notification-wrapper",
        });
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const setAsDefault = (val) => {
    homeFetch(`workspace/printers/${props.printer.id}/set-default`, {
      method: "PUT",
      body: {
        default: val,
      },
    }).then(() => {
      $toast.add({
        title: t("settings.printers.set_as_default", { printer: props.printer.alias }),
        icon: "bold/check",
        target: "#settings-modal .notification-wrapper",
      });
      emit("fetch", props.printer.id);
    });
  };

  const isConsideredActive = computed(() => {
    const minutes: number = props.printer.last_active_at ? Math.floor(parseFloat(props.printer.last_active_at)) : 0;
    if (!minutes) return false;

    return minutes < 5;
  });

  const latestActiveAtHuman = computed(() => {
    const minutes: number = props.printer.last_active_at ? Math.floor(parseFloat(props.printer.last_active_at)) : 0;

    return DateTime.now().setLocale(locale.value).minus({ minutes }).toRelative();
  });

  const deletePrinter = () => {
    $confirm({
      message: t("settings.printers.delete_confirm"),
      onConfirm: () => {
        homeFetch(`workspace/printers/${props.printer.id}`, {
          method: "DELETE",
        }).then(() => {
          $toast.add({
            title: t("settings.printers.delete"),
            text: t("settings.printers.delete_success"),
            icon: "bold/printer",
            target: "#settings-modal .notification-wrapper",
          });
        });
      },
      destructive: true,
    });
  };

  const name = ref("");

  const showRenameDropdown = ref(false);
  const toggleRenameDropdown = () => {
    showRenameDropdown.value = !showRenameDropdown.value;
  };

  const rename = () => {
    loading.value = true;
    homeFetch(`workspace/printers/${props.printer.id}/rename`, {
      method: "PUT",
      body: {
        alias: name.value,
      },
    })
      .then(() => {
        $toast.add({
          title: t("settings.printers.rename"),
          icon: "bold/printer",
          target: "#settings-modal .notification-wrapper",
        });
        clearRename();
        toggleDropdown();

        emit("fetch", props.printer.id);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const clearRename = () => {
    name.value = "";
    toggleRenameDropdown();
  };

  const emit = defineEmits(["fetch"]);
</script>
