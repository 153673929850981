import { default as indexlXWKW23Hj9Meta } from "/app/pages/auth/login/index.vue?macro=true";
import { default as logoutCHyXH6UWwkMeta } from "/app/pages/auth/logout.vue?macro=true";
import { default as indexFfkaDmrbi7Meta } from "/app/pages/auth/register/index.vue?macro=true";
import { default as BaseVcPtdrNxk4Meta } from "/app/pages/auth/register/partials/Mockups/Base.vue?macro=true";
import { default as CreateOrganisationv4H0dnoB4nMeta } from "/app/pages/auth/register/partials/Mockups/CreateOrganisation.vue?macro=true";
import { default as EnterPersonalInformationKwkUlBeqC1Meta } from "/app/pages/auth/register/partials/Mockups/EnterPersonalInformation.vue?macro=true";
import { default as InviteTeamMemberslj2z5WF0WlMeta } from "/app/pages/auth/register/partials/Mockups/InviteTeamMembers.vue?macro=true";
import { default as OrganisationInformationJRhwTiMLB8Meta } from "/app/pages/auth/register/partials/Mockups/OrganisationInformation.vue?macro=true";
import { default as NotificationStepOO3Mx0HUXBMeta } from "/app/pages/auth/register/partials/NotificationStep.vue?macro=true";
import { default as useCreateOrganisation5g9MhNSUQZMeta } from "/app/pages/auth/register/schemas/useCreateOrganisation.ts?macro=true";
import { default as useCreatePassword4F2WytoYglMeta } from "/app/pages/auth/register/schemas/useCreatePassword.ts?macro=true";
import { default as useEnableNotificationscVDntcRZEsMeta } from "/app/pages/auth/register/schemas/useEnableNotifications.ts?macro=true";
import { default as useEnterEmailGfmOaPjEyyMeta } from "/app/pages/auth/register/schemas/useEnterEmail.ts?macro=true";
import { default as useEnterPersonalInformationu3VHk8AKspMeta } from "/app/pages/auth/register/schemas/useEnterPersonalInformation.ts?macro=true";
import { default as useEnterPersonalInformationWithTOCjb8O8UDCzZMeta } from "/app/pages/auth/register/schemas/useEnterPersonalInformationWithTOC.ts?macro=true";
import { default as useInviteTeamMembersznJDsXcZkFMeta } from "/app/pages/auth/register/schemas/useInviteTeamMembers.ts?macro=true";
import { default as useOrganisationInformationkU1TUyrk6tMeta } from "/app/pages/auth/register/schemas/useOrganisationInformation.ts?macro=true";
import { default as useRequestPasswordResetiWdjYjvgBFMeta } from "/app/pages/auth/register/schemas/useRequestPasswordReset.ts?macro=true";
import { default as useResetPassword91N1jz8QJEMeta } from "/app/pages/auth/register/schemas/useResetPassword.ts?macro=true";
import { default as useVerifyIdentityRpxSLJKgPaMeta } from "/app/pages/auth/register/schemas/useVerifyIdentity.ts?macro=true";
import { default as indexqBiyqmBWQYMeta } from "/app/pages/auth/register/user/index.vue?macro=true";
import { default as indexcr5UFr0TWSMeta } from "/app/pages/auth/register/workspace/index.vue?macro=true";
import { default as index8gApeRg22xMeta } from "/app/pages/auth/reset/process/index.vue?macro=true";
import { default as indexRDDDbax4LVMeta } from "/app/pages/auth/reset/request/index.vue?macro=true";
import { default as forwarderwY6AMMcVvfMeta } from "/app/pages/auth/website/forwarder.vue?macro=true";
import { default as test6sGCljJlTKMeta } from "/app/pages/checkouts/test.vue?macro=true";
import { default as indexpEqeHBee6QMeta } from "/app/pages/documents/index.vue?macro=true";
import { default as EmptyListSJd0GUz51vMeta } from "/app/pages/documents/partials/EmptyList.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexjq7Ho9BMUYMeta } from "/app/pages/invoices/index.vue?macro=true";
import { default as EmptyListFsYrSBayGGMeta } from "/app/pages/invoices/partials/EmptyList.vue?macro=true";
import { default as indexNwMsGxInsLMeta } from "/app/pages/prices/index.vue?macro=true";
import { default as ordersuccessYia42uQzQTMeta } from "/app/pages/redirects/ordersuccess.vue?macro=true";
import { default as indexeDTdgZ4uqEMeta } from "/app/pages/returns/index.vue?macro=true";
import { default as EmptyList70tzqUHDm4Meta } from "/app/pages/returns/partials/EmptyList.vue?macro=true";
import { default as EmptyTableE8OExY7IbXMeta } from "/app/pages/returns/partials/EmptyTable.vue?macro=true";
import { default as index2HHjv8eMlIMeta } from "/app/pages/shipments/[id]/index.vue?macro=true";
import { default as labelJ6aSgbuK4bMeta } from "/app/pages/shipments/[id]/label.vue?macro=true";
import { default as index1s6FyJnw0EMeta } from "/app/pages/shipments/import/index.vue?macro=true";
import { default as listeWoekqTTv5Meta } from "/app/pages/shipments/import/list.vue?macro=true";
import { default as BalanceTooLowModalContentctspJaaRG5Meta } from "/app/pages/shipments/import/partials/BalanceTooLowModalContent.vue?macro=true";
import { default as CompleteModalContentu1R2aUyd4JMeta } from "/app/pages/shipments/import/partials/CompleteModalContent.vue?macro=true";
import { default as EditModalContentZGuCWClUujMeta } from "/app/pages/shipments/import/partials/EditModalContent.vue?macro=true";
import { default as TemplateModalContentnG2hpaTCTjMeta } from "/app/pages/shipments/import/partials/TemplateModalContent.vue?macro=true";
import { default as TopbarKqndjwvdP9Meta } from "/app/pages/shipments/import/partials/Topbar.vue?macro=true";
import { default as indexUQkB9U3X0WMeta } from "/app/pages/shipments/index.vue?macro=true";
import { default as EmptyListOLwja9b0H1Meta } from "/app/pages/shipments/partials/EmptyList.vue?macro=true";
import { default as EmptyListNoShopoZJ1R2SsYiMeta } from "/app/pages/shipments/partials/EmptyListNoShop.vue?macro=true";
import { default as EmptyListWithFilters4LjQsw6G9lMeta } from "/app/pages/shipments/partials/EmptyListWithFilters.vue?macro=true";
import { default as ExtraServicesuVqinJPVdAMeta } from "/app/pages/shipments/partials/ExtraServices.vue?macro=true";
import { default as SelectionGzCZ2fWo7OMeta } from "/app/pages/shipments/partials/MultiActions/Selection.vue?macro=true";
import { default as OrderDetailsp5Q0b4TbN7Meta } from "/app/pages/shipments/partials/OrderDetails.vue?macro=true";
import { default as PackageContent131KJjUClFMeta } from "/app/pages/shipments/partials/PackageContent.vue?macro=true";
import { default as WmsDetailsXjgdKeZvgzMeta } from "/app/pages/shipments/partials/WmsDetails.vue?macro=true";
import { default as index8vlRZcXPmuMeta } from "/app/pages/sortings/index.vue?macro=true";
import { default as EmptyListtiLtm6JCBDMeta } from "/app/pages/sortings/partials/EmptyList.vue?macro=true";
import { default as GroupEn4DfCIJhfMeta } from "/app/pages/sortings/partials/Group.vue?macro=true";
import { default as GroupActionstvAtzkLFVFMeta } from "/app/pages/sortings/partials/GroupActions.vue?macro=true";
import { default as NewTransfer60TFpyZ1wqMeta } from "/app/pages/sortings/partials/NewTransfer.vue?macro=true";
import { default as ProhibitItemOurEuzSGVjMeta } from "/app/pages/sortings/partials/ProhibitItem.vue?macro=true";
import { default as TopBoxItemfqLVSfbS4tMeta } from "/app/pages/sortings/partials/TopBoxItem.vue?macro=true";
import { default as indexWUwfrPymtWMeta } from "/app/pages/superuser/index.vue?macro=true";
import { default as indexjd0p1QE5TBMeta } from "/app/pages/tickets/[id]/index.vue?macro=true";
import { default as indexSYGgMZWiZOMeta } from "/app/pages/tickets/index.vue?macro=true";
import { default as EmptyListuogcf5UP9vMeta } from "/app/pages/tickets/partials/EmptyList.vue?macro=true";
import { default as TicketAttributeDhGTrghviGMeta } from "/app/pages/tickets/partials/TicketAttribute.vue?macro=true";
import { default as TicketDetailsBb4AqsaSpsMeta } from "/app/pages/tickets/partials/TicketDetails.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: indexlXWKW23Hj9Meta || {},
    component: () => import("/app/pages/auth/login/index.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutCHyXH6UWwkMeta || {},
    component: () => import("/app/pages/auth/logout.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: indexFfkaDmrbi7Meta || {},
    component: () => import("/app/pages/auth/register/index.vue")
  },
  {
    name: "auth-register-partials-Mockups-Base",
    path: "/auth/register/partials/Mockups/Base",
    component: () => import("/app/pages/auth/register/partials/Mockups/Base.vue")
  },
  {
    name: "auth-register-partials-Mockups-CreateOrganisation",
    path: "/auth/register/partials/Mockups/CreateOrganisation",
    component: () => import("/app/pages/auth/register/partials/Mockups/CreateOrganisation.vue")
  },
  {
    name: "auth-register-partials-Mockups-EnterPersonalInformation",
    path: "/auth/register/partials/Mockups/EnterPersonalInformation",
    component: () => import("/app/pages/auth/register/partials/Mockups/EnterPersonalInformation.vue")
  },
  {
    name: "auth-register-partials-Mockups-InviteTeamMembers",
    path: "/auth/register/partials/Mockups/InviteTeamMembers",
    component: () => import("/app/pages/auth/register/partials/Mockups/InviteTeamMembers.vue")
  },
  {
    name: "auth-register-partials-Mockups-OrganisationInformation",
    path: "/auth/register/partials/Mockups/OrganisationInformation",
    component: () => import("/app/pages/auth/register/partials/Mockups/OrganisationInformation.vue")
  },
  {
    name: "auth-register-partials-NotificationStep",
    path: "/auth/register/partials/NotificationStep",
    component: () => import("/app/pages/auth/register/partials/NotificationStep.vue")
  },
  {
    name: "auth-register-schemas-useCreateOrganisation",
    path: "/auth/register/schemas/useCreateOrganisation",
    component: () => import("/app/pages/auth/register/schemas/useCreateOrganisation.ts")
  },
  {
    name: "auth-register-schemas-useCreatePassword",
    path: "/auth/register/schemas/useCreatePassword",
    component: () => import("/app/pages/auth/register/schemas/useCreatePassword.ts")
  },
  {
    name: "auth-register-schemas-useEnableNotifications",
    path: "/auth/register/schemas/useEnableNotifications",
    component: () => import("/app/pages/auth/register/schemas/useEnableNotifications.ts")
  },
  {
    name: "auth-register-schemas-useEnterEmail",
    path: "/auth/register/schemas/useEnterEmail",
    component: () => import("/app/pages/auth/register/schemas/useEnterEmail.ts")
  },
  {
    name: "auth-register-schemas-useEnterPersonalInformation",
    path: "/auth/register/schemas/useEnterPersonalInformation",
    component: () => import("/app/pages/auth/register/schemas/useEnterPersonalInformation.ts")
  },
  {
    name: "auth-register-schemas-useEnterPersonalInformationWithTOC",
    path: "/auth/register/schemas/useEnterPersonalInformationWithTOC",
    component: () => import("/app/pages/auth/register/schemas/useEnterPersonalInformationWithTOC.ts")
  },
  {
    name: "auth-register-schemas-useInviteTeamMembers",
    path: "/auth/register/schemas/useInviteTeamMembers",
    component: () => import("/app/pages/auth/register/schemas/useInviteTeamMembers.ts")
  },
  {
    name: "auth-register-schemas-useOrganisationInformation",
    path: "/auth/register/schemas/useOrganisationInformation",
    component: () => import("/app/pages/auth/register/schemas/useOrganisationInformation.ts")
  },
  {
    name: "auth-register-schemas-useRequestPasswordReset",
    path: "/auth/register/schemas/useRequestPasswordReset",
    component: () => import("/app/pages/auth/register/schemas/useRequestPasswordReset.ts")
  },
  {
    name: "auth-register-schemas-useResetPassword",
    path: "/auth/register/schemas/useResetPassword",
    component: () => import("/app/pages/auth/register/schemas/useResetPassword.ts")
  },
  {
    name: "auth-register-schemas-useVerifyIdentity",
    path: "/auth/register/schemas/useVerifyIdentity",
    component: () => import("/app/pages/auth/register/schemas/useVerifyIdentity.ts")
  },
  {
    name: "auth-register-user",
    path: "/auth/register/user",
    meta: indexqBiyqmBWQYMeta || {},
    component: () => import("/app/pages/auth/register/user/index.vue")
  },
  {
    name: "auth-register-workspace",
    path: "/auth/register/workspace",
    meta: indexcr5UFr0TWSMeta || {},
    component: () => import("/app/pages/auth/register/workspace/index.vue")
  },
  {
    name: "auth-reset-process",
    path: "/auth/reset/process",
    meta: index8gApeRg22xMeta || {},
    component: () => import("/app/pages/auth/reset/process/index.vue")
  },
  {
    name: "auth-reset-request",
    path: "/auth/reset/request",
    meta: indexRDDDbax4LVMeta || {},
    component: () => import("/app/pages/auth/reset/request/index.vue")
  },
  {
    name: "auth-website-forwarder",
    path: "/auth/website/forwarder",
    meta: forwarderwY6AMMcVvfMeta || {},
    component: () => import("/app/pages/auth/website/forwarder.vue")
  },
  {
    name: "checkouts-test",
    path: "/checkouts/test",
    meta: test6sGCljJlTKMeta || {},
    component: () => import("/app/pages/checkouts/test.vue")
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexpEqeHBee6QMeta || {},
    component: () => import("/app/pages/documents/index.vue")
  },
  {
    name: "documents-partials-EmptyList",
    path: "/documents/partials/EmptyList",
    component: () => import("/app/pages/documents/partials/EmptyList.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexjq7Ho9BMUYMeta || {},
    component: () => import("/app/pages/invoices/index.vue")
  },
  {
    name: "invoices-partials-EmptyList",
    path: "/invoices/partials/EmptyList",
    component: () => import("/app/pages/invoices/partials/EmptyList.vue")
  },
  {
    name: "prices",
    path: "/prices",
    meta: indexNwMsGxInsLMeta || {},
    component: () => import("/app/pages/prices/index.vue")
  },
  {
    name: "redirects-ordersuccess",
    path: "/redirects/ordersuccess",
    component: () => import("/app/pages/redirects/ordersuccess.vue")
  },
  {
    name: "returns",
    path: "/returns",
    meta: indexeDTdgZ4uqEMeta || {},
    component: () => import("/app/pages/returns/index.vue")
  },
  {
    name: "returns-partials-EmptyList",
    path: "/returns/partials/EmptyList",
    component: () => import("/app/pages/returns/partials/EmptyList.vue")
  },
  {
    name: "returns-partials-EmptyTable",
    path: "/returns/partials/EmptyTable",
    component: () => import("/app/pages/returns/partials/EmptyTable.vue")
  },
  {
    name: "shipments-id",
    path: "/shipments/:id()",
    meta: index2HHjv8eMlIMeta || {},
    component: () => import("/app/pages/shipments/[id]/index.vue")
  },
  {
    name: "shipments-id-label",
    path: "/shipments/:id()/label",
    meta: labelJ6aSgbuK4bMeta || {},
    component: () => import("/app/pages/shipments/[id]/label.vue")
  },
  {
    name: "shipments-import",
    path: "/shipments/import",
    meta: index1s6FyJnw0EMeta || {},
    component: () => import("/app/pages/shipments/import/index.vue")
  },
  {
    name: "shipments-import-list",
    path: "/shipments/import/list",
    meta: listeWoekqTTv5Meta || {},
    component: () => import("/app/pages/shipments/import/list.vue")
  },
  {
    name: "shipments-import-partials-BalanceTooLowModalContent",
    path: "/shipments/import/partials/BalanceTooLowModalContent",
    component: () => import("/app/pages/shipments/import/partials/BalanceTooLowModalContent.vue")
  },
  {
    name: "shipments-import-partials-CompleteModalContent",
    path: "/shipments/import/partials/CompleteModalContent",
    component: () => import("/app/pages/shipments/import/partials/CompleteModalContent.vue")
  },
  {
    name: "shipments-import-partials-EditModalContent",
    path: "/shipments/import/partials/EditModalContent",
    component: () => import("/app/pages/shipments/import/partials/EditModalContent.vue")
  },
  {
    name: "shipments-import-partials-TemplateModalContent",
    path: "/shipments/import/partials/TemplateModalContent",
    component: () => import("/app/pages/shipments/import/partials/TemplateModalContent.vue")
  },
  {
    name: "shipments-import-partials-Topbar",
    path: "/shipments/import/partials/Topbar",
    component: () => import("/app/pages/shipments/import/partials/Topbar.vue")
  },
  {
    name: "shipments",
    path: "/shipments",
    meta: indexUQkB9U3X0WMeta || {},
    component: () => import("/app/pages/shipments/index.vue")
  },
  {
    name: "shipments-partials-EmptyList",
    path: "/shipments/partials/EmptyList",
    component: () => import("/app/pages/shipments/partials/EmptyList.vue")
  },
  {
    name: "shipments-partials-EmptyListNoShop",
    path: "/shipments/partials/EmptyListNoShop",
    component: () => import("/app/pages/shipments/partials/EmptyListNoShop.vue")
  },
  {
    name: "shipments-partials-EmptyListWithFilters",
    path: "/shipments/partials/EmptyListWithFilters",
    component: () => import("/app/pages/shipments/partials/EmptyListWithFilters.vue")
  },
  {
    name: "shipments-partials-ExtraServices",
    path: "/shipments/partials/ExtraServices",
    component: () => import("/app/pages/shipments/partials/ExtraServices.vue")
  },
  {
    name: "shipments-partials-MultiActions-Selection",
    path: "/shipments/partials/MultiActions/Selection",
    component: () => import("/app/pages/shipments/partials/MultiActions/Selection.vue")
  },
  {
    name: "shipments-partials-OrderDetails",
    path: "/shipments/partials/OrderDetails",
    component: () => import("/app/pages/shipments/partials/OrderDetails.vue")
  },
  {
    name: "shipments-partials-PackageContent",
    path: "/shipments/partials/PackageContent",
    component: () => import("/app/pages/shipments/partials/PackageContent.vue")
  },
  {
    name: "shipments-partials-WmsDetails",
    path: "/shipments/partials/WmsDetails",
    component: () => import("/app/pages/shipments/partials/WmsDetails.vue")
  },
  {
    name: "sortings",
    path: "/sortings",
    meta: index8vlRZcXPmuMeta || {},
    component: () => import("/app/pages/sortings/index.vue")
  },
  {
    name: "sortings-partials-EmptyList",
    path: "/sortings/partials/EmptyList",
    component: () => import("/app/pages/sortings/partials/EmptyList.vue")
  },
  {
    name: "sortings-partials-Group",
    path: "/sortings/partials/Group",
    component: () => import("/app/pages/sortings/partials/Group.vue")
  },
  {
    name: "sortings-partials-GroupActions",
    path: "/sortings/partials/GroupActions",
    component: () => import("/app/pages/sortings/partials/GroupActions.vue")
  },
  {
    name: "sortings-partials-NewTransfer",
    path: "/sortings/partials/NewTransfer",
    component: () => import("/app/pages/sortings/partials/NewTransfer.vue")
  },
  {
    name: "sortings-partials-ProhibitItem",
    path: "/sortings/partials/ProhibitItem",
    component: () => import("/app/pages/sortings/partials/ProhibitItem.vue")
  },
  {
    name: "sortings-partials-TopBoxItem",
    path: "/sortings/partials/TopBoxItem",
    component: () => import("/app/pages/sortings/partials/TopBoxItem.vue")
  },
  {
    name: "superuser",
    path: "/superuser",
    component: () => import("/app/pages/superuser/index.vue")
  },
  {
    name: "tickets-id",
    path: "/tickets/:id()",
    meta: indexjd0p1QE5TBMeta || {},
    component: () => import("/app/pages/tickets/[id]/index.vue")
  },
  {
    name: "tickets",
    path: "/tickets",
    meta: indexSYGgMZWiZOMeta || {},
    component: () => import("/app/pages/tickets/index.vue")
  },
  {
    name: "tickets-partials-EmptyList",
    path: "/tickets/partials/EmptyList",
    component: () => import("/app/pages/tickets/partials/EmptyList.vue")
  },
  {
    name: "tickets-partials-TicketAttribute",
    path: "/tickets/partials/TicketAttribute",
    component: () => import("/app/pages/tickets/partials/TicketAttribute.vue")
  },
  {
    name: "tickets-partials-TicketDetails",
    path: "/tickets/partials/TicketDetails",
    component: () => import("/app/pages/tickets/partials/TicketDetails.vue")
  }
]