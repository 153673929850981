<template>
  <Modal
    class="shadow-lvl-05"
    v-model:open="openParams"
    :clickToClose="false"
    maxWidth="1020px"
    :contentPadding="false"
    :center="true"
    anchor="top"
    ref="modal"
    :bgset="false"
  >
    <div class="w-[1020px] max-w-full !h-[756px] max-h-full py-2.5 bg-blend-overlay">
      <div class="h-full w-full bg-surface-lvl-00 max-w-[400px] mx-auto rounded-xl border-2 border-00">
        <div class="px-4 flex items-center py-2">
          <p class="text-secondary text-body-lg-heavy">{{ t("user_defined_params") }}</p>
          <Button class="ml-auto" variant="tertiary" :size="ComponentSize.sm" @click="emit('close')"
            ><Icon src="close"
          /></Button>
        </div>
        <div class="p-4">
          <p class="text-secondary text-body-default">
            {{ t("user_defined_params_desc") }}
          </p>
        </div>
        <Overlay class="h-full min-h-16 max-w-[400px] mx-auto" v-if="loading"></Overlay>
        <div class="p-2.5 w-full">
          <Button class="w-full mb-4" variant="default" @click="addNew()">{{ t("create_new") }}</Button>
          <div class="overflow-auto max-h-[530px]">
            <AccordionListPartialsItem
              class="mb-1"
              v-for="(filter, index) in filters"
              :title="filter.title"
              :open="selectedFilter == index"
              :index="index"
              @openItem="(val) => selectFilter(val)"
              :subtitle="filter.type ? getFilterType(filter.type) : ''"
            >
              <div class="flex flex-col gap-3">
                <div class="w-full">
                  <p class="text-quarterary text-body-sm">{{ t("used_on_these_checkouts") }}</p>
                  <div class="flex gap-1 flex-wrap" v-if="filter.checkouts.length !== 0">
                    <BadgeChip v-for="checkout in filter.checkouts">
                      {{ checkout }}
                    </BadgeChip>
                  </div>
                  <div v-else>
                    <p class="text-quarterary text-body-sm">{{ t("not_used") }}</p>
                  </div>
                </div>
                <Divider />
                <div class="flex flex-col gap-2">
                  <div class="flex items-center gap-1">
                    <p class="text-secondary text-body-sm-heavy w-[50px]">{{ t("title") }}</p>
                    <InputText class="w-full" v-model="filter.title" darkBg @focus="$event.target.select()" />
                  </div>
                  <div class="flex items-center gap-1">
                    <p class="text-secondary text-body-sm-heavy w-[50px]">{{ t("key") }}</p>
                    <InputText class="w-full" v-model="filter.key" darkBg />
                  </div>
                  <div class="flex items-center gap-1">
                    <p class="text-secondary text-body-sm-heavy w-[50px]">{{ t("type") }}</p>
                    <DropdownSelect class="w-full" v-model="filter.type" :options="types" />
                  </div>
                </div>
                <Divider />
                <div class="flex gap-2">
                  <Button v-if="filter.id" variant="destructive-link" @click="deleteFilter(filter.id)">{{
                    t("delete")
                  }}</Button>
                  <Button
                    class="ml-auto"
                    v-if="
                      (filter.id && filter.title !== _filterCopies[index].title) ||
                      (filter.id && filter.key !== _filterCopies[index].key) ||
                      (filter.id && filter.type !== _filterCopies[index].type)
                    "
                    variant="tertiary"
                    @click="cancelEdit(filter.id)"
                    >{{ t("cancel") }}</Button
                  >
                  <Button
                    class=""
                    v-if="filter.id"
                    variant="primary"
                    :class="
                      filter.title == _filterCopies[index].title &&
                      filter.key == _filterCopies[index].key &&
                      filter.type == _filterCopies[index].type
                        ? 'ml-auto'
                        : ''
                    "
                    @click="updateFilter(filter)"
                    >{{ t("save_changes") }}</Button
                  >
                  <Button class="ml-auto" v-if="!filter.id" variant="tertiary" @click="cancelEdit(0)">{{
                    t("cancel")
                  }}</Button>
                  <Button
                    class=""
                    v-if="!filter.id"
                    :disabled="!filter.title || !filter.key || !filter.type"
                    variant="primary"
                    @click="createFilter(filter)"
                    >{{ t("create") }}</Button
                  >
                </div>
              </div>
            </AccordionListPartialsItem>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  export interface Filter {
    created_at: Date;
    customer_id: number;
    id: number;
    key: string;
    title: string;
    type: string;
    updated_at: Date;
  }
  const openParams = defineModel("openParams", { default: false });
  const emit = defineEmits(["close"]);
  const { t } = useI18n();
  const props = defineProps<{
    checkoutId?: number;
  }>();
  const loading = ref(false);
  const filters = ref<Filter[]>([]);
  const _filterCopies = ref<Filter[]>([]);
  const types = ref([]);
  const selectedFilter = ref(null);

  onMounted(() => {
    fetchParams();
  });

  const selectFilter = (index: number) => {
    if (selectedFilter.value === index) {
      selectedFilter.value = null;
    } else {
      selectedFilter.value = index;
    }
  };

  const addNew = () => {
    let title = t("user_defined_param");
    if (filters.value.some((f) => f.title.includes(t("user_defined_param")))) {
      let userFilters = filters.value.filter((f) => f.title.includes(t("user_defined_param")));
      if (userFilters[0].title.includes("(")) {
        let index = userFilters[0].title.split("(")[1].split(")")[0];
        title = `${t("user_defined_param")} (${parseInt(index) + 1})`;
      } else {
        title = `${t("user_defined_param")} (1)`;
      }
    }
    let newFilter = {
      title: title,
      key: "",
      type: "text",
      checkouts: [],
    };
    filters.value.unshift(newFilter);
    _filterCopies.value.unshift(newFilter);
    selectedFilter.value = 0;
  };

  const cancelEdit = (id: number) => {
    if (id === 0) {
      filters.value.shift();
      _filterCopies.value.shift();
      selectedFilter.value = null;
      return;
    }
    let copy = _filterCopies.value.find((f) => f.id === id);
    let filter = filters.value.find((f) => f.id === id);
    if (copy && filter) {
      filter.title = copy.title;
      filter.key = copy.key;
      filter.type = copy.type;
    }
  };

  const deleteFilter = (id: number) => {
    $confirm({
      message: t("confirm_delete_param"),
      destructive: true,
      onConfirm: () => {
        homeFetch(`workspace/checkouts/filters/${id}`, {
          method: "DELETE",
        })
          .then((response) => {
            $toast.add({
              title: t("param_deleted"),
            });
            selectedFilter.value = null;
            fetchParams();
          })
          .catch((error) => {
            loading.value = false;
          });
      },
    });
  };

  const createFilter = (filter: object) => {
    loading.value = true;
    homeFetch(`workspace/checkouts/filters`, {
      method: "POST",
      body: {
        ...filter,
      },
    })
      .then((response) => {
        $toast.add({
          title: t("param_created"),
        });
        fetchParams();
      })
      .catch((error) => {
        loading.value = false;
      });
  };

  const updateFilter = (filter: object) => {
    loading.value = true;
    homeFetch(`workspace/checkouts/filters/${filter.id}`, {
      method: "PUT",
      body: {
        ...filter,
      },
    })
      .then((response) => {
        $toast.add({
          title: t("param_updated"),
        });
        fetchParams();
      })
      .catch((error) => {
        loading.value = false;
      });
  };

  const fetchParams = () => {
    loading.value = true;
    homeFetch("workspace/checkouts/filters")
      .then((response) => {
        if (response?.data) {
          filters.value = response.data.filters as Filter[];
          _filterCopies.value = structuredClone(response.data.filters as Filter[]);
          types.value = response.data.types;
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const getFilterType = (type: string) => {
    let filterType = types.value.find((t) => t.value === type);
    if (filterType) {
      return filterType.label;
    }
    return t("this_type_has_been_outfaced");
  };
</script>
