<template>
  <Modal
    v-model:open="open"
    :maxWidth="maxWidth"
    :minWidth="minWidth"
    :maxHeight="maxHeight"
    ref="modal"
    :contentPadding="false"
  >
    <template v-if="dialogType !== 'modal'">
      <ModalHeader class="border-b-0" v-if="options?.title">
        <div class="overflow-hidden text-ellipsis">
          {{ options.title }}
        </div>
      </ModalHeader>
      <Content v-if="Content" ref="content" />
      <div class="flex flex-col gap-4 p-4" v-else>
        <div class="text-body-default gap-2 flex flex-col break-words" v-if="options?.message">
          <p v-for="p in messageAsParagraphs">
            {{ p }}
          </p>
        </div>
        <div class="flex flex-col gap-2" v-if="dialogType === 'prompt'">
          <InputText
            v-model="promptValue"
            :placeholder="(options as IPromptDialogOptions)?.placeholder"
            :error="promptError"
          />
        </div>
      </div>
      <ModalFooter class="border-t-0">
        <div class="flex flex-col gap-2 w-full">
          <template v-if="dialogType === 'alert'">
            <Button @click="alertClose">{{ t("ok") }}</Button>
          </template>
          <template v-else>
            <Button
              class="w-full"
              :disabled="!canConfirm"
              @click="confirmClose"
              :variant="(options as IPromptDialogOptions)?.destructive ? 'destructive' : 'primary'"
              >{{ confirmText }}</Button
            >
            <Button @click="cancelClose">{{ cancelText }}</Button>
          </template>
        </div>
      </ModalFooter>
    </template>
    <Content v-else-if="Content" ref="content" />
  </Modal>
</template>

<script setup lang="ts">
  import { ref, onMounted, onUnmounted } from "vue";
  import EventBus from "@/plugins/eventbus";
  import type {
    DialogType,
    IConfirmDialogOptions,
    IDialogOptions,
    IModalOptions,
    IPromptDialogOptions,
  } from "../types";
  const dialogType = ref<DialogType | "">("");
  const content = ref(null);

  const options = ref<IDialogOptions | IPromptDialogOptions | IConfirmDialogOptions | IModalOptions | null>();

  const { t } = useI18n();

  const modal = ref<HTMLDivElement | null | undefined>();
  const promptValue = ref("");
  const promptError = ref("");

  const Content = computed(() => (options.value as IDialogOptions)?.content);
  const width = computed(() => (options.value as IModalOptions)?.width);

  const minWidth = computed(() => (options.value as IModalOptions)?.minWidth || width.value || "342px");
  const maxWidth = computed(() => (options.value as IModalOptions)?.maxWidth || width.value || "342px");
  const maxHeight = computed(() => (options.value as IModalOptions)?.maxHeight);
  const open = computed({
    get: () => dialogType.value !== "",
    set: (value: boolean) => {
      if (!value) {
        dialogType.value = "";
        options.value = null;
      }
    },
  });

  const alertClose = () => {
    (options.value as IDialogOptions)?.onClose?.();
    open.value = false;
  };

  const canConfirm = computed(() => {
    if (content.value?.canConfirm !== undefined) {
      return content.value.canConfirm;
    }

    return true;
  });

  watch(
    () => promptValue.value,
    () => {
      promptError.value = "";
    }
  );

  const confirmClose = () => {
    if (dialogType.value === "prompt") {
      const promptOptions = options.value as IPromptDialogOptions;
      const prompt = promptOptions?.prompt;

      if (prompt && promptValue.value !== prompt) {
        promptError.value = t("default_prompt_error");
        return;
      }

      promptOptions?.onConfirm?.(promptValue.value || "");
    } else {
      (options.value as IConfirmDialogOptions)?.onConfirm?.();
    }
    open.value = false;
  };

  const cancelClose = () => {
    (options.value as IConfirmDialogOptions)?.onCancel?.();
    open.value = false;
  };

  const confirmText = computed(() => {
    return (options.value as IConfirmDialogOptions)?.confirmText || t("ok");
  });

  const cancelText = computed(() => {
    return (options.value as IConfirmDialogOptions)?.cancelText || t("undo");
  });

  const messageAsParagraphs = computed(() => {
    if (!options.value?.message) return [];
    return options.value.message.split("\n");
  });

  onMounted(() => {
    EventBus.$on(
      "DIALOG_OPEN",
      (type: DialogType, opts: IDialogOptions | IPromptDialogOptions | IConfirmDialogOptions) => {
        dialogType.value = type;
        options.value = opts;
      }
    );

    EventBus.$on("DIALOG_CLOSE", () => {
      open.value = false;
    });
  });

  onUnmounted(() => {
    EventBus.$off("DIALOG_OPEN");
    EventBus.$off("DIALOG_CLOSE");
  });
</script>
