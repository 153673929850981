<template>
  <Modal class="shadow-lvl-05" v-model:open="open" maxWidth="1020px" maxHeight="756px" ref="modal">
    <div class="flex flex-col">
      <div class="pl-4 flex gap-4 items-center justify-between">
        <div class="text-body-default-heavy">
          {{ t("boarding.get_started") }}
        </div>
        <Button variant="tertiary" type="button" @click="open = false">
          <Icon src="close" />
        </Button>
      </div>

      <div class="flex flex-col gap-4 p-4 relative overflow-auto">
        <div class="flex flex-col gap-5">
          <div class="flex flex-col gap-2">
            <div class="text-title-screen">{{ t("boarding.welcome_to_homerunner", { name: user?.name }) }}</div>
            <div class="text-body-lg text-tertiary">{{ t("boarding.complete_setup_description") }}.</div>
          </div>
        </div>
        <div
          class="p-5 flex gap-10 rounded-xl border border-00 overflow-auto pb-4 [&.has-scrollbar]:shadow-inner group"
          ref="flowContainer"
        >
          <div class="w-full">
            <div
              class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 group-[.has-scrollbar]:pb-4"
              v-if="!currentFlow"
              ref="flowsGrid"
            >
              <SetupGrid
                v-for="flow in flows"
                :key="flow.title"
                :flow="flow"
                @click="() => setCurrentFlowKey(flow.key)"
              />
            </div>
            <div class="flex flex-col md:grid grid-cols-12 gap-4 md:gap-10" v-else>
              <SetupSidebar class="col-span-3" :flow="currentFlow" />
              <Formbuilder
                class="col-span-9"
                :validationSchema="currentFlow?.schema"
                :submitButtonText="t('continue')"
                @exit="open = false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import { ref, onMounted, onUnmounted, watch } from "vue";
  import { open, currentFlowKey, flows } from "../service";
  import EventBus from "@/plugins/eventbus";
  import SetupGrid from "./SetupGrid.vue";
  import SetupSidebar from "./SetupSidebar.vue";
  import useHasScrollbar from "~/composables/useHasScrollbar";
  import useSetupShop from "../flows/useSetupShop";
  import useSetupExternalWarehouse from "../flows/useSetupExternalWarehouse";
  import useSetupInvoicingResponsible from "../flows/useSetupInvoicingResponsible";

  import type { IBoardingFlow } from "../types";
  import Formbuilder from "./Formbuilder.vue";

  const { t } = useI18n();
  const user = useAuth().getUser();
  const modal = ref<HTMLDivElement | null | undefined>();

  const emit = defineEmits(["loading", "loaded"]);
  const flowContainer = ref<HTMLElement | null | undefined>();
  const flowsGrid = ref<HTMLElement | null | undefined>();

  onMounted(() => {
    flows.value = [useSetupShop(), useSetupExternalWarehouse(), useSetupInvoicingResponsible()];
  });

  watch(
    () => flowContainer.value,
    () => {
      if (flowContainer.value) {
        useHasScrollbar(flowContainer.value);
      }
    }
  );

  const addFlow = (boarding: IBoardingFlow) => {
    flows.value.push(boarding);
  };

  const currentFlow = computed(() => {
    if (!currentFlowKey.value) return null;
    return flows.value.find((b) => b.key === currentFlowKey.value);
  });

  const startBoarding = (key: string) => {
    const flow = flows.value.find((flow) => flow.key === key);
    if (flow) {
      setCurrentFlowKey(flow.key);
      open.value = true;
    } else {
      if (window.$toast)
        window.$toast.add({
          title: t("boarding.not_found"),
          group: "boarding.not_found",
        });
    }
  };

  const removeFlow = (key: string) => {
    const index = flows.value.findIndex((setup) => setup.key === key);
    if (index !== -1) {
      flows.value.splice(index, 1);
    }
  };

  const setCurrentFlowKey = (key: string) => {
    currentFlowKey.value = key;
  };

  onMounted(() => {
    EventBus.$on("BOARDING_TOGGLE", () => {
      open.value = !open.value;
    });
    EventBus.$on("BOARDING_OPEN", () => {
      open.value = true;
    });
    EventBus.$on("BOARDING_CLOSE", () => {
      open.value = false;
    });
    EventBus.$on("BOARDING_ADD", addFlow);
    EventBus.$on("BOARDING_REMOVE", removeFlow);
    EventBus.$on("BOARDING_START", startBoarding);
  });

  onUnmounted(() => {
    EventBus.$off("BOARDING_TOGGLE");
    EventBus.$off("BOARDING_OPEN");
    EventBus.$off("BOARDING_CLOSE");
    EventBus.$off("BOARDING_ADD");
    EventBus.$off("BOARDING_REMOVE");
    EventBus.$off("BOARDING_START");
  });
</script>
