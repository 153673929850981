<template>
  <SlideIn
    :submitReady="true"
    :showFooter="step == 2"
    :buttonText="t('create_ticket')"
    @close="close()"
    @submit="createTicket()"
    :title="t('create_ticket')"
    :open="open"
    ref="modal"
  >
    <div class="overflow-auto">
      <div class="flex flex-col" v-if="ticket_id">
        <div class="max-w-max mx-auto my-auto flex flex-col items-center justify-center">
          <p class="-mt-24 text-body-lg-heavy mb-5">{{ t("ticket_already_created") }}</p>
          <Button class="max-w-max max-auto text-center" variant="primary" @click="goToTicket(ticket_id)">{{
            t("go_to_entity", { entity: t("ticket", 1).toLowerCase() })
          }}</Button>
        </div>
      </div>
      <div class="w-full pt-2 px-5 pb-5" id="types" v-if="types && types.length !== 0 && step == 1">
        <div
          class="p-4 border border-00 rounded-lg shadow-surface-lvl-01 shadow-lvl-01 mb-3 flex gap-4 cursor-pointer"
          v-for="ticketType in types"
          :key="ticketType.id"
          @click="selectType(ticketType)"
        >
          <div>
            <p class="text-secondary text-body-default-heavy mb-0.5">{{ ticketType.name }}</p>
            <p class="text-quarterary text-body-default">{{ ticketType.description }}</p>
          </div>
          <div class="flex items-center justify-center ml-auto">
            <Icon class="" src="arrow_right"></Icon>
          </div>
        </div>
        <div class="h-14"></div>
      </div>
      <div class="w-full pt-2 px-5 pb-5" v-if="step == 2">
        <div class="bg-surface-lvl-01 py-2 px-3 flex rounded-lg mb-10">
          <p class="text-secondary text-body-default-heavy">{{ selectedObject.name }}</p>
          <p class="ml-auto text-body-default-heavy text-brand cursor-pointer" @click="step = 1">{{ t("change") }}</p>
        </div>
        <div class="flex flex-col gap-10">
          <div class="" v-for="(attribute, index) in selectedObject.attributes" :key="attribute.id">
            <div class="relative flex gap-2">
              <p class="text-attribute text-body-sm-heavy">{{ attribute.name }}</p>
              <div class="!max-w-[200px]">
                <Tooltip class="overflow-hidden" :text="attribute.description"></Tooltip>
              </div>
              <Button
                v-if="attribute?.options?.link"
                variant="primary-link"
                :size="ComponentSize.sm"
                :href="attribute?.options?.link"
                target="_blank"
                >{{ t("download_template") }}</Button
              >
            </div>
            <TypeInput
              @emitValue="(val) => addAttributeValue(val, attribute)"
              :index="index"
              :shipmentId="shipmentId"
              :attribute="attribute"
              :type="attribute.type"
            ></TypeInput>
          </div>
        </div>
        <InputParagraph
          class="mt-10"
          :error="messageError"
          @update:model-value="(val) => setMessage(val)"
          :label="t('ticket_description')"
        ></InputParagraph>
      </div>
      <Overlay class="inset-x-0 min-h-[300px] h-lvh w-full absolute top-0 left-0" v-if="loading"></Overlay>
    </div>
  </SlideIn>
</template>

<script setup lang="ts">
  import TypeInput from "./typeInput.vue";
  import { open } from "../service";
  import EventBus from "@/plugins/eventbus";
  import { ComponentSize } from "~/types/global";

  defineOptions({
    name: "CreateTicket",
  });

  const { t } = useI18n();
  const loading = ref(false);
  const types = ref([]);
  const packageNumber = ref(null);
  const shipmentId = ref(null);
  const selectedType = ref(null);
  const step = ref(1);
  const ticket_id = ref(null);
  const messageError = ref(null);

  const ticket = ref({
    shipment_id: null,
    type: null,
    from_name: null,
    from_email: null,
    cc: null,
    message: null,
    attributes: [],
  });

  const selectedObject = computed(() => {
    if (!selectedType.value) {
      return null;
    }
    return types.value.find((type) => type.key === selectedType.value);
  });

  const { submitTicket } = useTickets();

  const selectType = (type: object) => {
    selectedType.value = type.key;
    ticket.value.type = type.state;

    step.value = 2;
  };

  const attributesFilled = computed(() => {
    if (!selectedObject.value) {
      return false;
    }
    return selectedObject.value.attributes.every((attribute) => {
      return ticket.value.attributes.some((attr) => attr.type_attribute_id === attribute.id) && ticket.value.message;
    });
  });

  const setMessage = (val: any) => {
    messageError.value = null;
    ticket.value.message = val;
  };

  const { getUser } = useAuth();

  onMounted(() => {
    EventBus.$on("NEWTICKET_TOGGLE", () => {
      open.value = !open.value;
    });
    EventBus.$on("NEWTICKET_ADD", (o: object, p: string, i: string) => {
      loading.value = true;
      types.value = [];
      step.value = 1;
      selectedType.value = null;
      ticket.value = {
        shipment_id: i,
        package_number: p,
        type: null,
        from_name: getUser().name,
        from_email: null,
        cc: null,
        message: null,
        attributes: [],
      };
      if (o.ticket_id) {
        ticket_id.value = o.ticket_id;
        types.value = [];
      } else {
        types.value = o.data?.data ?? o.data;
      }
      packageNumber.value = p;
      shipmentId.value = i;
      loading.value = false;
    });
    EventBus.$on("NEWTICKET_OPEN", () => {
      open.value = true;
    });
    EventBus.$on("NEWTICKET_CLOSE", () => {
      open.value = false;
      ticket_id.value = null;
      types.value = [];
      step.value = 1;
    });
  });

  onUnmounted(() => {
    EventBus.$off("NEWTICKET_TOGGLE");
    EventBus.$off("NEWTICKET_OPEN");
    EventBus.$off("NEWTICKET_CLOSE");
  });

  const addAttributeValue = (val: any, attribute: object) => {
    attribute.error = null;
    const attributeValues = {
      key: attribute.key,
      type: attribute.type,
      type_attribute_id: attribute.id,
      value: val,
    };
    const existingAttributeIndex = ticket.value.attributes.findIndex((attr) => attr.key === attribute.key);
    if (existingAttributeIndex !== -1) {
      ticket.value.attributes.splice(existingAttributeIndex, 1);
    }
    if (val.length === 0) {
      attributeValues.value = null;
      ticket.value.attributes.splice(existingAttributeIndex, 1);
      return;
    } else {
      if (attribute.type === "file" || attribute.type === "dor" || attribute.type === "file_with_pdf") {
        attributeValues.fileName = val[0].name;
        attributeValues.value = val[0].value;
      } else if (attribute.type === "file[]") {
        attributeValues.fileNames = val.map((v: any) => v.name);
        attributeValues.value = val.map((v: any) => v.value);
      }
    }
    ticket.value.attributes.push(attributeValues);
  };

  const createTicket = async () => {
    let allFilled = await selectedObject.value.attributes.every((attribute) => {
      if (attribute.optional) {
        return true;
      }
      return (
        ticket.value.attributes.some((attr) => attr.type_attribute_id === attribute.id && attr.value) &&
        ticket.value.message
      );
    });
    if (!allFilled) {
      //create array of missing attributes
      const missingAttributes = selectedObject.value.attributes.filter((attribute) => {
        return !ticket.value.attributes.some((attr) => attr.type_attribute_id === attribute.id && attr.value);
      });
      missingAttributes.forEach((element) => {
        let originalAttribute = selectedObject.value.attributes.find((attr) => attr.id === element.id);
        originalAttribute.error = t("required");
      });

      if (!ticket.value.message) {
        messageError.value = t("required");
      }
      return;
    }
    loading.value = true;
    let response = await submitTicket(ticket.value);
    if (response.ticketId) {
      EventBus.$emit("NEWTICKET_CREATED");
      EventBus.$emit("NEWTICKET_CLOSE");
      loading.value = false;
    }
    loading.value = false;
  };

  const close = () => {
    EventBus.$emit("NEWTICKET_CLOSE");
  };

  const goToTicket = (ticketId: number) => {
    EventBus.$emit("NEWTICKET_CLOSE");
    const link = useRouter().resolve({ name: "tickets-id", params: { id: ticketId } })?.href;
    useRouter().push(link);
  };
</script>

<style>
  .input_title {
    @apply mb-1 mt-4 text-left text-body-sm-heavy;
  }
</style>
