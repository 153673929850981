const _xs = ["0.75rem",{"lineHeight":"1rem"}]
const _sm = ["0.875rem",{"lineHeight":"1.25rem"}]
const _base = ["1rem",{"lineHeight":"1.5rem"}]
const _lg = ["1.125rem",{"lineHeight":"1.75rem"}]
const _xl = ["1.25rem",{"lineHeight":"1.75rem"}]
const _2xl = ["1.5rem",{"lineHeight":"2rem"}]
const _3xl = ["1.875rem",{"lineHeight":"2.25rem"}]
const _4xl = ["2.25rem",{"lineHeight":"2.5rem"}]
const _5xl = ["3rem",{"lineHeight":"1"}]
const _6xl = ["3.75rem",{"lineHeight":"1"}]
const _7xl = ["4.5rem",{"lineHeight":"1"}]
const _8xl = ["6rem",{"lineHeight":"1"}]
const _9xl = ["8rem",{"lineHeight":"1"}]
const _2xs = ["10px",{"lineHeight":"10px"}]
const config = { "xs": _xs, "sm": _sm, "base": _base, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl, "8xl": _8xl, "9xl": _9xl, "2xs": _2xs, "title-screen": ["30px",{"lineHeight":"35.2px","letterSpacing":"-0.025rem","fontWeight":"500"}], "title-form": ["30px",{"lineHeight":"33px","letterSpacing":"-0.047rem","fontWeight":"500"}], "title-section": ["26px",{"lineHeight":"31.2px","letterSpacing":"-0.015rem","fontWeight":"500"}], "title-subsection": ["22px",{"lineHeight":"28.6px","letterSpacing":"-0.015rem","fontWeight":"500"}], "title-body": ["18px",{"lineHeight":"25.2px","letterSpacing":"-0.01rem","fontWeight":"500"}], "body-lg": ["16px",{"lineHeight":"23.2px","letterSpacing":"0.005rem","fontWeight":"400"}], "body-lg-heavy": ["16px",{"lineHeight":"23.2px","letterSpacing":"0.005rem","fontWeight":"500"}], "body-default": ["14px",{"lineHeight":"20.3px","letterSpacing":"0.0125rem","fontWeight":"400"}], "body-default-heavy": ["14px",{"lineHeight":"20.3px","letterSpacing":"0.0125rem","fontWeight":"500"}], "body-sm": ["13px",{"lineHeight":"18.85px","letterSpacing":"0.016rem","fontWeight":"400"}], "body-sm-heavy": ["13px",{"lineHeight":"18.85px","letterSpacing":"0.016rem","fontWeight":"500"}], "button-base": ["15px",{"lineHeight":"21.75px","letterSpacing":"-0.01em","fontWeight":"700"}], "button-sm": ["13px",{"lineHeight":"18.75px","letterSpacing":"-0.01em","fontWeight":"700"}],  }
export { config as default, _xs, _sm, _base, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl, _8xl, _9xl, _2xs }