<template>
  <div class="overflow-hidden flex flex-grow">
    <div class="max-h-full overflow-auto w-full">
      <table class="min-w-full relative table-fixed border-separate border-spacing-0" ref="tableRef">
        <thead>
          <tr
            class="text-quarterary border-00 bg-surface-lvl-00"
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
          >
            <template v-for="header in headerGroup.headers" :key="header.id">
              <TCheckbox
                class="sticky z-10 whitespace-nowrap checkbox top-0 w-0 [&_td]:shadow-lvl-01"
                v-if="header.id === 'select'"
                :readonly="selectAll === undefined"
                context="header"
                :model-value="props.selectAll"
                :indeterminate="table.getIsSomeRowsSelected()"
                @update:modelValue="toggleAllSelection"
                :show="selectAll !== undefined"
                :colSpan="header.colSpan"
                :data-header="header.id"
              />
              <THeader
                class="sticky z-10 whitespace-nowrap top-0"
                v-else
                :key="header.id"
                :header="getHeaderByKey(header.id)"
                :table="table"
                :headerDef="header"
                :headerKey="header.id"
                :colSpan="header.colSpan"
              />
            </template>
          </tr>
        </thead>
        <tr
          class="group/row border-00 text-secondary bg-surface-lvl-01 sticky z-20"
          v-for="(row, idx) in table.getTopRows()"
          :key="row.id"
          @click.prevent="(evt) => onRowClick(evt, row.original, row.index)"
          @click.middle.prevent="(evt) => onRowClick(evt, row.original, row.index)"
          :hasOnRowClick="hasOnRowClick(row.original)"
          :class="{
            'hover:bg-surface-lvl-01 hover:text-primary cursor-pointer': hasOnRowClick(row.original),
            'shadow-lvl-01': idx === table.getTopRows().length - 1,
          }"
          :style="{
            top: `${idx * 48 + 36}px`,
          }"
        >
          <template v-for="cell in row.getVisibleCells()" :key="cell.id">
            <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
          </template>
        </tr>
        <tr
          class="group/row border-00 text-secondary bg-surface-lvl-00"
          v-for="row in table.getCenterRows()"
          :key="row.id"
          @click.prevent="(evt) => onRowClick(evt, row.original, row.index)"
          @click.middle.prevent="(evt) => onRowClick(evt, row.original, row.index)"
          :hasOnRowClick="hasOnRowClick(row.original)"
          :class="{
            'border-02 !bg-01': row.getIsSelected(),
            //  'border-01 text-disabled': disabled,
            'hover:bg-surface-lvl-01 hover:text-primary cursor-pointer': hasOnRowClick(row.original),
          }"
        >
          <template v-for="cell in row.getVisibleCells()" :key="cell.id">
            <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
          </template>
        </tr>
      </table>
      <Overlay class="inset-x-0 sticky h-full w-full" v-if="loading"></Overlay>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    FlexRender,
    getCoreRowModel,
    useVueTable,
    createColumnHelper,
    type RowSelectionState,
    type Row,
    type RowPinningState,
  } from "@tanstack/vue-table";

  import TCheckbox from "./Checkbox.vue";
  import TCell from "./Row/Cell/index.vue";
  import THeader from "./Headers/Header.vue";
  import type { ITableData, ITableHeader } from "./types";
  import _ from "lodash";
  import Wrapper from "./Row/Cell/Wrapper.vue";
  defineOptions({
    name: "Table",
  });

  const props = withDefaults(
    defineProps<{
      headers: ITableHeader[];
      columns?: ITableHeader[];
      items: ITableData[];
      resizable?: boolean;
      checkbox?: boolean;
      rowIdentifier?: string;
      selectAll?: boolean | undefined;
      indeterminate?: boolean;
      loading?: boolean;
      detailsPage?: string;
      newIndicator?: string | Function;
      hideHeaders?: boolean;
      selected?: string[] | ITableData[];
      stickySelected?: boolean;
    }>(),
    {
      headers: () => [],
      columns: () => [],
      items: () => [],
      resizable: false,
      checkbox: false,
      rowIdentifier: "id",
      indeterminate: false,
      loading: false,
      hideHeaders: false,
      selected: () => [],
      selectAll: undefined,
    }
  );

  const { t } = useI18n();
  const tableRef = ref();
  const rowSelection = ref<RowSelectionState>({});
  const rowPinning = ref<RowPinningState>({
    top: [],
    bottom: [],
  });

  onMounted(() => {
    if (props.selected.length === 0) return;
    rowSelection.value = props.selected.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
  });

  const columnHelper = createColumnHelper();

  const slots = useSlots();

  const getHeaderByKey = (key: string): ITableHeader | undefined =>
    props.headers.find((h) => h.key.replaceAll(".", "_") === key);

  const mappedColumns = computed(() => {
    const mappedHeaders: any[] = props.headers.map((header) => {
      return columnHelper.accessor(header.key, {
        cell: ({ row, column }) => {
          const idx = column.getIndex();

          const isLead = props.checkbox ? idx === 1 : idx === 0;
          const slotContent = slots[`col.${header.key}`];
          const Component = cellComponent(header, isLead);
          const propsToPass = {
            item: row.original,
            index: idx,
            lead: isLead,
            header,
            isNew: isRowNew(row.original, props.newIndicator),
          };

          if (slotContent) {
            return h(TCell, propsToPass, {
              default: () => h(Wrapper, null, { default: () => slotContent({ item: row.original }) }),
            });
          }

          return h(TCell, propsToPass);
        },
        header: () => h(THeader, { header }),
      });
    });

    if (props.checkbox) {
      mappedHeaders.unshift({
        id: "select",
        cell: ({ row }: { row: any }) =>
          h(TCheckbox, {
            context: "row",
            modelValue: props.selectAll || row.getIsSelected() || !!row.getIsPinned(),
            disabled: !row.getCanSelect() && !row.getCanPin(),
            "onUpdate:modelValue": props.stickySelected ? () => pinRow(row) : row.getToggleSelectedHandler(),
          }),
      });
    }

    return mappedHeaders;
  });

  const hasOnRowClick = (item: ITableData) => {
    if (!props.detailsPage) {
      //check if row:click is passed as a listener
      return !!getCurrentInstance()?.vnode.props["onRow:click"];
    }
    const link = useRouter().resolve({ name: props.detailsPage, params: { id: item.id } })?.href;

    return !!link;
  };

  const onRowClick = (event: Event, item: ITableData, idx: number) => {
    if (!props.detailsPage) return emit("click:row", item, idx);
    const link = useRouter().resolve({ name: props.detailsPage, params: { id: item.id } })?.href;
    if (!link) return;
    //if middle click is pressed open in new tab
    if (event instanceof MouseEvent && event.button === 1) {
      window.open(link, "_blank")?.focus();
      //go to the tab
      //if ctrl or cmd is pressed open in new tab
    } else if (event.ctrlKey || event.metaKey) {
      window.open(link, "_blank")?.focus();
    } else {
      useRouter().push(link);
    }
  };

  const pinRow = (row: Row<any>) => {
    if (!row.getIsPinned()) {
      return row.pin("top", false, false);
    } else {
      return row.pin(false);
    }
  };

  watch(rowSelection, (newVal) => {
    emit(
      "update:selected",
      Object.keys(newVal).filter((k) => newVal[k])
    );
  });

  const toggleAllSelection = () => {
    emit("select:all");
    rowSelection.value = {};
  };

  const emit = defineEmits(["update:selected", "select:all", "click:row"]);

  watch(
    () => props.items,
    (newVal) => {
      table.resetRowPinning();
    }
  );

  const table = useVueTable({
    get data() {
      return props.items;
    },
    enableRowSelection: props.checkbox,

    state: {
      get rowSelection() {
        return rowSelection.value;
      },
      get columnVisibility() {
        return Object.fromEntries(props.columns.map((c) => [c.key, c.visible]));
      },
      get columnOrder() {
        const order = props.columns.map((c) => c.key?.replaceAll(".", "_"));
        if (props.checkbox) order.unshift("select");

        return order;
      },
      get rowPinning() {
        return rowPinning.value;
      },
    },
    enableMultiRowSelection: true,
    onRowSelectionChange: (updateOrValue) => {
      rowSelection.value = typeof updateOrValue === "function" ? updateOrValue(rowSelection.value) : updateOrValue;
    },
    onRowPinningChange: (updateOrValue) => {
      rowPinning.value = typeof updateOrValue === "function" ? updateOrValue(rowPinning.value) : updateOrValue;
    },
    getRowId: (row) => row[props.rowIdentifier],
    renderFallbackValue: t("not_specified"),
    columns: mappedColumns.value,
    getCoreRowModel: getCoreRowModel(),
  });
</script>
