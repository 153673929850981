<template>
  <svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3374_1890)">
      <path
        d="M15.282 1.24734L10.0124 14.7883H6.57438L3.98119 3.982C3.82375 3.3164 3.68686 3.07256 3.20805 2.79214C2.42674 2.33538 1.1358 1.90707 0 1.6411L0.0771458 1.24734H5.61131C6.31673 1.24734 6.95088 1.75309 7.11104 2.62799L8.48059 10.4635L11.8654 1.24734H15.282ZM28.753 10.3673C28.7668 6.79343 24.1645 6.59654 24.1961 5.00005C24.206 4.51417 24.6355 3.99758 25.5757 3.86572C26.0417 3.80002 27.3258 3.7499 28.7821 4.47172L29.3534 1.60024C28.5708 1.2943 27.5639 0.999886 26.3111 0.999886C23.0964 0.999886 20.834 2.84045 20.8149 5.47599C20.7942 7.42539 22.4297 8.5132 23.662 9.16096C24.9296 9.82431 25.355 10.2506 25.3502 10.8439C25.3411 11.7523 24.3391 12.1532 23.4026 12.169C21.7679 12.1961 20.8193 11.6926 20.0632 11.314L19.4737 14.2805C20.2336 14.656 21.6363 14.9836 23.0905 15.0001C26.5074 15.0001 28.7425 13.1824 28.753 10.3673ZM37.2419 14.7886H40.25L37.6243 1.24734H34.8479C34.2236 1.24734 33.697 1.63885 33.4639 2.24078L28.5834 14.7883H31.9985L32.6767 12.7656H36.8495L37.2422 14.7883L37.2419 14.7886ZM33.6129 9.99048L35.3248 4.90635L36.3101 9.99048H33.6132H33.6129ZM19.9292 1.24734L17.2398 14.7883H13.9875L16.678 1.24734H19.9292Z"
        fill="#18246D"
        style="fill: #18246d; fill: color(display-p3 0.0941 0.1412 0.4275); fill-opacity: 1"
      />
    </g>
    <defs>
      <clipPath id="clip0_3374_1890">
        <rect width="40.25" height="16" fill="white" style="fill: white; fill-opacity: 1" />
      </clipPath>
    </defs>
  </svg>
</template>
