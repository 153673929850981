const _sm = "0 1px 2px 0 rgb(0 0 0 / 0.05)"
const _DEFAULT = "0px 2px 12px -1px rgba(0, 0, 0, 0.10)"
const _md = " 0px 6px 16px rgba(0, 0, 0, 0.08)"
const _lg = "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
const _xl = "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)"
const _2xl = "0 25px 50px -12px rgb(0 0 0 / 0.25)"
const _inner = "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)"
const _none = "none"
const _focus = "0px 0px 0px 4px #CDDDF6"
const _toast = "0px 0px 0px 1px rgba(0, 0, 0, 0.10), 0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 16px 24px -6px rgba(0, 0, 0, 0.10)"
const config = { "sm": _sm, "DEFAULT": _DEFAULT, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "inner": _inner, "none": _none, "focus": _focus, "toast": _toast, "focus-gray": "0px 0px 0px 4px #E7E8E9", "focus-blue": "0px 0px 0px 1px #FFF, 0px 0px 0px 3px #0058D9", "inset-00": "0px 0px 0px 1px #ebebeb inset", "inset-01": "0px 0px 0px 2px #2f3237 inset", "inset-00+01": "0px 0px 0px 1px #ebebeb inset, 0px 1px 3px 0px rgba(38, 40, 44, 0.10), 0px -1px 0px 0px rgba(38, 40, 44, 0.10) inset", "inset-01+01": "0px 0px 0px 2px #2f3237 inset, 0px 1px 3px 0px rgba(38, 40, 44, 0.10), 0px -1px 0px 0px rgba(38, 40, 44, 0.10) inset", "avatar-border-xs": "0px 0px 0px 1px #1C1E211F inset", "avatar-border-sm": "0px 0px 0px 1.25px #1C1E211F inset", "avatar-border-md": "0px 0px 0px 1.5px #1C1E211F inset", "avatar-border-lg": "0px 0px 0px 1.75px #1C1E211F inset", "avatar-border-xl": "0px 0px 0px 2px #1C1E211F inset", "lvl-01": "0px 1px 3px 0px rgba(38, 40, 44, 0.10), 0px -1px 0px 0px rgba(38, 40, 44, 0.10) inset", "lvl-02": "0px -1px 0px 0px rgba(38, 40, 44, 0.10) inset, 0px 4px 8px 0px rgba(38, 40, 44, 0.10)", "lvl-03": "0px 6px 12px 0px rgba(38, 40, 44, 0.10)", "lvl-04": "0px 8px 24px 0px rgba(38, 40, 44, 0.10)", "lvl-05": "0px 12px 36px 0px rgba(38, 40, 44, 0.12)",  }
export { config as default, _sm, _DEFAULT, _md, _lg, _xl, _2xl, _inner, _none, _focus, _toast }