<template>
  <div class="text-body-sm text-secondary flex-1">
    {{ t("showing") }} <span class="text-body-sm-heavy text-primary">{{ currentFrom }}</span>
    {{ t("to").toLowerCase() }} <span class="text-body-sm-heavy text-primary">{{ currentTo }}</span>
    {{ t("of").toLowerCase() }} <span class="text-body-sm-heavy text-primary">{{ total }}</span>
  </div>
  <div class="flex-1 flex gap-1 justify-center">
    <Button
      v-if="!!pagination.prev_page"
      icon-only
      :size="ComponentSize.sm"
      @click="() => goToPage(pagination.prev_page)"
      ><Icon src="arrow_left"
    /></Button>
    <Button
      v-for="(page, key) in pageRange"
      :size="ComponentSize.sm"
      :active="current == page"
      variant="tertiary"
      @click="() => goToPage(page)"
      >{{ page }}</Button
    >
    <Button
      v-if="!!pagination.next_page"
      icon-only
      :size="ComponentSize.sm"
      @click="() => goToPage(pagination.next_page)"
      ><Icon src="arrow_right"
    /></Button>
  </div>
  <div class="flex-1"></div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { useI18n } from "vue-i18n";
  import { ComponentSize } from "~/types/global";
  import type { Pagination } from "~/types/paginate";

  //props for a laravel paginator
  const props = withDefaults(
    defineProps<{
      pagination: Pagination;
      entityName?: string;
      keepLength?: boolean;
      limit?: number;
    }>(),
    {
      pagination: {
        current_page: 1,
        total: 0,
        per_page: 10,
        next_page: null,
        prev_page: null,
        last_page: null,
      },
      keepLength: true,
      limit: 5,
    }
  );

  const emit = defineEmits(["update:current_page"]);

  const { t } = useI18n();

  const currentFrom = computed(() => {
    if (!props.pagination?.per_page) return 0;

    return props.pagination.per_page * (current.value - 1) + 1;
  });

  const currentTo = computed(() => {
    if (!props.pagination?.per_page) return 0;

    return Math.min(props.pagination.per_page * current.value, total.value);
  });

  const lastPage = computed(() => {
    return props.pagination?.last_page || 1;
  });

  const total = computed(() => {
    return props.pagination?.total || 0;
  });

  const current = computed(() => {
    return props.pagination?.current_page || 1;
  });

  const pageRange = computed(() => {
    // Initialize an empty array to hold the range of pages.
    let range = [];

    // Define the start and end of the pagination, as well as the limit of pages to show.

    let limit = props.limit;

    // Find the middle index of the pagination to ensure the current page is centered.
    let middle = Math.floor(limit / 2);

    if (lastPage.value <= limit) {
      // If there are fewer or equal pages than the limit, add all pages to the range.
      for (let i = 1; i <= lastPage.value; i++) {
        range.push(i);
      }
    } else {
      // If the current page is near the start of the pagination...
      if (current.value <= middle) {
        for (let i = 1; i <= limit; i++) {
          range.push(i);
        }
      }
      // ...or if the current page is near the end of the pagination...
      else if (current.value >= lastPage.value - middle) {
        for (let i = lastPage.value - limit + 1; i <= lastPage.value; i++) {
          range.push(i);
        }
      }
      // ...otherwise, ensure the current page is in the middle of the range.
      else {
        for (let i = current.value - middle; i <= current.value + middle; i++) {
          range.push(i);
        }
      }
    }

    // If the last page is not in the range and the missing pages are more than 1, add an ellipsis. If the missing pages are less than 1, add the last pages
    if (range[range.length - 1] != lastPage.value && lastPage.value - range[range.length - 1] > 1) {
      range.push("...");
      range.push(lastPage.value);
    } else if (range[range.length - 1] != lastPage.value && lastPage.value - range[range.length - 1] <= 1) {
      for (let i = range[range.length - 1] + 1; i <= lastPage.value; i++) {
        range.push(i);
      }
    }

    // If the first page is not in the range and the missing pages are more than 1, add an ellipsis. If the missing pages are less than 1, add the first pages
    if (range[0] != 1 && range[0] - 1 > 1) {
      range.unshift("...");
      range.unshift(1);
    } else if (range[0] != 1 && range[0] - 1 <= 1) {
      for (let i = range[0] - 1; i >= 1; i--) {
        range.unshift(i);
      }
    }

    // Return the final range of pages.
    return range;
  });

  const goToPage = (page?: number | "..." | null) => {
    if (page == "..." || !page) {
      return;
    }
    emit("update:current_page", page);
  };
</script>
