<template>
  <div class="flex flex-col gap-1">
    <label class="text-body-sm-heavy text-left text-secondary" v-if="label">{{ label }}</label>

    <div class="w-full flex gap-3">
      <div class="">
        <DropdownSelect
          class="w-full max-w-[100px]"
          v-model="countrycode"
          filterable
          :name="prefixName"
          @update:model-value="updatePhone"
          :options="phonePrefixes.prefixes"
        />
      </div>
      <div class="">
        <InputText
          v-model="number"
          :name="name"
          @update:model-value="updatePhone"
          :placeholder="t('phone')"
        ></InputText>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const emit = defineEmits(["update:modelValue"]);

  const props = withDefaults(
    defineProps<{
      name?: string;
      prefixName?: string;
      label?: string;
      values?: any;
      setPrefix?: any;
    }>(),
    {}
  );

  onMounted(() => {
    if (props.values) {
      number.value = props.values.phone_number;
    }
  });

  watch(
    () => props.setPrefix,
    (val) => {
      if (val) {
        const foundPrefix = phonePrefixes.value.prefixes.find((prefix) => prefix.iso === val);
        if (foundPrefix) {
          countrycode.value = foundPrefix.value;
        }
      }
    }
  );

  const { data: phonePrefixes } = await useCountries();

  const countrycode = ref("+45");

  const number = ref("");

  watchEffect(() => {});

  const updatePhone = () => {
    emit("update:modelValue", { prefix: countrycode.value, phone_number: number.value });
  };
</script>
