<template>
  <div
    class="rounded-full flex transition-all duration-300 flex-shrink-0"
    tabindex="0"
    :class="[
      inputValue ? 'bg-positive hover:bg-positive-dark' : 'bg-02',
      disabled ? 'cursor-not-allowed !bg-disabled' : 'cursor-pointer focus:shadow-focus-blue',
      size === 'sm' ? 'h-4 p-1 w-7' : 'h-5 p-[3px] w-[38px]',
    ]"
    @click="onClick"
  >
    <div
      class="shadow-surface-lvl-01 shadow-lvl-01 rounded-full transition-all duration-300"
      :class="[
        inputValue && size == 'md' ? 'ml-[18px]' : 'ml-0',
        inputValue && size == 'sm' ? 'ml-[11px]' : 'ml-0',
        disabled ? ' bg-disabled-dark' : 'bg-white',
        size === 'sm' ? 'w-2 h-2' : 'w-3.5 h-3.5',
      ]"
    ></div>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      name?: string;
      selected?: boolean;
      disabled?: boolean;
      size?: "sm" | "md";
      parentClicked?: boolean;
    }>(),
    {
      name: "",
      selected: false,
      disabled: false,
      size: "md",
      parentClicked: false,
    }
  );

  const emit = defineEmits(["update:modelValue"]);
  const modelValue = defineModel();

  const onClick = () => {
    if (props.disabled) return;
    setValue();
  };

  watch(
    () => props.parentClicked,
    (val) => {
      setValue();
    }
  );

  const { value: inputValue } = useField(() => props.name, undefined, {
    type: "checkbox",
    checkedValue: true,
    uncheckedValue: false,
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const setValue = () => {
    if (props.disabled) return;
    inputValue.value = !inputValue.value;
    emit("update:modelValue", inputValue.value);
  };
</script>
