export const downloadDocument = (
  d:
    | IDocument
    | {
        id: number | string;
        document_number: string;
        file_type: string;
      }
): Promise<any> => {
  const { t } = useNuxtApp().$i18n;
  $toast.add({
    group: `document_downloading`,
    title: t("document_downloading"),
    icon: "file_text",
  });

  return homeFetchRaw("documents/" + d.id, {
    responseType: "blob",
    silent: true,
  })
    .then((response) => {
      const contentDisposition = response.headers.get("content-disposition");
      const { _data } = response;
      if (contentDisposition) {
        let fileName = contentDisposition.split("filename=")[1].replace(/"/g, "");

        if (!fileName) {
          fileName = `${d.document_number}.${d.file_type}`;
        }
        downloadFile(_data, fileName);
      }
    })
    .catch((error) => {
      $toast.add({
        type: "error",
        group: `document_downloading_error`,
        title: t("error"),
        text: t("document_generation.failed_description"),
      });
    });
};
