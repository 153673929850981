<template>
  <Button class="dropdown-trigger" ref="button" type="button" :size="size" :disabled="disabled">
    <template #prefix>
      <slot name="prefix"> </slot>
    </template>
    <div class="w-full overflow-hidden text-left truncate line-clamp-1">
      <slot>{{ t("select") }}</slot>
    </div>
    <template #suffix>
      <Icon class="ml-auto" src="bold/caret_down" :size="ComponentSize.sm" />
    </template>
  </Button>
</template>

<script setup lang="ts">
  import { useI18n } from "vue-i18n";
  import { ref } from "vue";
  import { computed } from "vue";
  import { ComponentSize } from "~/types/global";

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      size?: ButtonSize;
      disabled?: boolean;
    }>(),
    {
      size: ComponentSize.default,
      disabled: false,
    }
  );

  defineOptions({
    name: "DropdownTriggerButton",
  });

  const button = ref<HTMLElement | null>(null);
  const rawButton = computed(() => button.value?.el);

  defineExpose({
    el: rawButton,
  });
</script>
