<template>
  <DropdownSelect
    v-bind="$attrs"
    v-model="inputValue"
    :options="countries"
    :triggerText="t('select_country')"
    :error="error"
    filterable
  />
</template>

<script setup lang="ts">
  import { ref, computed } from "vue";

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      name?: string;
      error?: string;
      selectDefault?: boolean;
    }>(),
    {
      name: "",
      selectDefault: false,
    }
  );

  const modelValue = defineModel({ default: "" });

  onMounted(() => {
    if (props.selectDefault) {
      inputValue.value = "DK";
    }
  });

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const error = computed(() => {
    return props.error || errorMessage.value;
  });

  const input = ref<HTMLElement | null>(null);

  const el = computed(() => {
    return input.value?.input;
  });

  const emit = defineEmits(["click"]);

  const countries = getCountrySelectOptions();

  defineExpose({
    el,
  });
</script>
