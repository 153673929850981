<template>
  <component
    class="text-body-default flex items-center justify-start hover:bg-00 transtition duration-100 rounded-lg dropdown__item whitespace-nowrap gap-4 group"
    :is="href ? NuxtLink : 'div'"
    @click="callParentClose"
    tabindex="0"
    :class="{
      'cursor-not-allowed': disabled,
      'bg-01': active,
      'cursor-pointer': !disabled,
      'px-3 py-1.5': size === 'default',
      'h-14 py-2 px-4 border-00 hover:border-02 border rounded-lg shadow-lvl-01 hover:shadow-none': size === 'large',
    }"
    :to="href"
    :external="hrefIsExternal"
    :target="props.target"
    ref="el"
  >
    <slot name="prefix"></slot>
    <slot name="custom"></slot>
    <div class="flex flex-col w-full">
      <div
        class="truncate"
        :class="{
          'text-secondary group-hover:text-primary': variant === 'default' && (size === 'default' || 'large'),
          'text-primary': variant === 'default' && selected && size === 'default',
          'text-destructive': variant === 'destructive' && size === 'default',
          '!text-disabled': disabled && size === 'default',
          'text-body-default-heavy ': size === 'large',
        }"
      >
        <slot>
          {{ title }}
        </slot>
      </div>

      <slot name="subtext">
        <span class="text-body-sm text-quarterary group-hover:text-tertiary" v-if="subtext">{{ subtext }}</span>
      </slot>
    </div>
    <Icon class="ml-auto text-foreground-secondary" v-if="selected" src="check" />
    <Icon class="ml-auto text-foreground-secondary" v-if="hasArrow" src="arrow_right" />
  </component>
</template>

<script setup lang="ts">
  import { NuxtLink } from "#components";

  defineOptions({
    name: "DropdownItem",
  });

  const props = withDefaults(
    defineProps<{
      variant?: "default" | "destructive";
      selected?: boolean;
      active?: boolean;
      disabled?: boolean;
      hasArrow?: boolean;
      title?: string;
      subtext?: string;
      size?: "default" | "large" | "hug";
      href?: string | Record<string, any>;
      closeOnClick?: boolean;
      target?: string;
    }>(),
    {
      variant: "default",
      selected: false,
      disabled: false,
      active: false,
      hasArrow: false,
      size: "default",
      closeOnClick: true,
      target: "_self",
    }
  );
  const instance = ref(null);
  const emit = defineEmits(["click"]);

  const el = ref<HTMLElement | null>(null);
  onMounted(() => {
    instance.value = getCurrentInstance();
  });

  const hrefIsExternal = typeof props?.href === "string" && props.href.startsWith("http");
  const callParentClose = (evt) => {
    if (props.disabled) return;
    if (!props.closeOnClick) {
      emit("click", evt);
      return;
    }

    const dropdownParent = getParentUntilHasEmitOptions(instance.value);

    emit("click", evt);

    nextTick(() => {
      if (dropdownParent) dropdownParent.emit("update:open", false);
    });
  };

  const getParentUntilHasEmitOptions = (instance: any): any => {
    if (instance) {
      if (instance?.emitsOptions && Object.prototype.hasOwnProperty.call(instance?.emitsOptions, "update:open"))
        return instance;
      else if (instance.parent) return getParentUntilHasEmitOptions(instance.parent);
    }
    return null;
  };

  defineExpose({
    el,
  });
</script>

<style>
  .dropdown__item.animate-right {
    animation: slide-in-right cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }

  .dropdown__item.animate-left {
    animation: slide-in-left cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }

  @keyframes slide-in-right {
    0% {
      transform: translateX(100%);
    }
    25% {
      transform: translateX(0%);
    }
    66% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-in-left {
    0% {
      transform: translateX(-100%);
    }
    25% {
      transform: translateX(0%);
    }
    66% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0%);
    }
  }
</style>
