<template>
  <div class="relative">
    <label class="text-body-sm-heavy text-left text-secondary" v-if="label">{{ label }}</label>
    <div id="editorWrapper">
      <QuillyEditor v-model="text" ref="editor" :options="options" @update:model-value="onModelValueChange" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import Quill from "quill"; // Core build
  const props = defineProps<{
    name: string;
    label?: string;
  }>();

  const editor = ref();
  const model = ref<string>("");

  const text = ref<string>("");

  let quill: Quill | null = null;

  const options = ref({
    theme: "snow",
    bounds: "#editorWrapper",
    modules: {
      toolbar: [["bold", "italic", "underline"], ["link"], [{ list: "ordered" }, { list: "bullet" }]],
    },
    readOnly: false,
  });

  /*const options = {
        bounds: "#editorWrapper",
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline'],
                ['link'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ]
        },
    }*/

  const onModelValueChange = (value: string) => {
    inputValue.value = quill.getSemanticHTML();
  };

  onMounted(() => {
    quill = editor.value?.initialize(Quill)!;
    if (inputValue.value) {
      text.value = inputValue.value;
    }
  });

  const { value: inputValue } = useField(() => props.name, undefined, {
    syncVModel: true,
  });
</script>

<style>
  .ql-toolbar.ql-snow {
    border-bottom: none;
    @apply rounded-t-[10px] border-x border-t border-00 bg-surface-lvl-00 !p-0.5;
  }
  .ql-editor {
    @apply !max-h-[300px] !min-h-[200px] overflow-auto rounded-b-xl border-x border-t border-00 !bg-00 !px-4 text-body-default text-primary placeholder:text-quarterary read-only:text-inherit hover:placeholder:text-tertiary;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    @apply relative min-h-[100px] w-full min-w-full border-none;
  }

  .ql-snow.ql-toolbar button svg {
    @apply !h-5 !w-5 !text-foreground-secondary;
  }
  .ql-formats {
    @apply !m-0 max-w-max gap-0.5;
  }
  .ql-snow.ql-toolbar button {
    @apply m-0 mr-0.5 flex !h-7 !w-7 items-center rounded-lg text-secondary transition-all duration-300 hover:bg-01 hover:!text-primary active:bg-02;
  }

  .ql-snow.ql-toolbar button.ql-active {
    @apply !bg-02 !text-primary transition-all duration-300;
  }

  .ql-snow.ql-toolbar button.ql-active .ql-stroke {
    @apply !stroke-foreground-primary transition-all duration-300;
  }
  .ql-snow.ql-toolbar button.ql-active .ql-fill {
    @apply !fill-foreground-primary transition-all duration-300;
  }
  .ql-snow.ql-toolbar button .ql-stroke {
    @apply !stroke-foreground-primary transition-all duration-300;
  }
  .ql-snow.ql-toolbar button .ql-fill {
    @apply !fill-foreground-primary transition-all duration-300;
  }
  .ql-tooltip .ql-editing {
    @apply !left-0;
  }
</style>
