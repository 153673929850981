import md5lib from "md5";

export const md5 = (message: string): string => md5lib(message);

export const isString = (value: any) => typeof value === "string" || value instanceof String;

export const removeHtmlTags = (str: string) => str?.replace(/<[^>]*>?/gm, "") || "";

export const stringToIntegerValue = (value?: string | null) => {
  if (!value) {
    return 0;
  }
  return value.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
};

export const getFileNameFromFileUrl = (url: string) => {
  if (!url || typeof url !== "string") return "";
  //handle potential query string in url
  url = url.split("?")[0];

  return url.split("/").pop() || "";
};
