<template>
  <div class="flex justify-evenly py-3 px-5 items-center bg-surface-lvl-00 paginator h-paginator border-t">
    <ClientOnly>
      <TablePaginatorCursor v-if="isCursor" :pagination="pagination" @update:cursor="updateCursor" />
      <TablePaginatorLengthaware
        v-else
        :pagination="pagination"
        @update:current_page="updateCurrentPage"
        :entity-name="entityName"
        :keep-length="keepLength"
        :limit="limit"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { computed } from "vue";
  import { useI18n } from "vue-i18n";
  import type { CursorPagination, Pagination } from "~/types/paginate";

  defineOptions({
    name: "Paginator",
  });

  //props for a laravel paginator
  const props = withDefaults(
    defineProps<{
      pagination: Pagination | CursorPagination | null;
      entityName?: string;
      keepLength?: boolean;
      limit?: number;
    }>(),
    {
      pagination: null,
    }
  );

  const emit = defineEmits(["paginate"]);

  const updateCurrentPage = (page: number) => {
    emit("paginate", page);
  };

  const updateCursor = (cursor: string) => {
    emit("paginate", cursor);
  };

  const isCursor = computed(() => {
    //check if pagination is cursor based
    return props.pagination?.is_cursor ?? false;
  });
</script>
