<template>
  <section class="px-0 pb-1 text-body-default text-tertiary">
    <header class="sticky top-0 z-10 flex items-center gap-2 bg-surface-lvl-00 px-2.5 py-1.5">
      <Icon class="text-quarterary" :src="icon" :size="ComponentSize.lg" />
      {{ title }}
    </header>
    <slot></slot>
  </section>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import type { SearchResultCategory } from "~/types/search";

  defineOptions({
    name: "GlobalSearchCategory",
  });

  const props = withDefaults(
    defineProps<{
      category: SearchResultCategory;
    }>(),
    {
      category: "recent" as SearchResultCategory,
    }
  );

  const title = computed(() => {
    return globalSearchCategoryTitle(props.category);
  });

  const icon = computed(() => {
    return globalSearchCategoryIcon(props.category);
  });
</script>
