export default ({
  options,
  modelValue,
  emit,
  open,
  dropdown,
  toggleDropdown,
  multiple,
}: {
  options: Ref<{ label: string; value: any; handler?: null | Function; disabled?: boolean }[] | null>;
  modelValue: any;
  emit?: any;
  open: Ref<boolean>;
  dropdown: Ref<HTMLElement | null>;
  toggleDropdown: () => void;
  multiple?: boolean;
}) => {
  const searchElm = ref<HTMLElement | null>(null);
  const optionsWrapper = ref<HTMLElement | null>(null);

  const search = ref("");

  const filteredOptions = computed<{ label: string; value: any; image?: string | null }[]>(() => {
    if (!options?.value) return [];
    let filtered = options.value;
    if (multiple) {
      //sort selected items to the top
      const selected = filtered.filter((option) => modelValue.value.includes(option.value));
      if (selected.length > 0) selected.push(null);
      const unselected = filtered.filter((option) => !modelValue.value.includes(option.value));
      filtered = [...selected, ...unselected];
    }

    if (!search.value) return filtered;

    filtered = filtered.filter((filterKey) => {
      if (!filterKey) return false;
      return filterKey.label?.toLowerCase().includes(search.value.toLowerCase());
    });

    return filtered;
  });

  const prettyModelValue = computed(() => {
    if (!options?.value) return "";

    if (multiple) {
      const selectedOptions = modelValue.value.map((value: any) => {
        const option = options.value.find((option) => option.value === value);
        return option?.label || "";
      });

      if (selectedOptions.length > 2) {
        return `${selectedOptions.slice(0, 2).join(", ")} +${selectedOptions.length - 2}`;
      }

      return selectedOptions.join(", ");
    }

    let option = null;

    if (valueIsArray.value && modelValue.value.length)
      option = options.value.find((option) => option.value === modelValue.value[0]);
    else option = options.value.find((option) => option.value === modelValue.value);
    return option?.label || "";
  });

  const setFocusOnItem = (index: number, direction: "down" | "up") => {
    switch (direction) {
      case "down":
        if (index === -1 || index === filteredOptions.value.length - 1) index = 0;
        else index++;
        break;
      case "up":
        if (index === -1 || index === 0) index = filteredOptions.value.length - 1;
        else index--;
        break;
    }

    const items = optionsWrapper.value?.querySelectorAll(".dropdown__item");
    if (!items) return;
    (items[index] as HTMLElement)?.focus();
  };

  watch(open, () => {
    if (open.value) {
      //focus search input
      setTimeout(() => {
        searchElm.value?.focus({ preventScroll: true });
      }, 150);
    } else {
      search.value = "";
    }
  });

  const getOptionFromValue = (value: any) => {
    if (!filteredOptions.value) return null;
    return filteredOptions.value.find((option) => option?.value === value);
  };

  const selectOption = (value: string) => {
    const option = getOptionFromValue(value);
    if (option?.disabled) return;
    if (!multiple) toggleDropdown();
    nextTick(() => {
      if (option && option.handler) {
        option.handler();
        return;
      }
      if (!emit) return;

      if (multiple) {
        const newValue = modelValue.value.includes(value)
          ? modelValue.value.filter((v: any) => v !== value)
          : [...modelValue.value, value];
        emit("select", newValue);
        emit("update:modelValue", newValue);
        modelValue.value = newValue;
        return;
      }

      emit("select", value);
      if (valueIsArray.value) value = [value];
      emit("update:modelValue", value);
      modelValue.value = value;
    });
  };

  const valueIsArray = computed(() => Array.isArray(modelValue.value));

  return {
    searchElm,
    optionsWrapper,
    search,
    filteredOptions,
    prettyModelValue,
    modelValue,
    setFocusOnItem,
    selectOption,
  };
};
