<template>
  <div class="relative flex w-full items-center gap-3">
    <Button type="button" :size="ComponentSize.sm" :disabled="moveIndex == 1" iconOnly @click="scroll('minus')">
      <Icon class="" src="arrow_left" :class="moveIndex == 1 ? 'text-disabled' : 'text-foreground-secondary'"></Icon>
    </Button>
    <div
      class="group flex w-full snap-x gap-5 overflow-x-auto scroll-smooth transition-all duration-300"
      id="slider"
      ref="slider"
    >
      <div
        class="h-auto min-w-[180px] cursor-pointer snap-center rounded-lg px-3 py-4 transition-all duration-300"
        v-for="(item, index) in items"
        :class="selectedIndex == index ? 'shadow-inset-01+01' : 'shadow-inset-00+01'"
        :data-id="item.id"
        @click="() => updateSelection(item, index)"
      >
        <slot name="content" :item="item"></slot>
      </div>
    </div>
    <Button type="button" :size="ComponentSize.sm" :disabled="isAtEnd" iconOnly @click="scroll('add')">
      <Icon class="" src="arrow_right" :class="isAtEnd ? 'text-disabled' : 'text-foreground-secondary'"></Icon>
    </Button>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  defineOptions({
    name: "Slider",
  });

  const props = defineProps<{
    items: Array<any>;
    deselectItem: boolean;
    preSelect?: boolean;
  }>();

  const moveIndex = ref(1);
  const selectedIndex = ref<number | null>(null);
  const slider = ref<HTMLElement>();
  const sliderWidth = ref(0);
  const currentScroll = ref(0);

  const entries = computed(() => props.items);

  let resizeObserver: ResizeObserver;

  onMounted(() => {
    if (process.client) {
      nextTick(() => {
        if (!slider.value) return;
        //watch the width of the slider
        resizeObserver = new ResizeObserver(() => {
          setSliderWidth();
        });

        if (props.preSelect) {
          updateSelection(props.items[0], 0);
        }

        resizeObserver.observe(slider.value);
      });
    }
  });

  onBeforeUnmount(() => {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
  });

  const setSliderWidth = () => {
    sliderWidth.value = slider.value.offsetWidth;

    if (moveIndex.value > possibleNumberOfSteps.value) {
      moveIndex.value = possibleNumberOfSteps.value;
    }
  };

  const possibleVisibleItems = computed(() => {
    //calculate the number of items that can be visible in the slider based on the width of the slider
    if (!slider.value) return 0;
    return Math.floor(sliderWidth.value / 180);
  });

  const possibleNumberOfSteps = computed(() => {
    //calculate the number of steps that can be taken based on the number of items that can be visible
    return Math.ceil(props.items.length / possibleVisibleItems.value);
  });

  const isAtEnd = computed(() => {
    return moveIndex.value === possibleNumberOfSteps.value;
  });

  watch(
    () => props.deselectItem,
    () => {
      selectedIndex.value = null;
    }
  );

  watch(entries, () => {
    setSliderWidth();
  });

  const emit = defineEmits(["update:modelValue"]);

  const scroll = (direction: string) => {
    if (!slider.value) return;
    if (direction === "add") {
      moveIndex.value += 1;
      currentScroll.value += 180 * possibleVisibleItems.value;
    } else {
      moveIndex.value -= 1;
      currentScroll.value -= 180 * possibleVisibleItems.value;
    }

    slider.value.scrollLeft = currentScroll.value;
  };

  const updateSelection = (item: object, index: number, scroll?: boolean) => {
    emit("update:modelValue", item);
    selectedIndex.value = index;

    if (scroll) {
      scrollToIndex(index);
    }
  };

  const scrollToIndex = (idx: number) => {
    if (!slider.value) return;

    //calculate how many times the slider should move
    const steps = Math.floor(idx / possibleVisibleItems.value) + 1;
    console.log("steps", steps, idx);
    const differenceFromSelectedIndex = moveIndex.value - steps;

    if (differenceFromSelectedIndex > 0) {
      for (let i = 0; i < differenceFromSelectedIndex; i++) {
        scroll("minus");
      }
    } else {
      for (let i = 0; i < Math.abs(differenceFromSelectedIndex); i++) {
        scroll("add");
      }
    }
  };

  defineExpose({
    updateSelection,
  });
</script>

<style lang="css" scoped>
  #slider::-webkit-scrollbar {
    display: none;
  }

  #slider {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
