<template>
  <div class="flex gap-10 @xl:px-10 flex-col @xl:flex-row">
    <div class="flex flex-col gap-3 flex-1">
      <div class="flex gap-3" v-for="point in definition.points">
        <Icon src="Check" :size="ComponentSize.lg" />
        <div class="text-body-lg">{{ point }}</div>
      </div>
    </div>
    <div class="flex flex-col flex-1 gap-1.5">
      <template v-for="(tableEntry, idx) in definition.table">
        <div class="flex text-body-default text-tertiary gap-2 justify-between">
          <div>{{ tableEntry.left }}</div>
          <div class="text-right flex gap-1 items-center">
            {{ tableEntry.right }}
            <Icon class="text-tertiary" v-if="tableEntry.infoLink" src="Info" />
          </div>
        </div>
        <Divider v-if="idx !== definition.table.length - 1" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const definition = useHandin();
</script>

<script lang="ts">
  export const useHandin = (): Record<string, any> => {
    return {
      points: [
        "Dine pakker skal indleveres i en Bring pakkeshop.",
        "Indlevering anbefales hvis du sender mindre end 10 pakker om dagen.",
        "Indlevering kan ikke kombineres med afhentning.",
      ],
      table: [
        { left: "Indlevering", right: "0,00 DKK" },
        { left: "Pakkepriser inkl. gebyr", right: "fra 27,70 DKK" },
        { left: "Automatisk optankning", right: "Valgfrit" },
        { left: "Geografisk dækning", right: "Hele EU" },
      ],
    };
  };
</script>
