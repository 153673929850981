<template>
  <div class="min-w-[219px] bg-surface-lvl-01 p-1">
    <div class="flex gap-2.5 py-1.5 px-2 self-stretch">
      <LayoutNavigationWorkspaceAvatar />
      <div class="text-sm font-medium text-primary mr-auto flex flex-col overflow-hidden">
        <span>{{ account.account.name }}</span>
        <span class="text-xs text-tertiary text-nowrap text-ellipsis">{{ currentDomain }}</span>
      </div>
    </div>
    <div class="my-1.5">
      <InputText v-model="search" :placeholder="t('settings.search')" dark-bg>
        <template #prefix>
          <Icon class="text-quarterary" src="bold/magnifying_glass" />
        </template>
      </InputText>
    </div>
    <div class="flex flex-col gap-3">
      <div class="flex flex-col" v-for="(group, key) in groupedFlows">
        <div class="h-[35px] text-body-sm-heavy px-1 pt-[14px] pb-1.5" v-if="groupHasTitle(key)">
          {{ groupTitle(key) }}
        </div>
        <div class="flex flex-col gap-1">
          <Entry
            v-for="(flow, idx) in group"
            :key="flow.key"
            :entry="flow"
            :active="isCurrentFlow(flow.key)"
            @click="selectFlow(flow)"
            :search="search"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ISettingsFlow } from "../../types";
  import Entry from "./Entry.vue";
  import _ from "lodash";

  const props = withDefaults(
    defineProps<{
      flows: ISettingsFlow[];
      currentFlow: ISettingsFlow | null;
    }>(),
    {
      flows: () => [],
      currentFlow: null,
    }
  );
  const { t, te } = useI18n();
  const { getAccount } = useAuth();
  const account = getAccount()!;

  const currentDomain = useRequestURL().hostname;

  const search = ref<string>("");
  const isCurrentFlow = (key: string) => {
    return props.currentFlow?.key === key;
  };

  const groupHasTitle = (group: string) => {
    return te(`workspace_setting_groups.${group}.title`);
  };

  const groupTitle = (group: string) => {
    return t(`workspace_setting_groups.${group}.title`);
  };

  const groupedFlows = computed(() => {
    return _.groupBy(filteredFlows.value, "group");
  });

  const filteredFlows = computed(() => {
    //filter flows based on search, looking through title and description
    let entries = props.flows.map((flow) => {
      let entries = [];
      if (flow.headers && search.value) {
        //check if any of the flow's headers match the search and push header to entries
        entries.push(
          ...flow.headers
            .filter((header) => {
              return header.toLowerCase().includes(search.value.toLowerCase());
            })
            .map((header) => {
              return {
                title: header,
                key: header,
                parent: flow.key,
              };
            })
        );
      }

      if (
        flow.title.toLowerCase().includes(search.value.toLowerCase()) ||
        (flow.description && flow.description.toLowerCase().includes(search.value.toLowerCase())) ||
        entries.length
      ) {
        //prepend the flow itself to the entries
        entries.unshift(flow);
      }

      return entries;
    });

    //flatten the array of arrays
    entries = _.flatten(entries)
      //remove empty arrays and undefined values
      .filter((entry) => entry);

    return entries;
  });

  const emits = defineEmits(["entry:click"]);

  const selectFlow = (flow) => {
    search.value = "";

    if (flow.parent) {
      //if the selected entry is a header, emit the parent flow
      return emits("entry:click", flow.parent, flow.key);
    }

    return emits("entry:click", flow.key);
  };
</script>
