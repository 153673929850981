export const defaultFrontendOptions = {
  units: {
    weight: "g",
  },
};

const frontendOptions = computed(() => {
  const { services } = useAuth();

  return services.value?.frontend_options || defaultFrontendOptions;
});

export default () => frontendOptions;
export const useFrontendOption = (option: string, defaultValue?: any) => {
  return computed(() => {
    return getNestedValue(frontendOptions.value, option, defaultValue);
  });
};
