<template>
  <div class="h-4">
    <CreditcardTypeVisa v-if="isVisaType"></CreditcardTypeVisa>
    <CreditcardTypeMastercard v-else></CreditcardTypeMastercard>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    type?: "visa" | "mastercard" | "visa_dk" | "visa-electron";
  }>();

  const isVisaType = computed(() => props.type?.includes("visa"));
</script>
