<template>
  <div class="flex flex-col @sm:flex-row @sm:gap-4 justify-between user-row border-b">
    <div class="flex gap-2.5 py-1.5 px-2 self-stretch items-center">
      <Avatar :entity="user" size="xs" random-color />
      <div
        class="text-body-default-heavy mr-auto flex flex-col overflow-hidden flex-shrink-0 w-full"
        :class="{
          'justify-center': !isVerified,
        }"
      >
        <template v-if="isVerified">
          <span>{{ user.name }}</span>
          <span class="text-body-sm text-quarterary text-nowrap text-ellipsis">{{ user.email }}</span>
        </template>
        <template v-else>
          <span>{{ user.email }}</span>
        </template>
      </div>
    </div>
    <div class="flex items-center gap-4 w-full @sm:w-auto">
      <BadgeChip class="text-nowrap" v-if="!isVerified" type="default">
        {{ t("settings.users.awaiting_verification") }}
      </BadgeChip>
      <div class="@sm:max-w-[200px] w-full">
        <Button
          class="w-full !justify-between"
          variant="tertiary"
          ref="trigger"
          @click="toggleDropdown"
          :active="showDropdown"
          :size="ComponentSize.sm"
        >
          {{ userRoleLabel }}
          <template #suffix>
            <Icon src="CaretDown" :size="ComponentSize.sm" />
          </template>
        </Button>
      </div>
    </div>
  </div>

  <Dropdown
    v-model:open="showDropdown"
    ref="dropdown"
    maxWidth="162px"
    :toggleElement="trigger"
    :calculatePosition="calculatePosition"
  >
    <div class="flex flex-col dropdown__content p-1.5">
      <DropdownItem
        v-for="role in roles"
        @click="() => changeRole(role.value)"
        :disabled="isCurrentRole(role.value) || loading"
      >
        {{ role.label }}
      </DropdownItem>
      <Divider class="mx-1" />
      <DropdownItem variant="destructive" @click="removeUser" :disabled="loading">
        {{ t("settings.users.remove_from_organisation") }}
      </DropdownItem>
    </div>
  </Dropdown>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      user: Record<string, any>;
      roles: { value: string; label: string }[];
    }>(),
    {}
  );
  const loading = ref(false);

  const isCurrentRole = (role: string) => {
    return props.user.roles && props.user.roles.length > 0 && props.user.roles[0].id == role;
  };

  const userRoleLabel = computed(() => {
    let role = t("settings.users.roles.no_role");

    if (!props.user.roles || props.user.roles.length === 0) {
      return role;
    }

    return (
      props.roles.find((role) => role.value == props.user.roles[0].id)?.label || t("settings.users.roles.unkown_role")
    );
  });

  const { dropdown, trigger, showDropdown, toggleDropdown, calculatePosition } = useDropdown();

  const emits = defineEmits(["fetch"]);

  const removeUser = () => {
    $confirm({
      destructive: true,
      message: t("settings.users.confirm_remove_user", {
        user: props.user.name || props.user.email,
      }),
      onConfirm: () => {
        loading.value = true;

        homeFetch(`workspace/users/${props.user.id}`, {
          method: "DELETE",
        })
          .then(() => {
            $toast.add({
              title: t("settings.users.remove_user.success", {
                user: props.user.name || props.user.email,
              }),
              icon: "users",
            });
            emits("fetch");
          })
          .finally(() => {
            toggleDropdown();
            loading.value = false;
          });
      },
    });
  };

  const changeRole = (role: string) => {
    if (role == props.user.roles[0].id) {
      return;
    }
    $confirm({
      message: t("settings.users.confirm_change_role", {
        user: props.user.name || props.user.email,
        newRole: props.roles.find((r) => r.value == role)?.label,
        oldRole: props.roles.find((r) => r.value == props.user.roles[0].id)?.label,
      }),
      onConfirm: () => {
        loading.value = true;

        homeFetch(`workspace/users/${props.user.id}`, {
          method: "PUT",
          body: {
            name: props.user.name,
            email: props.user.email,
            roles: [role],
          },
        })
          .then(() => {
            $toast.add({
              title: t("settings.users.change_role.success", {
                user: props.user.name || props.user.email,
                role: props.roles.find((r) => r.value == role)?.label,
              }),
              icon: "users",
            });
            emits("fetch");
          })
          .finally(() => {
            toggleDropdown();
            loading.value = false;
          });
      },
    });
  };

  const isVerified = computed(() => {
    return props.user.account_users && props.user.account_users.length > 0 && props.user.account_users[0].verified;
  });
</script>
