<template>
  <Transition name="fade">
    <div
      class="absolute top-0 left-0 z-50 bg-loader-overlay w-full h-dvh p-1 flex overflow-hidden"
      v-if="open"
      @click="emit('close')"
    >
      <div
        class="flex slide-in flex-col w-full relative max-w-[450px] ml-auto bg-surface-lvl-00 rounded-xl border-2 border-00 overflow-hidden"
        v-on:click.stop
        ref="modalContent"
      >
        <div class="p-5 pb-3 flex">
          <p class="text-title-subsection text-secondary">{{ title }}</p>
          <Button class="ml-auto" type="button" iconOnly variant="tertiary" @click="emit('close')">
            <Icon class="cursor-pointer" src="close" />
          </Button>
        </div>
        <slot />
        <div class="w-full surface-lvl-00" v-if="showFooter">
          <div class="bg-surface-lvl-00 w-full p-5 pt-3">
            <Button class="w-full" :disabled="!submitReady" type="button" variant="primary" @click="emit('submit')">{{
              buttonText
            }}</Button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  const t = useI18n();

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: "",
    },
    submitReady: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(["close", "submit"]);
</script>
