<template>
  <div class="flex flex-col gap-5 max-w-[540px] m-auto w-full min-h-0">
    <InputText
      v-model="name"
      :size="ComponentSize.lg"
      :label="t('name_your_checkout')"
      :placeholder="t('checkout_name_placeholder')"
    />

    <div class="flex flex-col gap-3 min-h-0">
      <label class="text-body-sm-heavy text-left text-secondary">{{ t("connect_checkout_to_webshop") }}</label>
      <div class="overflow-auto relative">
        <ListSelector
          v-if="orderedShops.length"
          v-model="shops"
          :values="orderedShops"
          valueKey="id"
          multi
          radioPosition="top"
        >
          <template #title="{ value }">
            <div class="flex flex-col">
              <div class="text-body-lg-heavy">{{ value.name }}</div>
              <div class="text-body-default text-tertiary">{{ value.shop_url || t("not_specified") }}</div>
            </div>
          </template>
        </ListSelector>
        <div class="flex h-60" v-else-if="loading">
          <Overlay>
            <div class="text-body-default-heavy text-tertiary mt-2">{{ t("loading_webshops") }}</div>
          </Overlay>
        </div>

        <div class="flex flex-col items-center justify-center p-4" v-else>
          <img class="mb-4" src="~/assets/illustrations/emptyViews/ec-no-result.svg" alt="No webshops illustration" />
          <p class="text-body-default text-tertiary text-center">{{ t("checkout_no_webshops") }}</p>
        </div>
      </div>
    </div>

    <Button v-if="mode !== 'edit'" variant="tertiary" :disabled="!canSubmit">{{ t("connect_checkout_later") }}</Button>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import type { IWebshop } from "../../../types";

  const { t } = useI18n();
  const props = defineProps<{
    mode: string;
    canSubmit: boolean;
  }>();

  const loading = ref(false);
  const { value: shops, setValue: setShops } = useField(() => "shops", undefined, {
    syncVModel: true,
  });

  const { value: name } = useField(() => "name", undefined, {
    syncVModel: true,
  });

  const possibleShops = ref<IWebshop[]>([]);

  const orderedShops = computed(() => {
    const selectedShops = possibleShops.value
      .filter((s) => shops.value?.find((ss) => ss === s.id))
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    const unselectedShops = possibleShops.value
      .filter((s) => !shops.value?.find((ss) => ss === s.id))
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

    return [...selectedShops, ...unselectedShops];
  });

  onMounted(() => {
    getWebshops();
  });

  const getWebshops = () => {
    loading.value = true;
    return homeFetch("workspace/webshops")
      .then((response) => {
        if (response?.data) {
          possibleShops.value = response.data;
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };
</script>
