<template>
  <Modal
    class="shadow-lvl-05"
    v-model:open="open"
    :clickToClose="false"
    maxWidth="1020px"
    :contentPadding="false"
    :center="true"
    anchor="top"
    ref="modal"
    :overlay="false"
    @closed="() => emit('close')"
  >
    <div class="w-[1020px] max-w-full h-[756px] max-h-full" v-if="!portalCreated">
      <FormWizard
        class="w-full h-full flex flex-col [&_.form-step]:mx-auto"
        :loading="loading"
        :validation-schema="validationSchema"
        @submit="onSubmit"
        showProgress
        :name="t('create_return_portal')"
        :stepNames="stepNames"
        headerType="create"
        footerType="create"
        :submitButtonText="t('create')"
        ref="wizard"
        @exit="(emit('close'), (portalCreated = false))"
        hide-title
        @next="(val) => handleNext(val)"
      >
        <template #step-settings="{ schema }">
          <Settings
            :information="information"
            :schema="schema"
            :values="values"
            :legacy="legacy"
            @updateAddress="(val) => setAddressValue(val)"
            @updateAltAddress="(val) => setAltAddressValue(val)"
          ></Settings>
        </template>
        <template #step-legacy="{ schema }">
          <Legacy :schema="schema" :values="values"></Legacy>
        </template>
        <template #step-causes="{ schema }">
          <Causes :schema="schema" :values="values"></Causes>
        </template>
        <template #step-look="{ schema }">
          <Look :schema="schema" :values="values" :information="information"></Look>
        </template>
        <template v-if="legacy" #step-look="{ schema }">
          <Look :schema="schema" :values="values" :information="information"></Look>
        </template>
        <template v-if="!legacy" #step-rules="{ schema }">
          <Rules :checkouts="checkouts" :schema="schema" :values="values" :information="information"></Rules>
        </template>
      </FormWizard>
    </div>
    <div class="w-[1020px] max-w-full h-[756px] max-h-full flex flex-col items-center" v-if="portalCreated">
      <div class="m-auto max-w-[540px]">
        <div class="relative overflow-hidden rounded-full mx-auto h-[100px] w-[200px] bg-utility-kiwi mb-4">
          <svg width="200" height="101" viewBox="0 0 200 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M-136.967 38.1815C-149.752 45.5628 -154.132 61.9106 -146.751 74.6953C-139.37 87.4801 -123.022 91.8605 -110.237 84.4792L32.553 2.03922C45.3378 -5.34206 49.7182 -21.6899 42.3369 -34.4746C34.9556 -47.2594 18.6078 -51.6398 5.82302 -44.2585L-136.967 38.1815Z"
              stroke="#ABD9C1"
              style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
              stroke-width="1.5"
            />
            <path
              d="M-162.116 132.028C-174.9 139.409 -179.281 155.757 -171.9 168.542C-164.518 181.326 -148.17 185.707 -135.386 178.325L7.40457 95.8854C20.1893 88.5041 24.5697 72.1563 17.1884 59.3715C9.80715 46.5868 -6.54066 42.2064 -19.3254 49.5877L-162.116 132.028Z"
              stroke="#ABD9C1"
              style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
              stroke-width="1.5"
            />
            <path
              d="M30.3861 51.7514C37.7674 64.5362 54.1152 68.9166 66.8999 61.5353C79.6847 54.154 84.0651 37.8062 76.6838 25.0214C69.3025 12.2367 52.9547 7.85629 40.1699 15.2376C27.3852 22.6189 23.0048 38.9667 30.3861 51.7514Z"
              stroke="#ABD9C1"
              style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
              stroke-width="1.5"
            />
            <path
              d="M99.6656 -19.1125C86.8808 -11.7312 82.5004 4.61665 89.8817 17.4014C97.263 30.1862 113.611 34.5666 126.396 27.1853L269.186 -55.2547C281.971 -62.636 286.351 -78.9838 278.97 -91.7686C271.588 -104.553 255.241 -108.934 242.456 -101.552L99.6656 -19.1125Z"
              stroke="#ABD9C1"
              style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
              stroke-width="1.5"
            />
            <path
              d="M62.6226 81.6034C49.8378 88.9846 45.4575 105.332 52.8387 118.117C60.22 130.902 76.5678 135.282 89.3526 127.901L232.143 45.4611C244.928 38.0798 249.308 21.732 241.927 8.94723C234.545 -3.83752 218.198 -8.21791 205.413 -0.836628L62.6226 81.6034Z"
              stroke="#ABD9C1"
              style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
              stroke-width="1.5"
            />
            <path
              d="M168.369 99.8797C155.584 107.261 151.204 123.609 158.585 136.394C165.966 149.178 182.314 153.559 195.099 146.177L266.494 104.957C279.279 97.5762 283.659 81.2284 276.278 68.4436C268.896 55.6588 252.549 51.2785 239.764 58.6597L168.369 99.8797Z"
              stroke="#ABD9C1"
              style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
              stroke-width="1.5"
            />
          </svg>
          <Icon
            class="absolute top-[35.5px] left-[84px] text-utility-kiwi-darkest"
            src="check"
            :size="ComponentSize.xl4"
          ></Icon>
        </div>
        <p class="w-full text-center text-title-section text-secondary mb-2">
          {{ t("return_portal_name", { name: createdName }) }}
        </p>
        <!--<p class="text-body-lg text-tertiary mb-4">{{ t("webshop_is_created_desc") }}</p>-->
        <Button class="w-full mt-4" @click="(emit('close'), (portalCreated = false))" type="button" variant="default">{{
          t("close")
        }}</Button>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import Settings from "./partials/Settings.vue";
  import Causes from "./partials/Causes.vue";
  import Look from "./partials/Look.vue";
  import Rules from "./partials/Rules.vue";
  import Legacy from "./partials/Legacy.vue";
  import { ComponentSize } from "~/types/global";
  const open = defineModel("open", { default: false });
  const loading = ref(false);
  const legacy = ref(false);
  const portalCreated = ref(false);
  const yup = useYup();
  const { t } = useI18n();
  const emit = defineEmits(["close", "update"]);
  const createdName = ref("");
  const props = defineProps<{
    portal: Object | null;
    information: any;
  }>();
  const { checkoutsForSelect } = useCheckouts();
  const wizard = ref(null);
  const values = computed(() => wizard.value?.values);
  const checkouts = ref([]);
  onMounted(() => {
    checkoutsForSelect().then((checkoutsSelect) => {
      checkouts.value = checkoutsSelect;
    });
  });
  const validationSchema = computed(() => {
    let schema: Record<string, any>[] = [
      yup
        .object({
          name: yup.string().required(),
          logo: yup.string().optional().default(""),
          address: yup.string().default("SHIPPING"),
          alternative_address: yup.boolean().default(false),
          settings: yup.object({
            alternative_address: yup.object({
              attention: yup.string().nullable(),
              city: yup.string().nullable(),
              company: yup.string().nullable(),
              country: yup.string().nullable(),
              notification_email: yup.string().nullable(),
              street: yup.string().nullable(),
              zip_code: yup.string().nullable(),
              telephone: yup.string().nullable(),
            }),
          }),
        })
        .meta({ continueButtonText: t("continue"), title: t("portal_settings"), key: "settings" }),
      yup
        .object({
          price: yup.string().required().default("0"),
          product: yup.string().required().default(""),
          country: yup.string().required().default("DK"),
          currency: yup.string().required().default("DKK"),
        })
        .meta({ continueButtonText: t("continue"), title: t("set_return_portal"), key: "legacy", autoskip: true }),
      yup
        .object({
          settings: yup.object({
            causes: yup.array().default([]),
          }),
        })
        .meta({ continueButtonText: t("continue"), title: t("return_causes"), key: "causes" }),
      yup
        .object({
          title: yup.string().required().default("Returportal"),
          description: yup
            .string()
            .required()
            .default(
              "<p>Her kan du ganske let købe en pakkelabel til brug ved returnering af varer, som er købt hos Homerunner. <br> Returfragten koster 0 kr. og du kan betale med kreditkort. Når betalingen er gennemført, vil du få vist kvitteringsside, og her kan du hente og udskrive din returlabel. Returlabelen vil også blive sendt som vedhæftet pdf til den e-mailadresse, som du har oplyst. Du kan udskrive returlabelen på en almindelig A4-printer. <br> Herefter sætter du labelen forsvarligt fast på pakken uden at dække tekst og stregkode. Så er du klar til at aflevere pakken til Bring på et posthus, i en postbutik eller en pakkeboks.</p>"
            ),
          settings: yup.object({
            order_gate_title: yup.string().default(null).nullable(),
            order_gate_description: yup.string().default(null).nullable(),
            order_gate_limit: yup.number().default(100),
          }),
        })
        .meta({ continueButtonText: t("continue"), title: t("look"), key: "look" }),
      yup
        .object({
          settings: yup.object({ checkout_id: yup.number().required().default(null) }),
        })
        .meta({ title: t("checkout"), key: "rules", continueButton: t("continue"), autoskip: false }),
    ];

    return schema;
  });

  const handleNext = async (val: number) => {
    if (val === 4 && legacy.value) {
      onSubmit(values.value);
    }
  };

  watch(
    () => open.value,
    (newValue) => {
      legacy.value = false;
      validationSchema.value[1].spec.meta.autoskip = true;
      validationSchema.value[3].spec.meta.continueButtonText = t("continue");
      if (newValue) {
        if (props.portal) {
          if (!props.portal.settings.smartcheckout) {
            legacy.value = true;
            validationSchema.value[1].spec.meta.autoskip = false;
            validationSchema.value[3].spec.meta.continueButtonText = t("update");
          }
          setTimeout(() => {
            if (!props.portal.settings.smartcheckout) {
              wizard.value?.setValues({
                price: props.portal.price,
                product: props.portal.product,
                country: props.portal.country,
                currency: props.portal.currency,
              });
            }
            wizard.value?.setValues({
              name: props.portal.name,
              logo: props.portal.logo || "",
              address: props.portal.settings.alternative_address.city ? "ALTERNATIVE" : "SHIPPING",
              alternative_address: props.portal.settings.alternative_address.city ? true : false,
              title: props.portal.title,
              description: props.portal.description,
              settings: {
                checkout_id: props.portal.settings?.checkout?.id || null,
                order_gate: props.portal.settings.order_gate,
                private: props.portal.settings.private,
                public: props.portal.settings.public,
                order_number: props.portal.settings.order_number,
                causes: props.portal.settings.causes,
                css_style: props.portal.settings.css_style,
                smartcheckout: props.portal.settings.smartcheckout,
                reference_key: props.portal.settings.reference_key,
                order_gate_title: props.portal.settings.order_gate_title,
                order_gate_description: props.portal.settings.order_gate_description,
              },
            });
            if (props.portal.settings?.alternative_address?.city) {
              wizard.value?.setValues({
                settings: {
                  alternative_address: {
                    attention: props.portal.settings.alternative_address.attention,
                    city: props.portal.settings.alternative_address.city,
                    company: props.portal.settings.alternative_address.company,
                    country: props.portal.settings.alternative_address.country,
                    notification_email: props.portal.settings.alternative_address.notification_email,
                    street: props.portal.settings.alternative_address.street,
                    zip_code: props.portal.settings.alternative_address.zip_code,
                    telephone: props.portal.settings.alternative_address.telephone,
                  },
                },
              });
            }
          }, 50);
        }
      }
    }
  );

  const stepNames = computed(() => validationSchema.value.map((schema) => schema.describe().meta?.title));

  const setAddressValue = (val: String) => {
    wizard.value?.setValues({ address: val });
  };

  const setAltAddressValue = (val: boolean) => {
    wizard.value?.setValues({ alternative_address: val });
  };

  const onSubmit = (formData) => {
    let data = { ...formData };
    data.customer_url = null;
    if (!data.settings.causes) {
      data.settings.causes = [];
    }
    if (props.portal) {
      data.uid = props.portal.uid;
      return homeFetch(`workspace/return-portals/`, {
        method: "PUT",
        body: {
          ...data,
        },
      }).then((response) => {
        $toast.add({
          title: t("portal_updated"),
        });
        emit("update");
        emit("close");
      });
    }
    createdName.value = data.name;
    data.settings.order_number = false;
    data.settings.private = false;
    data.settings.smartcheckout = true;
    data.settings.public = false;
    data.settings.private = false;
    data.settings.order_gate = true;
    data.country = "DK";
    data.price = 0;
    data.product = "gls_business_return_labelless";
    loading.value = true;
    return homeFetch("workspace/return-portals", {
      method: "POST",
      body: {
        ...data,
      },
    })
      .then((response) => {
        $toast.add({
          title: t("portal_created"),
        });
        portalCreated.value = true;
        emit("update");
      })
      .finally(() => {
        loading.value = false;
      });
  };
</script>
