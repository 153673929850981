export default () => {
  const yup = useYup();
  // This function will inject the validation fields into the schema takes yup objects
  const injectValidationFields = (fields: object, schema: Array<[]>, stepIndex: number, objectKey: string) => {
    if (Object.keys(fields).length == 0) {
      return;
    }
    Object.entries(fields).forEach((entry) => {
      const [key, value] = entry;
      schema[stepIndex].fields[objectKey].fields[key] = value;
      schema[stepIndex].fields[objectKey]._nodes.push(key);
    });
  };
  // translateFieldsToValidation will take the fields and return yup objects
  const translateFieldsToValidation = (service: object, add: boolean) => {
    let fields = {};
    //get service key
    let service_key = service?.carrier_key;
    //create service key object
    fields[service_key] = yup.object();
    if (!add) {
      fields[service_key]._nodes = [];
    } else {
      // loop fields and add to service key object
      service.fields.forEach((field) => {
        if (field.type == "multi_select" && field.validation_rules.type == "required") {
          fields[service_key].fields.values = yup.array().min(2).required();
        }
        if (field.validation_rules.type == "required" && field.type !== "multi_select") {
          fields[service_key].fields[field.key] = yup.string().required();
          fields[service_key]._nodes.push(field.key);
        }
        if (field.validation_rules.type == "if_empty") {
          let field_array = [];
          field.validation_rules.fields.forEach((field) => {
            field_array.push(`services.${service_key}.${field}`);
          });
          if (field.type == "number") {
            fields[service_key].fields[field.key] = yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .requiredIf(field_array);
          }
          if (field.type == "string") {
            fields[service_key].fields[field.key] = yup.string().requiredIf(field_array);
          }
          fields[service_key]._nodes.push(field.key);
        }
      });
    }
    return fields;
  };
  return {
    injectValidationFields,
    translateFieldsToValidation,
  };
};
