<template>
  <div class="w-full">
    <InputText
      class="w-full"
      v-if="type == 'text'"
      :error="attribute.error"
      :model-value="attribute.value"
      @update:modelValue="(val) => emit('emitValue', val)"
    />
    <InputNumber
      class="w-full"
      v-if="type == 'number'"
      :error="attribute.error"
      :model-value="attribute.value"
      @update:modelValue="(val) => emit('emitValue', val)"
    />
    <InputParagraph
      v-if="type == 'textarea'"
      :error="attribute.error"
      :model-value="attribute.value"
      @update:modelValue="(val) => emit('emitValue', val)"
    ></InputParagraph>
    <FileUpload
      v-if="type == 'file' || type == 'dor' || type == 'file_with_pdf'"
      :error="attribute.error"
      :model-value="attribute.value"
      @update:modelValue="(val) => emit('emitValue', val)"
      :index="index"
      :shipmentId="shipmentId"
      s3
      :message="t('pdf_size')"
    ></FileUpload>
    <FileUpload
      v-if="type == 'file[]'"
      :error="attribute.error"
      :model-value="attribute.value"
      @update:modelValue="(val) => emit('emitValue', val)"
      :index="index"
      imagePreview
      multiple
      :shipmentId="shipmentId"
      s3
    ></FileUpload>
    <DropdownSelect
      class="w-full"
      v-model="attribute.value"
      v-if="type == 'select'"
      :error="attribute.error"
      @update:modelValue="(val) => emit('emitValue', val)"
      :options="attribute.options"
      :triggerText="t('select')"
      triggerType="button"
    />
    <TickerCheckbox
      class="mt-1"
      v-if="type == 'boolean'"
      v-model="attribute.value"
      @update:modelValue="(val) => emit('emitValue', val)"
      >{{ attribute.value ? t("yes") : t("no") }}</TickerCheckbox
    >
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const emit = defineEmits(["emitValue"]);

  const props = defineProps<{
    type: string;
    name?: string;
    shipmentId: number;
    index: number;
    attribute: any;
  }>();
</script>
