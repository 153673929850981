<template>
  <Input
    v-bind="$attrs"
    tag="input"
    :model-value="inputValue"
    @update:model-value="(val) => setValue(val, false)"
    @blur="(evt) => handleBlur(evt, true)"
    :error="error"
    :size="size"
    ref="input"
    @click="() => emit('click')"
    suffix=".homerunner.com"
    :placeholder="formattedPlaceholder"
    :modifiers="['subdomain']"
  >
    <template v-for="(slot, index) of slotNames" :key="index" #[slot]>
      <slot :name="slot" />
    </template>
  </Input>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { ref } from "vue";
  import { ComponentSize } from "~/types/global";

  const slots = useSlots();
  const slotNames = Object.keys(slots) as "default"[];

  const modelValue = defineModel();

  const props = withDefaults(
    defineProps<{
      size?: InputSize;
      disabled?: boolean;
      selected?: boolean;
      active?: boolean;
      name?: string;
      error?: string;
      placeholder?: string;
    }>(),
    {
      size: ComponentSize.default,
      disabled: false,
      selected: false,
      active: false,
      name: "",
    }
  );

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    setValue,
    meta,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const error = computed(() => {
    return props.error || errorMessage.value;
  });

  const input = ref<HTMLElement | null>(null);

  const el = computed(() => {
    return input.value?.input;
  });

  const formattedPlaceholder = computed(() => {
    if (!props.placeholder) return "";

    //format that placeholder to be a valid subdomain name
    return companyNameSanitizer(props.placeholder);
  });

  const emit = defineEmits(["click"]);

  defineExpose({
    el,
  });
</script>
