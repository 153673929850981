<template>
  <div class="w-full flex flex-col gap-3">
    <div class="w-full flex gap-3" v-for="(object, index) in objects">
      <div class="text-left" v-for="field in service.fields">
        <InputText
          class="w-full"
          v-if="field.type == 'text'"
          v-model="objects[index][field.key]"
          type="text"
          :label="field.label"
        />
        <InputText
          class="w-full"
          v-if="field.type == 'number'"
          v-model="objects[index][field.key]"
          type="number"
          :label="field.label"
        />
        <DropdownSelect
          v-if="field.type == 'select'"
          v-model="objects[index][field.key]"
          :options="field.options"
          :label="field.label"
        />
      </div>
      <Button
        class="mt-[22.5px]"
        v-if="index !== 0"
        @click="removeField(index)"
        type="button"
        variant="tertiary"
        iconOnly
        ><Icon src="close"></Icon
      ></Button>
    </div>
    <Button class="mr-auto" type="button" variant="primary-link" @click="addField()">+ {{ t("add_field") }}</Button>
  </div>
</template>

<script setup lang="ts">
  import { defineProps, defineEmits, ref, onMounted } from "vue";
  const { t } = useI18n();
  const props = defineProps<{
    service: any;
  }>();

  onMounted(() => {
    if (!objects.value) {
      objects.value = [];
      let fieldArray = {};
      props.service.fields.forEach((field: any) => {
        fieldArray[field.key] = "";
      });
      objects.value.push(fieldArray);
    }
  });

  const addField = () => {
    let fieldArray = {};
    props.service.fields.forEach((field: any) => {
      fieldArray[field.key] = "";
    });
    objects.value.push(fieldArray);
  };

  const removeField = (index: number) => {
    objects.value.splice(index, 1);
  };

  const { value: objects } = useField(() => `services.${props.service.carrier_key}`, undefined, {
    syncVModel: false,
    controlled: true,
  });
</script>
