<template>
  <Input
    :label="label || t('search_by_address')"
    :size="size"
    :style="{
      '--geoapify-autocomplete-dropdown-width': dropdownWidth,
      '--geoapify-autocomplete-dropdown-left-position': `-${inputLeftPadding}px`,
      '--geoapify-autocomplete-dropdown-right-position': `-${inputRightPadding}px`,
      '--geoapify-autocomplete-dropdown-top-position': `${inputHeight}px`,
    }"
    ref="input"
    :model-value="inputValue"
    @update:model-value="(val) => setValue(val, false)"
    @blur="(evt) => handleBlur(evt, true)"
    @click="() => emits('click')"
    :error="error"
    :name="name"
  >
    <div class="geoapify-autocomplete-container" ref="autocomplete"></div>
  </Input>
</template>

<script setup lang="ts">
  import { GeocoderAutocomplete } from "@geoapify/geocoder-autocomplete";
  import { ComponentSize } from "~/types/global";

  const props = withDefaults(
    defineProps<{
      size?: InputSize;
      dropdownWidth?: string;
      name?: string;
      label?: string;
      placeholder?: string;
      error?: string;
      country?: string;
    }>(),
    {
      size: ComponentSize.default,
      name: "",
      country: "dk",
    }
  );

  const modelValue = defineModel();

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    setValue,
    meta,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    validateOnMount: false,
    controlled: !!props.name,
  });

  const { t } = useI18n();
  const autocompleteInput = useTemplateRef("autocomplete");
  const input = useTemplateRef("input");

  const inputLeftPadding = computed(() => {
    if (!input.value) return 0;

    const inputElm = input.value?.wrapper as HTMLElement;
    return inputElm ? parseInt(window.getComputedStyle(inputElm).paddingLeft) : 0;
  });

  const inputRightPadding = computed(() => {
    if (!input.value) return 0;

    const inputElm = input.value?.wrapper as HTMLElement;
    return inputElm ? parseInt(window.getComputedStyle(inputElm).paddingRight) : 0;
  });

  const inputHeight = computed(() => {
    if (!input.value) return 0;
    const marginBottom = 4;
    const outlineWidth = 2;
    const inputElm = input.value?.wrapper as HTMLElement;
    const borderWidth = inputElm ? parseInt(window.getComputedStyle(inputElm).borderWidth) : 0;
    const borders = borderWidth * 2;

    const topPadding = inputElm ? parseInt(window.getComputedStyle(inputElm).paddingTop) : 0;
    return inputElm ? inputElm.offsetHeight - topPadding + borders + marginBottom + outlineWidth : 0;
  });

  watchEffect(() => {
    if (autocompleteInput.value) {
      const autocomplete = new GeocoderAutocomplete(autocompleteInput.value, "", {
        debounceDelay: 100,
        limit: 25,
        skipIcons: true,
        placeholder: props.placeholder || t("search_by_address"),
      });

      autocomplete.setGeocoderUrl("/api/geocode");
      autocomplete.on("select", (feature: GeoFeature) => {
        nextTick(() => {
          if (!feature?.properties?.address_line1) return;
          autocomplete.setValue(feature.properties.address_line1);
        });
        emits("select", feature?.properties);
      });
    }
  });

  const emits = defineEmits(["select", "suggestions", "click"]);
</script>
