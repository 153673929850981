export default defineNuxtRouteMiddleware(async (to, from) => {
  const { updateCapabilities } = useAuth();
  if (to.meta.layout == "auth") {
    return;
  }

  const nuxtApp = useNuxtApp();

  if (nuxtApp.isHydrating && nuxtApp.payload.serverRendered && !to.name?.toString().startsWith("auth-")) {
    await updateCapabilities();
  }
});
