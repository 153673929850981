<template>
  <div>
    <p class="mb-1 text-body-sm-heavy text-secondary" v-if="label">
      {{ label }}
    </p>
    <div class="flex cursor-pointer items-stretch rounded-xl shadow-lvl-01">
      <div
        class="group flex flex-1 items-center justify-center gap-2 border border-01 px-5 transition-all duration-300"
        v-for="(button, index) in buttons"
        :class="{
          'rounded-l-xl': index === 0,
          '!border-l-0': index !== 0,
          'rounded-r-xl': index === buttons.length - 1,
          'h-11 p-3': size === ComponentSize.lg,
          'h-9 p-2.5': size === ComponentSize.default,
          'h-7 p-1.5': size === ComponentSize.sm,
          '!bg-00': isSelectedButton(button),
        }"
        @click="() => selectValue(button.value)"
      >
        <Transition name="checkmark-show">
          <div class="flex items-center overflow-hidden" v-show="isSelectedButton(button)">
            <Icon class="text-inherit" src="check" />
          </div>
        </Transition>
        <p class="text-body-default-heavy text-secondary transition-all duration-300 group-hover:text-primary">
          {{ button.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  export interface IButton {
    iconOnly?: string;
    text?: string;
    prefix?: string;
    suffix?: string;
    variant?: string;
    value?: string;
  }

  const props = withDefaults(
    defineProps<{
      buttons: any;
      size?: ButtonSize;
      name?: string;
      label?: string;
    }>(),
    {
      name: "",
      size: ComponentSize.default,
    }
  );

  const modelValue = defineModel();

  const isSelectedButton = (button: { value: any }): boolean => {
    return button.value === selectedValue.value;
  };

  const selectValue = (value: any) => {
    selectedValue.value = value;
  };

  const { value: selectedValue } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    validateOnMount: false,
    controlled: !!props.name,
  });
</script>
