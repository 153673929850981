<template>
  <header class="flex items-center gap-2.5">
    <Avatar
      size="xs"
      :entity="
        data
          ? {
              name: data.shipment.carrier,
              image: getCarrierLogoUrl(data.shipment.carrier),
            }
          : {}
      "
    />
    <div class="flex flex-col">
      <p class="text-body-lg font-medium text-secondary">
        {{ `#${data?.id}` || "-" }}
      </p>
      <p class="text-body-default text-tertiary">
        {{ t("created") }}
        {{ formatDate(data?.created_at, DateTime.DATE_MED) }}
      </p>
    </div>
  </header>
  <Badge>{{ t(data?.status.name) }}</Badge>
  <div
    class="grid w-full grid-cols-[max-content_1fr] gap-x-2 gap-y-3.5 text-body-default [&>*:nth-child(even)]:text-secondary [&>*:nth-child(odd)]:odd:break-words [&>*:nth-child(odd)]:odd:text-quarterary"
  >
    <p class="self-center">{{ t("type") }}</p>
    <Badge class="truncate">
      {{ t(data?.type.name) }}
    </Badge>
    <p class="self-center">{{ t("created_by") }}</p>
    <div class="flex items-center gap-2" v-if="data?.owner">
      <Avatar
        size="2xs"
        :entity="
          data
            ? {
                name: data.owner,
              }
            : {}
        "
      />
      {{ data?.owner }}
    </div>
    <span v-else>-</span>
    <p class="self-center">{{ t("carrier") }}</p>
    <div class="flex items-center gap-2">
      <Avatar
        size="2xs"
        :entity="
          data
            ? {
                name: data.shipment.carrier,
                image: getCarrierLogoUrl(data.shipment.carrier),
              }
            : {}
        "
      />
      {{ data?.shipment.carrier_name }}
    </div>
    <p>{{ t("package_number") }}</p>
    <p>{{ data?.shipment.package_number || "-" }}</p>
    <p>{{ t("weight") }}</p>
    <p>{{ useFormatWeight(data?.shipment.weight || 0) }}</p>
    <p>{{ t("receiver") }}</p>
    <p class="break-words">
      {{ data?.shipment.receiver.attention }}<br v-show="data?.shipment.receiver.attention" />
      {{ data?.shipment.receiver.name }}<br />
      {{ data?.shipment.receiver.street1 }} <br />
      {{ data?.shipment.receiver.zip_code }}
      {{ data?.shipment.receiver.city }} <br />
      {{ data?.shipment.receiver.country }}
    </p>
    <p>{{ t("pallet_number") }}</p>
    <p>{{ palletNumber }}</p>
  </div>
</template>

<script lang="ts" setup>
  import { DateTime } from "luxon";
  import type { ITicketSimple } from "~/types/ticket";

  defineOptions({
    name: "GlobalSearchInfoTicket",
  });

  const props = defineProps<{
    data: ITicketSimple;
  }>();

  const { t } = useI18n();

  const palletNumber = computed(() => {
    if (!props.data.shipment.meta.pallet_number || !props.data.shipment.meta.pallet_number.length) return "-";
    return props.data.shipment.meta.pallet_number[props.data.shipment.meta.pallet_number.length - 1];
  });
</script>
