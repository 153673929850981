<template>
  <Modal
    class="shadow-lvl-05"
    v-model:open="modalOpen"
    :clickToClose="false"
    maxWidth="1020px"
    :contentPadding="false"
    :overlay="false"
    :center="true"
    anchor="top"
    ref="modal"
    @closed="() => emit('close')"
  >
    <div class="w-[1020px] max-w-full h-[756px] max-h-full">
      <FormWizard
        class="w-full h-full flex flex-col"
        v-if="!shopCreated"
        :validation-schema="validationSchema"
        @next="(val) => handleNext(val)"
        @submit="onSubmit"
        showProgress
        :name="t('set_up_entity', { entity: t('webshop').toLocaleLowerCase() })"
        :stepNames="[
          t('name_webshop'),
          t('select_shopsystem'),
          t('select shopify_plan'),
          t('other'),
          t('link_account'),
          t('link_checkout'),
        ]"
        headerType="create"
        footerType="create"
        :submitButtonText="t('create')"
        ref="wizard"
        @exit="emit('close')"
      >
        <FormStep>
          <div class="w-full mx-auto h-full max-w-[540px] flex flex-col gap-5 items-center justify-center">
            <p class="text-secondary text-title-section text-center">{{ t("create_webshop_titles.1") }}</p>
            <InputText
              class="w-full"
              id="name"
              :size="ComponentSize.lg"
              name="name"
              :placeholder="t('webshop_name_example', { name: account?.account?.name })"
            ></InputText>
          </div>
        </FormStep>
        <FormStep>
          <div class="w-full mx-auto h-full max-w-[540px] flex flex-col gap-5 items-center justify-center">
            <p class="text-secondary text-title-section text-center">
              {{ t("create_webshop_titles.2", { name: values.name }) }}
            </p>
            <div class="grid grid-cols-2 w-full gap-3">
              <Button
                class="w-full"
                v-for="system in shopSystems"
                type="button"
                activeCheckmark
                :active="values?.platform == system.value"
                @click="selectSystem(system.value)"
                :size="ComponentSize.lg"
              >
                <Icon
                  class="text-[#7AB55C]"
                  v-if="system.value == 'shopify'"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Shopify"
                />
                <Icon
                  class="text-[#3E9FFF]"
                  v-else-if="system.value == 'shopware'"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Shopware"
                />
                <Icon
                  class="text-[#E56624]"
                  v-else-if="system.value == 'magento-1' || system.value == 'magento-2'"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Magento"
                />
                <Icon
                  class="text-[#92588B]"
                  v-else-if="system.value == 'woocommerce'"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Woo"
                />
                <Icon
                  class="text-foreground-brand"
                  v-else-if="system.value == 'custom'"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Custom"
                />
                <p>{{ system.label }}</p>
              </Button>
            </div>
          </div>
        </FormStep>
        <FormStep>
          <div class="w-full mx-auto h-full max-w-[540px] flex flex-col gap-4 items-center justify-center">
            <p class="text-secondary text-title-section text-center mb-1">
              {{ t("create_webshop_titles.3", { name: values.name }) }}
            </p>
            <p class="text-center text-quarterary text-body-default-heavy">{{ t("setup_automatic_with") }}</p>
            <div class="flex flex-col gap-3 w-full">
              <Button
                class="w-full"
                v-for="plan in shopifyPlans"
                type="button"
                activeCheckmark
                :active="values?.shopify_plan == plan.value"
                @click="selectPlan(plan.value)"
                :size="ComponentSize.lg"
              >
                <Icon class="text-[#7AB55C]" :size="ComponentSize.lg" src="custom/shopIcons/Shopify" />
                <p>{{ plan.label }}</p>
              </Button>
            </div>
            <p class="text-center text-quarterary text-body-default-heavy">{{ t("payment_frequency") }}</p>
            <div class="w-full grid grid-cols-2 gap-3">
              <Button
                class="w-full"
                v-for="interval in paymentIntervals"
                type="button"
                activeCheckmark
                :active="values?.payment_interval == interval.value"
                @click="selectInterval(interval.value)"
                :size="ComponentSize.lg"
              >
                <p>{{ interval.label }}</p>
              </Button>
            </div>
          </div>
        </FormStep>
        <FormStep>
          <div class="w-full mx-auto h-full max-w-[540px] flex flex-col gap-4 items-center justify-center">
            <p class="text-secondary text-title-section text-center mb-5">{{ t("create_webshop_titles.6") }}</p>
            <InputText
              class="w-full"
              id="shop_url"
              :size="ComponentSize.lg"
              name="shop_url"
              :label="t('url')"
              :placeholder="t('shop_url_example')"
            ></InputText>
          </div>
        </FormStep>
        <FormStep>
          <div class="w-full mx-auto h-full max-w-[540px] flex flex-col gap-5 items-center justify-center">
            <p class="text-secondary text-title-section text-center mb-1">
              {{ t("create_webshop_titles.5", { system: _.capitalize(values.platform) }) }}
            </p>
            <div class="w-full p-3 flex gap-5 bg-00 rounded-lg">
              <Badge class="h-7 w-7 min-w-7 flex justify-center items-center" type="bold">1</Badge>
              <div class="w-full">
                <p class="text-primary text-body-lg-heavy">{{ step1Title }}</p>
                <p class="text-secondary text-body-default mb-2">{{ step1Desc }}</p>
                <a :href="returnLinkToPlugin" target="_blank" rel="noopener noreferrer">
                  <Button type="button" variant="default">
                    <p>{{ t("open_item_in_a_new_tab", { item: _.capitalize(values.platform) }) }}</p>
                    <Icon src="arrow_up_right" />
                  </Button>
                </a>
              </div>
            </div>
            <div class="w-full p-3 flex gap-5 bg-00 rounded-lg">
              <Badge class="h-7 w-7 min-w-7 flex justify-center items-center" type="bold">2</Badge>
              <div class="w-full">
                <p class="text-primary text-body-lg-heavy">
                  {{ t("setup_homerunner_in_shop_system", { system: _.capitalize(values.platform) }) }}
                </p>
                <div
                  v-if="
                    values.shopify_plan == 'basic' ||
                    (values.shopify_plan == 'shopify' && values.payment_interval == 'monthly')
                  "
                >
                  <p class="text-secondary text-body-default mb-2">{{ t("shopify plan unsupported") }}</p>
                  <a
                    href="https://help.homerunner.com/categories/it/shop-integrationer/shopify#fd60d75b-b225-4498-af96-e07881cc4b75"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button type="button" variant="default">
                      <p>{{ t("see_guide") }}</p>
                      <Icon src="arrow_up_right" />
                    </Button>
                  </a>
                </div>
                <div>
                  <p
                    class="text-secondary text-body-default"
                    v-if="
                      values.platform == 'shopify' &&
                      values.shopify_plan !== 'basic' &&
                      values.shopify_plan !== 'shopify' &&
                      values.payment_interval !== 'monthly'
                    "
                  >
                    {{ t("setup_homerunner_in_shop_system_desc", { system: _.capitalize(values.platform) }) }}
                  </p>
                  <div
                    class="w-full flex gap-2 cursor-pointer mb-2 mt-2"
                    @click="copyToClipboard(values.activation_code || '')"
                  >
                    <p class="text-primary text-title-section">{{ values.activation_code }}</p>
                    <Icon class="mt-1.5" src="copy" :size="ComponentSize.lg" />
                  </div>
                  <a
                    class="mb-2"
                    v-if="
                      values.platform == 'shopify' &&
                      values.shopify_plan !== 'basic' &&
                      values.shopify_plan !== 'shopify' &&
                      values.payment_interval !== 'monthly'
                    "
                    href="https://help.homerunner.com/categories/it/shop-integrationer/shopify"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button class="mb-2" type="button" variant="default">
                      <p>{{ t("see_guide") }}</p>
                      <Icon src="arrow_up_right" />
                    </Button>
                  </a>
                  <a
                    class="mb-2"
                    v-if="values.platform == 'woocommerce'"
                    href="https://help.homerunner.com/categories/it/shop-integrationer/woocommerce"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button class="mb-2" type="button" variant="default">
                      <p>{{ t("see_guide") }}</p>
                      <Icon src="arrow_up_right" />
                    </Button>
                  </a>
                  <p class="text-quarterary text-body-sm">
                    {{
                      t("activation_code_desc_with_time", {
                        time: DateTime.fromISO(values?.activation_expires_at).toFormat("dd/MM/y HH:mm"),
                      })
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </FormStep>
        <FormStep>
          <div class="w-full mx-auto h-full max-w-[540px] flex flex-col gap-3 items-center justify-center">
            <EmptyView
              v-if="checkouts.length == 0"
              :title="t('create_webshop_titles.4')"
              :description="t('no_checkouts_found')"
            >
              <template #illustration>
                <img src="~/assets/illustrations/emptyViews/ec-no-result.svg" alt="No return portals illustration" />
              </template>
            </EmptyView>
            <p class="text-secondary text-title-section text-center mb-2" v-if="checkouts.length !== 0">
              {{ t("create_webshop_titles.4") }}
            </p>
            <div class="h-full w-full overflow-auto" v-if="checkouts.length !== 0">
              <ListSelector name="checkout_id" :values="checkouts" valueKey="id" radioPosition="top">
                <template #title="{ value }">
                  <div class="w-full">
                    <p class="text-body-lg-heavy text-secondary">{{ value.name }}</p>
                    <p class="text-tertiary text-body-default">
                      {{ t("created_at") }} {{ DateTime.fromISO(value.created_at).toFormat("DDD") }}
                    </p>
                  </div>
                </template>
              </ListSelector>
            </div>
            <Button variant="tertiary">{{ t("add_checkout_at_later_date") }}</Button>
          </div>
        </FormStep>
      </FormWizard>
      <div class="w-[1020px] max-w-full h-full flex relative" v-if="shopCreated">
        <div class="my-auto mx-auto max-w-[540px] transform -translate-x-1">
          <div class="relative overflow-hidden rounded-full mx-auto h-[100px] w-[200px] bg-utility-kiwi mb-4">
            <svg width="200" height="101" viewBox="0 0 200 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M-136.967 38.1815C-149.752 45.5628 -154.132 61.9106 -146.751 74.6953C-139.37 87.4801 -123.022 91.8605 -110.237 84.4792L32.553 2.03922C45.3378 -5.34206 49.7182 -21.6899 42.3369 -34.4746C34.9556 -47.2594 18.6078 -51.6398 5.82302 -44.2585L-136.967 38.1815Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M-162.116 132.028C-174.9 139.409 -179.281 155.757 -171.9 168.542C-164.518 181.326 -148.17 185.707 -135.386 178.325L7.40457 95.8854C20.1893 88.5041 24.5697 72.1563 17.1884 59.3715C9.80715 46.5868 -6.54066 42.2064 -19.3254 49.5877L-162.116 132.028Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M30.3861 51.7514C37.7674 64.5362 54.1152 68.9166 66.8999 61.5353C79.6847 54.154 84.0651 37.8062 76.6838 25.0214C69.3025 12.2367 52.9547 7.85629 40.1699 15.2376C27.3852 22.6189 23.0048 38.9667 30.3861 51.7514Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M99.6656 -19.1125C86.8808 -11.7312 82.5004 4.61665 89.8817 17.4014C97.263 30.1862 113.611 34.5666 126.396 27.1853L269.186 -55.2547C281.971 -62.636 286.351 -78.9838 278.97 -91.7686C271.588 -104.553 255.241 -108.934 242.456 -101.552L99.6656 -19.1125Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M62.6226 81.6034C49.8378 88.9846 45.4575 105.332 52.8387 118.117C60.22 130.902 76.5678 135.282 89.3526 127.901L232.143 45.4611C244.928 38.0798 249.308 21.732 241.927 8.94723C234.545 -3.83752 218.198 -8.21791 205.413 -0.836628L62.6226 81.6034Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
              <path
                d="M168.369 99.8797C155.584 107.261 151.204 123.609 158.585 136.394C165.966 149.178 182.314 153.559 195.099 146.177L266.494 104.957C279.279 97.5762 283.659 81.2284 276.278 68.4436C268.896 55.6588 252.549 51.2785 239.764 58.6597L168.369 99.8797Z"
                stroke="#ABD9C1"
                style="stroke: #abd9c1; stroke: color(display-p3 0.6706 0.851 0.7569); stroke-opacity: 1"
                stroke-width="1.5"
              />
            </svg>
            <Icon
              class="absolute top-[35.5px] left-[84px] text-utility-kiwi-darkest"
              src="check"
              :size="ComponentSize.xl4"
            ></Icon>
          </div>
          <p class="w-full text-center text-title-section text-secondary mb-2">{{ t("webshop_is_created") }}</p>
          <p class="text-body-lg text-tertiary mb-4">{{ t("webshop_is_created_desc") }}</p>
          <Button class="w-full mt-4" @click="emit('close')" type="button" variant="default">{{ t("close") }}</Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import _ from "lodash";
  import { DateTime } from "luxon";
  import { ComponentSize } from "~/types/global";

  const props = defineProps<{
    open: boolean;
    setShop: any;
  }>();

  const modalOpen = ref(props.open);

  const shopCreated = ref(false);

  const createdShop = ref({});

  const checkouts = ref<any[]>([]);

  const loading = ref(false);

  const yup = useYup();

  const { t } = useI18n();

  const emit = defineEmits(["close"]);

  const wizard = ref(null);

  const values = computed(() => wizard.value?.values);

  const { getAccount } = useAuth();

  const account = getAccount();

  const shopSystems = [
    { label: "Shopify", value: "shopify" },
    { label: "Magento 1", value: "magento-1" },
    { label: "Magento 2", value: "magento-2" },
    { label: "WooCommerce", value: "woocommerce" },
    { label: "Shopware", value: "shopware" },
    { label: t("other"), value: "custom" },
  ];

  const shopifyPlans = [
    { label: "Basic", value: "basic" },
    { label: "Shopify", value: "shopify" },
    { label: "Advanced", value: "advanced" },
  ];

  const paymentIntervals = [
    { label: t("monthly_payment"), value: "monthly" },
    { label: t("yearly_payment"), value: "yearly" },
  ];

  onMounted(() => {
    if (props.setShop) {
      setTimeout(() => {
        wizard.value?.setValues({
          platform: props.setShop.platform,
          shop_url: props.setShop.shop_url,
          name: props.setShop.name,
          checkout_id: props.setShop.checkout_id,
          id: props.setShop.id,
        });
      }, 50);
    }
  });

  const step1Title = computed(() => {
    switch (values.value.platform) {
      case "shopify":
        return t("add_homerunner_in_appstore");
      case "woocommerce":
        return t("add_homerunner_in_woocommerce");
      default:
        return t("get_our_plugin");
    }
  });

  const step1Desc = computed(() => {
    switch (values.value.platform) {
      case "shopify":
        return t("go_to_shopify_and_andd");
      case "woocommerce":
        return t("go_to_woocommerce_and_add");
      default:
        return t("get_our_plugin_and_add");
    }
  });

  const returnLinkToPlugin = computed(() => {
    switch (values.value.platform) {
      case "shopify":
        return "https://apps.shopify.com/coolrunner-app";
      case "woocommerce":
        return "https://wordpress.org/plugins/homerunner-smartcheckout/";
      case "shopware":
        return "https://github.com/Homerunner-com/shopware";
      case "magento-1":
        return "https://github.com/Homerunner-com/magento1";
      case "magento-2":
        return "https://github.com/Homerunner-com/magento2";
      default:
        return "https://homerunner.com";
    }
  });

  const selectSystem = (system: string) => {
    wizard.value?.setValues({ platform: system });
    if (system == "shopify") {
      validationSchema.value[2].spec.meta.autoskip = false;
    } else {
      validationSchema.value[2].spec.meta.autoskip = true;
    }
    if (system == "custom") {
      validationSchema.value[3].spec.meta.autoskip = false;
      validationSchema.value[4].spec.meta.autoskip = true;
    } else {
      validationSchema.value[3].spec.meta.autoskip = true;
      validationSchema.value[4].spec.meta.autoskip = false;
    }
  };

  const { checkoutsForSelect } = useCheckouts();

  const handleNext = (val: number) => {
    if (val === 1) {
      getCheckouts();
    }
    if (val == 2 && values.value.platform !== "custom") {
      if (values.value.id) {
        updateShop();
      } else {
        createShop();
      }
    }
    if (val == 4 && values.value.platform !== "shopify") {
      if (values.value.id) {
        updateShop();
      } else {
        createShop();
      }
    }
    if (val === 5 && values.value.platform == "custom") {
      if (values.value.id) {
        updateShop();
      } else {
        createShop();
      }
    }
    if (val === 5 && checkouts.value.length == 0) {
      if (values.value.id) {
        updateShop();
      } else {
        createShop();
      }
      shopCreated.value = true;
    }
  };

  const getCheckouts = async () => {
    const fetchedCheckouts = await checkoutsForSelect();
    checkouts.value = fetchedCheckouts.checkouts;
    if (checkouts.value.length == 0) {
      validationSchema.value[4].spec.meta.continueButtonText = t("create");
    }
  };

  const selectPlan = (plan: string) => {
    wizard.value?.setValues({ shopify_plan: plan });
  };

  const selectInterval = (interval: string) => {
    wizard.value?.setValues({ payment_interval: interval });
  };

  const createShop = () => {
    loading.value = true;

    if (values.value.id) {
      updateShop().then(() => {
        loading.value = false;
      });
      return;
    }

    homeFetch(`workspace/webshops`, {
      method: "POST",
      body: {
        name: values.value.name,
        platform: values.value.platform,
        shop_url: values.value.shop_url,
      },
    })
      .then((r) => {
        wizard.value?.resetForm({
          values: {
            ...r.data,
          },
        });
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const updateShop = () => {
    return homeFetch(`workspace/webshops/${values.value.id}`, {
      method: "PUT",
      body: {
        ...values.value,
      },
    }).then((r) => {
      wizard.value?.resetForm({
        values: {
          ...r.data.data,
        },
      });
    });
  };

  const addCheckout = () => {
    if (values.value.id) {
      updateShop();
      return;
    }
    createdShop.value.checkout_id = values.value.checkout_id;
    homeFetch(`workspace/webshops/${createdShop.value.id}`, {
      method: "PUT",
      body: {
        ...createdShop.value,
      },
    }).then((r) => {});
  };

  const validationSchema = ref([
    yup
      .object({
        name: yup.string().required(),
      })
      .meta({ continueButtonText: t("continue") }),
    yup
      .object({
        platform: yup.string().required(),
      })
      .meta({ continueButtonText: t("continue") }),
    yup
      .object({
        shopify_plan: yup.string().required(),
        payment_interval: yup.string().required(),
      })
      .meta({ continueButtonText: t("continue"), autoskip: true }),
    yup
      .object({
        shop_url: yup.string().required().matches(/[.:]/),
      })
      .meta({ continueButtonText: t("continue"), autoskip: true }),
    yup
      .object({
        code: yup.number().nullable(),
      })
      .meta({ continueButtonText: t("continue"), autoskip: false }),
    yup
      .object({
        checkout_id: yup.number().nullable(),
      })
      .meta({ continueButtonText: t("continue"), autoskip: false }),
  ]);

  const onSubmit = (formData) => {
    if (values.value.checkout_id) {
      addCheckout();
    }
    shopCreated.value = true;
  };
</script>
