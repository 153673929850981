<template>
  <td
    class="overflow-hidden px-4 h-12 box-border border-b group-[:last-of-type]/row:border-0 text-sm relative bg-inherit group-hover/row:bg-inherit border-inherit"
    :class="{
      'text-disabled': disabled,
      'text-inherit': !disabled,
      '[&>.cell-content]:mr-auto': align === 'left',
      '[&>.cell-content]:mx-auto': align === 'center',
      '[&>.cell-content]:ml-auto': align === 'right',
    }"
    ref="td"
  >
    <div class="cell-content text-ellipsis whitespace-nowrap flex flex-col max-w-[350px] w-fit">
      <div>
        <slot> {{ value }}</slot>
      </div>
      <div class="text-body-sm text-quarterary">
        <slot name="subValue">
          {{ subValue }}
        </slot>
      </div>
    </div>
  </td>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  const props = defineProps<{
    disabled?: boolean;
    value?: any;
    subValue?: any;
    header?: any;
  }>();
  const td = ref<HTMLElement>();

  const align = computed(() => {
    if (!props.header) return "left";
    if (props.header.type === "price") return "right";

    return props.header?.align || "left";
  });

  defineExpose({
    td,
  });
</script>
