<template>
  <div class="flex flex-col gap-6 @container" ref="flowWrapper">
    <div class="flex gap-4 justify-between">
      <div class="flex-col">
        <div class="text-body-lg-heavy settings-flow-header">
          {{ t("settings.printers.label.title") }}
        </div>

        <div class="text-body-default text-quarterary">
          {{ t("settings.printers.label.description") }}
        </div>
      </div>
      <DropdownSelect v-model="labelSize" :options="labelSizes" />
    </div>

    <Divider />
    <div class="flex gap-4 justify-between">
      <div class="flex-col">
        <div class="text-body-lg-heavy settings-flow-header">
          {{ t("settings.printservice.download.title") }}
        </div>

        <div class="text-body-default text-quarterary">
          {{ t("settings.printservice.download.description") }}
        </div>
      </div>
      <DropdownActions
        :actions="downloadActions"
        :trigger-text="t('download')"
        button-variant="default"
        :buttonSize="ComponentSize.default"
        trigger-icon-suffix="CaretDown"
      />
    </div>

    <Divider />
    <EmptyView
      v-show="hasNoPrinters"
      :title="t('settings.printers.download.title')"
      :description="t('settings.printers.download.description')"
    >
      <template #illustration>
        <img src="~/assets/illustrations/emptyViews/oc-plugging-in.svg" alt="No transfers illustration" />
      </template>
      <template #actions>
        <div class="flex gap-4 items-center">
          <Button variant="primary" href="https://assets.coolrunner.dk/printservice/PrintService.dmg">
            <template #prefix> <Icon src="custom/services/apple" /> </template>
            {{ t("download_for_mac") }}</Button
          >
          <div class="text-body-sm text-quarterary">{{ t("or").toLowerCase() }}</div>
          <Button variant="primary" href="https://assets.coolrunner.dk/printservice/PrintService.msi">
            <template #prefix> <Icon src="custom/services/windows" /> </template>
            {{ t("download_for_windows") }}</Button
          >
        </div>
      </template>
    </EmptyView>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" v-if="!hasNoPrinters">
      <PrinterCard v-for="printer in printers" :printer="printer" :key="printer.id" @fetch="fetchPrinters" />
    </div>
  </div>
</template>

<script setup lang="ts">
  export interface SettingsPrinter {
    id: number;
    customer_id: number;
    name: string;
    alias: string;
    location: string;
    print_service_id: number;
    description: string;
    default: number;
    last_active_at: string;
    active_at: Date;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    print_service: PrintService;
  }
  export interface PrintService {
    id: number;
    customer_id: number;
    mac_address: string;
    os: string;
    os_version: string;
    os_name: string;
    hostname: string;
    location: string;
    created_at: null;
    updated_at: Date;
  }
  import type { ISettingsFlow } from "../types";
  import PrinterCard from "../components/PrinterCard.vue";
  import { ComponentSize } from "~/types/global";

  const { t } = useI18n();
  const loading = ref<boolean>(false);
  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );

  const labelSizes: DropdownOption[] = [
    {
      label: "A4",
      value: "A4",
    },
    {
      label: "LabelPrint",
      value: "LabelPrint",
    },
  ];

  const labelSize = ref<string>("A4");
  const printers = ref<SettingsPrinter[]>([]);

  const hasNoPrinters = computed(() => printers.value.length === 0 && !loading.value);

  const downloadActions: DropdownAction[] = [
    {
      label: t("download_for_mac"),
      handler: () =>
        navigateTo("https://assets.coolrunner.dk/printservice/PrintService.dmg", {
          external: true,
        }),
      icon: "custom/services/apple",
      value: "mac",
    },
    {
      label: t("download_for_windows"),
      handler: () =>
        navigateTo("https://assets.coolrunner.dk/printservice/PrintService.msi", {
          external: true,
        }),
      icon: "custom/services/windows",
      value: "windows",
    },
  ];

  const fetchPrinters = () => {
    setLoading(true);
    return homeFetch("workspace/printers")
      .then((response) => {
        if (response?.data) {
          printers.value = response.data as SettingsPrinter[];
        }
      })
      .then(fetchLabelSize)
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchLabelSize = () => {
    return homeFetch("workspace/printers/label-format").then((response) => {
      if (response?.data) {
        labelSize.value = response.data as string;
      }
    });
  };

  watch(labelSize, (size) => {
    updateLabelSize(size);
  });

  const updateLabelSize = (size: string) => {
    setLoading(true);
    return homeFetch("workspace/printers/label-format", {
      method: "PUT",
      body: {
        label_format: size,
      },
    })
      .then(() => {
        labelSize.value = size;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  onMounted(() => {
    fetchPrinters().then(() => {
      goToCurrentHeader();
    });
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => {
    loading.value = l;
    emits("update:loading", l);
  };
</script>

<script lang="ts">
  export const usePrinterSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/printer";
    const title = t("settings.printers.title");
    const pageTitle = t("settings.printers.page_title");
    const key = "printers";
    const headers = [t("settings.printers.label.title")];

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
    };
  };
</script>
