<template>
  <form v-if="validationSchema" @submit.prevent="onSubmit">
    <slot name="hidden"></slot>
    <div class="w-full py-4 px-4 flex">
      <Button class="mr-2" type="button" iconOnly variant="tertiary" @click="exit">
        <Icon class="cursor-pointer text-secondary" src="close" />
      </Button>
      <p class="text-quarterary text-title-body mt-1.5">{{ name }}</p>
      <Divider class="mx-2" rotation="vertical"></Divider>
      <p class="text-secondary text-title-body mt-1.5">{{ stepNames[currentStepIdx] }}</p>
    </div>
    <slot> </slot>
    <div class="p-4 flex">
      <Button class="w-full max-w-max" v-if="hasPrevious" type="button" @click="goToPrev" variant="tertiary">{{
        t("previous")
      }}</Button>
      <Button class="w-full ml-auto max-w-max mr-3" v-if="hasSkip" type="button" variant="default" @click="skip">{{
        t("skip_this_step")
      }}</Button>
      <Button
        class="w-full max-w-max"
        v-if="hasContinueButton"
        type="submit"
        :class="{ 'ml-auto': !hasSkip }"
        :disabled="!canSubmit || hasSkip"
        :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'"
        :loading="loading"
      >
        {{ continueButtonText }}
      </Button>
    </div>
  </form>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      validationSchema?: any[];
      loading?: boolean;
      submitButtonText?: string;
      lastStepPositive?: boolean;
      showProgress?: boolean;
      splitFooter?: boolean;
      customFooter?: boolean;
      name?: string;
      stepNames?: string[];
    }>(),
    {
      loading: false,
      submitButtonText: "",
      lastStepPositive: false,
      showProgress: false,
      splitFooter: false,
      name: "",
      customFooter: false,
      stepNames: [],
    }
  );

  const emit = defineEmits(["submit", "exit", "skip"]);
  const {
    currentStepIdx,
    isLastStep,
    hasPrevious,
    hasSkip,
    stepKey,
    hasContinueButton,
    continueButtonText,
    schemaFields,
    goToPrev,
    form,
    canSubmit,
    skip,
    exit,
    onSubmit,
    goToLastStep,
    goToStepByKeyName,
    resetForm,
    setErrors,
  } = useCreateShipment({
    submitButtonText: props.submitButtonText,
    validationSchema: props.validationSchema,
    emit,
  });

  const { values, setValues, setFieldValue } = form;

  defineExpose({
    values,
    currentStepIdx,
    isLastStep,
    hasPrevious,
    canSubmit,
    stepKey,
    setValues,
    setFieldValue,
    goToLastStep,
    goToStepByKeyName,
    resetForm,
    setErrors,
  });
</script>
