<template>
  <div class="flex-1 flex gap-1 justify-center">
    <Button
      :size="ComponentSize.sm"
      icon-only
      @click="() => goToPage(pagination.prev_cursor)"
      :disabled="!pagination.prev_cursor"
      ><Icon src="arrow_left"
    /></Button>
    <Button
      :size="ComponentSize.sm"
      icon-only
      @click="() => goToPage(pagination.next_cursor)"
      :disabled="!pagination.next_cursor"
      ><Icon src="arrow_right"
    /></Button>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import type { CursorPagination } from "~/types/paginate";

  //props for a laravel paginator
  const props = withDefaults(
    defineProps<{
      pagination: CursorPagination;
    }>(),
    {}
  );

  const emit = defineEmits(["update:cursor"]);

  const goToPage = (cursor?: String | null) => {
    if (!cursor) return;

    emit("update:cursor", cursor);
  };
</script>
