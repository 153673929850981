export const useFormatPrice = (
  price: number | string | null | undefined,
  decimals: boolean | "auto",
  currency: string = "DKK"
) => {
  const { $i18n } = useNuxtApp();
  currency = currency.trim().toUpperCase();
  if (!currency) currency = "DKK";

  if (price === "" || price === null || price === undefined) return "";

  //if price is string convert to number
  if (typeof price === "string") {
    price = price.trim();
    //split string by space and take first part
    const splits = price.split(" ");

    price = parseFloat(splits[0]);
    currency = splits[1] ?? currency;
  }

  if (isNaN(price)) return "";

  if (decimals) {
    if (decimals === "auto")
      return price.toLocaleString($i18n.locale, {
        style: "currency",
        maximumFractionDigits: 2,
        currency,
        currencyDisplay: "code",
      });

    return price.toLocaleString($i18n.locale, {
      style: "currency",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency,
      currencyDisplay: "code",
    });
  }

  return price.toLocaleString($i18n.locale, {
    style: "currency",
    maximumFractionDigits: 0,
    currency,
    currencyDisplay: "code",
  });
};

export const useDecidePriceExclOrIncl = (price: {
  price_excl_tax: number | string;
  price_incl_tax: number | string;
}) => {
  if (!price) return "-";

  const { isAccountBusiness } = useAuth();

  return isAccountBusiness() ? price.price_excl_tax : price.price_incl_tax;
};
