<template>
  <div
    class="rounded-xl cursor-pointer group relative"
    :class="small ? 'w-[68px] h-[68px]' : 'h-[82px] w-[82px]'"
    :style="`background-image: url('${src}'); background-position: center; background-size: cover;`"
  >
    <Button
      class="absolute top-1 left-[49px] transition-all duration-300 opacity-0 group-hover:opacity-100"
      v-if="!small"
      variant="primary"
      :size="ComponentSize.sm"
      iconOnly
      @click="emit('removeFile', file.name)"
    >
      <Icon class="text-white" src="close" :size="ComponentSize.sm" />
    </Button>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const props = defineProps<{
    src: string;
    file?: Object;
    small?: boolean;
  }>();

  const emit = defineEmits(["removeFile"]);
</script>
