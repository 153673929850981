import { type AxiosResponse } from "axios";
import type { ITableHeader } from "~/components/Table/types";
import type { FetchResponse as OFetchResponse } from "ofetch";

export enum ComponentSize {
  xxs = "xxs",
  xs = "xs",
  sm = "sm",
  md = "md",
  default = "default",
  lg = "lg",
  xl = "xl",
  xl2 = "2xl",
  xl3 = "3xl",
  xl4 = "4xl",
  xl5 = "5xl",
  xl6 = "6xl",
}
declare global {
  type FetchResponse<T> = OFetchResponse<T>;
  type HTMLBaseTarget = "_blank" | "_self" | "_parent" | "_top" | string;
  type ButtonVariant =
    | "default"
    | "primary"
    | "secondary"
    | "tertiary"
    | "positive"
    | "destructive"
    | "primary-link"
    | "secondary-link"
    | "destructive-link"
    | "attention"
    | "tertiary-inversed"
    | "warning";

  type ButtonSize = ComponentSize.default | ComponentSize.sm | ComponentSize.lg;
  type InputSize = ComponentSize.default | ComponentSize.sm | ComponentSize.lg;
  type IconSize =
    | ComponentSize.sm
    | ComponentSize.default
    | ComponentSize.lg
    | ComponentSize.xl
    | ComponentSize.xl2
    | ComponentSize.xl3
    | ComponentSize.xl4
    | ComponentSize.xl5
    | ComponentSize.xl6;
  type AccordionSize = ComponentSize.default | ComponentSize.sm;

  interface ICookie {
    name: string;
    value: string;
    expiration?: number;
  }

  interface ISidebarEntry {
    label: string;
    icon: string;
    route: string;
    route_key: string;
    entries?: any[];
    target?: HTMLBaseTarget;
    hidden?: boolean;
  }

  interface ISidebarAction {
    label: string;
    icon: string;
    key: string;
    action: Function;
    hidden?: boolean;
  }
  interface IDescribable {
    describable: string;
    type: string;
  }

  interface ICoreResponse extends AxiosResponse {
    data: {
      http_code: number;
      response_code: number;
      response_data: Record<string, any> | any[] | null;
      status: string;
      status_code: string;
      status_message: string;
    };
  }

  interface DropdownOption {
    label: string;
    value: any;
    icon?: string | null;
    image?: string | null;
    subtext?: string;
    disabled?: boolean;
    destructive?: boolean;
  }

  interface View {
    id?: string;
    name: string;
    icon?: string;
    query?: Record<string, string>;
    columns?: AvailableColumn[];
    sort?: string[] | null;
    filters?: Record<string, any>;
    type?: string;
    search?: string;
    count?: number;
  }

  interface AvailableColumn {
    key: string;
    visible: boolean;
    label: string;
  }

  export interface Contact {
    id: number;
    customer_id: number;
    name: string;
    company_name: string;
    street1: string;
    street2: null;
    zip_code: string;
    city: string;
    country_id: number;
    phone_prefix: string;
    phone: string;
    email: string;
    latitude: number;
    longitude: number;
    hash_key: string;
    created_at: Date;
    updated_at: Date;
    country_code: string;
    country: Country;
  }

  export interface Country {
    id: number;
    full_name: string;
    iso: string;
    iso3: string;
    numeric: string;
    region: string;
    zip_format: string;
    address_format: string;
    phone_prefix: string;
    phone_format: string;
    active: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    is_zone: number;
    trade_union: string;
  }

  export interface ICheckout {
    id?: number;
    name: string;
    shipping_methods: ICheckoutShippingMethod[];
    shops: number[];
  }

  export interface ICheckoutShippingMethod {
    carrier_product_service: string;
    address_validation: boolean;
    name: string;
    description: string;
    label: null;
    logo: string;
    carrier: string;
    active: boolean;
    rules: IRule[];
  }

  export interface IRule {
    priority: number;
    filters: Record<string, IRuleFilter[]>;
    price: number;
    description: string | null;
  }

  export interface IRuleFilter {
    operator: string;
    value: any;
  }

  export type IRuleFilterDescription = Record<string, IRuleFilterDescriptionEntry>;

  export interface IRuleFilterDescriptionEntry {
    label: string;
    type: string;
    options: any[];
    operator: string[];
  }

  export interface OrganisationInformation {
    id: number;
    name: string;
    vat: string;
    payment_method: string;
    business: boolean;
    test_mode: number;
    level: string;
    created_at: Date;
    updated_at: Date;
    core_customer_id: number;
    account_id: null;
    deleted_at: null;
    disabled_at: null;
    subdomain: string;
    contact_info: ContactInfo[];
  }

  export interface ContactInfo {
    id: number;
    title: null;
    name: string;
    phone_prefix: null;
    phone: string;
    email: string;
    street1: string;
    street2: null;
    zip_code: string;
    city: string;
    type: "BILLING" | "SHIPPING";
    country_id: number;
    country_iso: string;
    entity_type: string;
    entity_id: number;
    latitude: number;
    longitude: number;
    created_at: Date;
    updated_at: Date;
  }

  export interface IDocument {
    id: number;
    customer_id: number;
    type: string;
    file_type: string;
    document_number: string;
    sender_country: string;
    receiver_country: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    meta: IMeta | null;
  }

  export type IMeta = Record<string, any>;

  export interface IInvoice {
    amount: string;
    number: string;
    cashbooked: number;
    created_at: string;
    draft_number: string;
    invoice_type: string;
    invoicing_company_name: string;
    reconciliation: number;
    title: string;
    type: string;
    url: string;
    specification_url: string;
  }
  export interface IProfile {
    name: string;
    email: string;
    phone: string;
    timezone: string;
    language: string;
    consent: boolean;
  }

  export interface IFullPrice {
    uid: string;
    carrier: string;
    title: string;
    weight_from: number;
    weight_to: number;
    from_country: string;
    to_country: string;
    sales_price_excl_vat: string;
    sales_price_incl_vat: string;
    currency: string;
    carrier_product_service: string;
    has_insurance: boolean;
    delivery_eta: string;
    fuel_percentage: string;
    fuel_price: string;
    sub_products: SubProducts;
    service_codes: any[];
  }

  export interface SubProducts {
    carrier: SubProduct;
    linehaul: SubProduct;
    clickfee: SubProduct;
    sorting: SubProduct;
  }

  export interface SubProduct {
    type: string;
    price_excl_tax: number;
    price_incl_tax: number;
    title: string;
  }

  export interface IServiceError {
    statusCode: number;
    data: {
      statusCode: number;
      data: {
        data: string | Record<string, any> | null | IValidationError;
        message: string;
        session: string;
      };
      url: string;
      message: string;
      stack: string;
      statusMessage: string;
    };
  }

  export interface IValidationError {
    errors: Record<string, string[]>;
  }

  interface GeoFeature {
    properties: GeoLocation;
    bbox: number[];
    geometry: {
      type: string;
      coordinates: number[];
    };
    type: "Feature";
  }
  export interface GeoLocation {
    country: string;
    country_code: string;
    state: string;
    city: string;
    municipality: string;
    street: string;
    lon: number;
    lat: number;
    housenumber: string;
    result_type: string;
    formatted: string;
    address_line1: string;
    address_line2: string;
    plus_code: string;
    place_id: string;
    postcode?: string;
  }
}
export {};
